import axios from "axios";
import {ApiResponse, getHeaders} from './ApiResponse';


export enum UserStatus
{
	confirmed = 'confirmed',
	unconfirmed = 'unconfirmed',
	billing = 'billing',
	subscription = 'subscription',
	active = 'active'
}


export interface User
{
	uid: string;
	status: UserStatus;
	givenName: string;
	familyName: string;
	compositeName: string;
	email: string;
	dateCreated: string;
	dateLastSeen: string;
	publishableKey: string;
}


export interface UserDevice
{
	uid: string;
	deviceIdentifier: string;
	accessToken?: string;
	expiresAt: string;
	lastUsedAt: string;
	isActive?: boolean;
}


export interface Auth
{
	user: User;
	device: UserDevice;
}


export type UserAuthApiResponse = ApiResponse<Auth>;

export type UserApiResponse = ApiResponse<{}>;

export async function userAuthRequest(email: string, password: string): Promise<UserAuthApiResponse>
{
	const response = await axios.post<UserAuthApiResponse>('/api/user/auth', {
		email, password,
	}, {headers: getHeaders()});

	return response.data;
}

export async function userCreateRequest(name: string, email: string, password: string): Promise<UserAuthApiResponse>
{
	const response = await axios.post<UserAuthApiResponse>('/api/user', {
		name, email, password
	}, {headers: getHeaders()});

	return response.data;
}

export async function userValidateRequest(accessToken: string): Promise<UserAuthApiResponse>
{
	const response = await axios.get<UserAuthApiResponse>('/api/user', {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function userRecoveryRequest(email: string): Promise<UserApiResponse>
{
	const response = await axios.post<UserApiResponse>('/api/user/recovery', {
		email
	}, {headers: getHeaders()});

	return response.data;
}

export async function userRevokeRequest(accessToken: string): Promise<UserApiResponse>
{
	const response = await axios.post<UserApiResponse>('/api/user/revoke', {
		accessToken
	}, {headers: getHeaders()});

	return response.data;
}

export async function userConfirmRequest(uid: string): Promise<UserApiResponse>
{
	const response = await axios.post<UserApiResponse>('/api/validation/confirm', {
		uid
	}, {headers: getHeaders()});

	return response.data;
}

export async function userConfirmResendRequest(accessToken: string): Promise<UserApiResponse>
{
	const response = await axios.post<UserApiResponse>('/api/validation/resend', {}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function userCompleteRequest(accessToken: string): Promise<UserApiResponse>
{
	const response = await axios.post<UserApiResponse>('/api/user/complete', {}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function userSaveRequest(accessToken: string, email: string, name: string): Promise<UserApiResponse>
{
	const response = await axios.post<UserApiResponse>('/api/user/update', {
		email, name
	}, {headers: getHeaders(accessToken)});

	return response.data;
}

export async function userChangePasswordRequest(accessToken: string, current: string, password: string): Promise<UserApiResponse>
{
	const response = await axios.post<UserApiResponse>('/api/user/password', {
		current, password
	}, {headers: getHeaders(accessToken)});

	return response.data;
}