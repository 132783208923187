import React from 'react';
import {EventPayStatus} from '../../model/event/eventState';
import {Event, EventPaymentMethod} from '../../api/Event';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Module} from '../../api/Module';
import {LockRounded, OpenInNewRounded} from '@material-ui/icons';
import CheckboxField from '../form/CheckboxField';
import {Button, TextField} from '@material-ui/core';
import {actionEventDetail} from '../../model/event/actions/DetailAction';
import {generateHtmlId} from '../../utils/htmlId';
import {actionEventPayExtra} from '../../model/event/actions/PayExtraAction';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	modules: Module[];
	event: Event;
	status: EventPayStatus;
	sessionId?: string;
}


interface DispatchProps
{
	pay: (uid: string, participants: number, modules: string[], payment: EventPaymentMethod) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	modules: string[];
	participants: number;
	payment: EventPaymentMethod;
}


class BareEventModulesForm extends React.Component<Props, State>
{
	public state: State = {
		modules: ['default'],
		participants: 0,
		payment: EventPaymentMethod.CHARGE_AUTOMATICALLY
	};


	public componentDidMount(): void
	{
		const {modules} = this.props.event;
		this.setState({modules: modules.map((m) => m.key)});
	}


	protected isModuleActive(module: string): boolean
	{
		const result = this.props.event.modules.find((m) => m.key === module);
		return !!result;
	}


	protected onParticipantsChanged = (participants: number) => this.setState({participants});

	protected save = (e: any) =>
	{
		e.preventDefault();
		if (!this.isFormValid()) {
			return;
		}

		let {modules, participants, payment} = this.state;
		const newModules = modules.filter((module) =>
		{
			return !this.props.event.modules.map((m) => m.key).includes(module);
		});

		this.props.pay(this.props.event.uid, participants, newModules, payment);
	};

	protected onModuleChanged = (module: Module, enabled: boolean) =>
	{
		let {modules} = this.state;
		if (enabled && !modules.includes(module.key)) {
			modules.push(module.key);
		}

		if (!enabled) {
			modules = modules.filter((key) => key !== module.key);
		}

		this.setState({modules});
	};

	protected getModulePrice = (): number =>
	{
		let price = 0;
		this.props.modules.forEach((module) =>
		{
			if (this.state.modules.includes(module.key)) {
				price += module.pricePerParticipant;
			}
		});

		return Math.round((price + Number.EPSILON) * 10) / 10;
	};

	protected getNewPrice = (): number =>
	{
		const newTotalPrice = this.getModulePrice() * (this.props.event.participants + this.state.participants);
		return newTotalPrice - this.props.event.totalPaid;
	};

	protected isFormValid = (): boolean =>
	{
		return this.state.modules.length !== this.props.event.modules.length || this.state.participants > 0;
	};

	public render = () =>
	{
		const isSaving = this.props.status === EventPayStatus.paying;

		return (
			<form className='Form Form--full' onSubmit={this.save}>
				<div className='Form__column Form__column--full'>
					<h2>{this.props.t('events.edit.modules.title')}</h2>
					<p>
						{this.props.t('events.edit.modules.message')}&nbsp;
						<a href={'https://www.simpleflow.io/pricing/'} target={'_blank'} rel='noopener noreferrer'>
							<strong>
								{this.props.t('events.edit.modules.seePricing')}&nbsp;
								<OpenInNewRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
							</strong>
						</a>
					</p>
					<div className='Form__column Form__column--full Form__moduleList'>
						{this.props.modules.map((module, key) => (
							<div key={key} className='Form__item Form__module'>
								<CheckboxField
									disabled={isSaving || module.isDefault || this.isModuleActive(module.key)}
									label={(
										<div className='Form__moduleInfo'>
											<div className='Form__moduleName'>
												{module.name}
												&nbsp;
												{!module.isPublic && (
													<LockRounded style={{position: 'relative', top: 2}} color={'disabled'} fontSize={'inherit'}/>
												)}
											</div>
											<div className='Form__modulePrice'>
												{module.pricePerParticipant.toFixed(2)} EUR
											</div>
											<p>{module.description}</p>
										</div>
									)}
									isChecked={this.state.modules.includes(module.key)}
									onChange={(enabled) => this.onModuleChanged(module, enabled)}
								/>
							</div>
						))}
						<div className='Form__item Form__module Form__module--total'>
							<p>{this.props.t('events.edit.modules.participantPrice')}</p>
							<div className='Form__modulePrice'>{this.getModulePrice().toFixed(2)} EUR</div>
						</div>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<h2>{this.props.t('events.edit.participants.title')}</h2>
					<p>
						{this.props.t('events.edit.participants.message')}
					</p>
					<p dangerouslySetInnerHTML={{
						__html: this.props.t('events.edit.participants.current', {
							current: this.props.event.remote.participants,
							limit: this.props.event.participants
						})
					}}/>
					<div className='Form__group'>
						<div className='Form__item'>
							<div className='Form__segment Form__segment--half' style={{paddingLeft: 0}}>
								<TextField
									disabled={isSaving}
									type={'number'}
									required={true}
									value={this.state.participants}
									onChange={(e) =>
									{
										const value = parseInt(e.target.value ? e.target.value : '0');
										this.onParticipantsChanged(value < 0 ? 0 : value);
									}}
									className='TextField'
									aria-valuemin={0}
									label={this.props.t('events.edit.participants.increased')}
									variant="outlined"
									fullWidth={true}
									autoComplete={generateHtmlId()}
								/>
							</div>
						</div>
					</div>
					<h2>{this.props.t('events.edit.summary.title')}</h2>
					<p>{this.props.t('events.edit.summary.message')}</p>
					<div className='Form__column Form__column--full Form__moduleList'>
						<div className='Form__item Form__module Form__module--total'>
							<p>{this.props.t('events.edit.summary.extraPay')}</p>
							<div className='Form__modulePrice'>{this.getNewPrice().toFixed(2)} EUR</div>
						</div>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<Button
						disabled={isSaving || !this.isFormValid()}
						onSubmit={this.save}
						type='submit'
						className='Button'
						variant="contained"
						size="large"
						color="secondary">
						{isSaving ?
							this.props.t('events.edit.summary.pay.loading') :
							this.props.t('events.edit.summary.pay.default', {
								price: this.getNewPrice().toFixed(2)
							})
						}
					</Button>
				</div>
			</form>
		);
	};
}


export const EventModulesForm = withTranslation('app')(connect(
	(state: AppState) => ({
		modules: state.user.modules || [],
		status: state.events.payExtra,
		sessionId: state.events.sessionId
	}),
	(dispatch: AppThunkDispatch) => ({
		pay: (uid: string, participants: number, modules: string[], payment: EventPaymentMethod) =>
			dispatch(actionEventPayExtra(uid, participants, modules, payment))
				.then(() => dispatch(actionEventDetail(uid)))
	})
)(BareEventModulesForm));