import React from 'react';


export const EventLoader = (
	<div className="EventLoader">
		<div className="EventLoader__cube">
			<div/>
			<div/>
			<div/>
		</div>
		<div className="EventLoader__cube">
			<div/>
			<div/>
			<div/>
		</div>
		<div className="EventLoader__cube">
			<div/>
			<div/>
			<div/>
		</div>
		<div className="EventLoader__cube">
			<div/>
			<div/>
			<div/>
		</div>
	</div>
);