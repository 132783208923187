import {ApiResponse, getHeaders} from './ApiResponse';
import axios from 'axios';


export enum SubscriptionPlan
{
	MONTHLY = 'monthly',
	YEARLY = 'yearly'
}


export enum SubscriptionPeriod
{
	MONTH = 'month',
	YEAR = 'year'
}


export enum SubscriptionStatus
{
	INCOMPLETE = 'incomplete',
	INCOMPLETE_EXPIRED = 'incomplete_expired',
	TRIALING = 'trialing',
	ACTIVE = 'active',
	PAST_DUE = 'past_due',
	CANCELED = 'canceled',
	UNPAID = 'unpaid'
}


export interface SubscriptionInfoPlan
{
	id: string;
	amount: number;
	currency: string;
	interval: SubscriptionPeriod;
}

export interface SubscriptionInfoCard
{
	expMonth: number;
	expYear: number;
	last4: number;
	brand: string;
	holder: string;
}


export interface SubscriptionInfo
{
	id: string;
	status: SubscriptionStatus;
	balance: number;
	cancelAtPeriodEnd: boolean;
	currentPeriodStart: string;
	currentPeriodEnd: string;
	plan: SubscriptionInfoPlan;
	card: SubscriptionInfoCard|null;
}


export interface SubscriptionInvoice
{
	id: string;
	description: string;
	paid: boolean;
	created: string;
	currency: string;
	pdf: string;
	link: string;
	total: number;
}


export interface SubscriptionInfoResponse
{
	info: SubscriptionInfo;
	invoices: SubscriptionInvoice[];
}


export interface SubscriptionCreatingResponse
{
	sessionId: string;
}


export interface SubscriptionResumingResponse extends SubscriptionInfoResponse
{
	sessionId?: string;
}


export type SubscriptionApiResponse = ApiResponse<SubscriptionCreatingResponse>;
export type SubscriptionResumingApiResponse = ApiResponse<SubscriptionResumingResponse>;
export type SubscriptionInfoApiResponse = ApiResponse<SubscriptionInfoResponse>;
export type SubscriptionPlainApiResponse = ApiResponse<{}>;

export async function subscriptionStartRequest(accessToken: string, plan: SubscriptionPlan, coupon?: string): Promise<SubscriptionApiResponse>
{
	const response = await axios.post<SubscriptionApiResponse>('/api/subscription', {plan, coupon}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function subscriptionResumeRequest(accessToken: string): Promise<SubscriptionResumingApiResponse>
{
	const response = await axios.put<SubscriptionResumingApiResponse>('/api/subscription/resume', {}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function subscriptionCompleteRequest(accessToken: string, sessionId: string): Promise<SubscriptionPlainApiResponse>
{
	const response = await axios.post<SubscriptionPlainApiResponse>('/api/subscription/complete', {sessionId}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function subscriptionCancelRequest(accessToken: string): Promise<SubscriptionInfoApiResponse>
{
	const response = await axios.delete<SubscriptionInfoApiResponse>('/api/subscription', {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function subscriptionInfoRequest(accessToken: string): Promise<SubscriptionInfoApiResponse>
{
	const response = await axios.get<SubscriptionInfoApiResponse>('/api/subscription', {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function subscriptionChangePlanRequest(accessToken: string, plan: SubscriptionPlan): Promise<SubscriptionInfoResponse>
{
	const response = await axios.put<SubscriptionInfoResponse>('/api/subscription/plan', {plan}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}