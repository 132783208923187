import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Paper, TextField} from '@material-ui/core';
import {
    CancelRounded,
    DateRangeRounded,
    EditRounded,
    OpenInNewRounded,
    PaymentRounded,
    RefreshRounded
} from '@material-ui/icons';
import {SubscriptionInfo, SubscriptionInvoice, SubscriptionPlan, SubscriptionStatus} from '../../api/Subscription';
import {
    SubscriptionCancelStatus,
    SubscriptionChangePlanStatus,
    SubscriptionInfoStatus,
    SubscriptionResumeStatus
} from '../../model/subscription/subscriptionState';
import {actionSubscriptionInfo} from '../../model/subscription/actions/SubscriptionInfoAction';
import moment from 'moment';
import {actionSubscriptionResume} from '../../model/subscription/actions/SubscriptionResumeAction';
import {AlertType, sendAlert} from '../Alert';
import {actionSubscriptionCancel} from '../../model/subscription/actions/SubscriptionCancelAction';
import {actionSubscriptionChangePlan} from '../../model/subscription/actions/SubscriptionChangePlanAction';
import {WithTranslation, withTranslation} from 'react-i18next';
import {CardElement} from "@stripe/react-stripe-js";
import {Stripe, StripeElements} from "@stripe/stripe-js";
import {Billing} from "../../api/Billing";
import {actionSubscriptionUpdateCard} from "../../model/subscription/actions/SubscriptionCardAction";
import {stripePromise} from "../Stripe";


interface StateProps {
    // Info
    info: SubscriptionInfoStatus;
    subscription?: SubscriptionInfo;
    invoices: SubscriptionInvoice[];

    // Resuming
    resume: SubscriptionResumeStatus;
    sessionId?: string;

    // Canceling
    cancel: SubscriptionCancelStatus;

    // Change Plan
    plan: SubscriptionChangePlanStatus;

    // Billing
    billing?: Billing;
    publishableKey: string;
}

interface OwnProps {
    stripe: Stripe | null;
    elements: StripeElements | null;
}


interface DispatchProps {
    load: () => void;
    cancelSubscription: () => void;
    resumeSubscription: () => void;
    changePlan: (plan: SubscriptionPlan) => void;
    updateCard: (id: string) => void;
}


type Props = StateProps & DispatchProps & WithTranslation & OwnProps;


interface State {
    isCanceling: boolean;
    isChangingPlan: boolean;
    isCardUpdating: boolean;
    isCardLoading: boolean;
    cardHolder: string;
    cardCompleted: boolean;
}


class BareSubscriptionInfoView extends React.Component<Props, State> {
    public state: State = {
        isCanceling: false,
        isChangingPlan: false,
        isCardUpdating: false,
        isCardLoading: false,
        cardHolder: '',
        cardCompleted: false
    };


    public componentDidMount(): void {
        this.props.load();
    }


    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (!prevProps.sessionId && this.props.sessionId && this.props.resume === SubscriptionResumeStatus.started) {
            this.checkout();
        }

        if (!prevProps.subscription && this.props.subscription && this.props.subscription.card) {
            this.setState({cardHolder: this.props.subscription.card.holder});
        }
    }


    public render = () => {
        const {subscription} = this.props;
        if (this.props.info === SubscriptionInfoStatus.loading
            || this.props.resume === SubscriptionResumeStatus.loading
            || this.props.cancel === SubscriptionCancelStatus.loading
            || this.props.plan === SubscriptionChangePlanStatus.loading
            || !subscription) {
            return (<div className="Loader">Loading…</div>);
        }

        const isMonthly = subscription.plan.interval.toString() === 'MONTH';

        return (
            <>
                <div className='Form Form--full'>
                    <div className='Form__column Form__column--full'>
                        <div className='Form__group'>
                            <h2>{this.props.t('plan.title')}</h2>
                            {subscription.status !== SubscriptionStatus.CANCELED && subscription.status !== SubscriptionStatus.ACTIVE && (
                                <p>
                                    {this.props.t('plan.info.basic')}
                                    {subscription.status === SubscriptionStatus.TRIALING && (
                                        <>
                                            {subscription.cancelAtPeriodEnd ? (
                                                <a className='Text--running' href='#restore'
                                                   onClick={this.resumeSubscription}>
                                                    &nbsp;
                                                    <strong>
                                                        {this.props.t('plan.info.canceled.restore')}&nbsp;
                                                        <PaymentRounded style={{position: 'relative', top: 3}}
                                                                        fontSize={'inherit'}/>
                                                    </strong>
                                                </a>
                                            ) : (
                                                <strong>
                                                    &nbsp;
                                                    <a className='Text--dns' href='#cancel-subscription'
                                                       onClick={this.onCancelingOpened}>
                                                        {this.props.t('plan.info.active.fourth')}&nbsp;
                                                        <CancelRounded style={{position: 'relative', top: 3}}
                                                                       fontSize={'inherit'}/>
                                                    </a>
                                                </strong>
                                            )}
                                        </>
                                    )}
                                </p>
                            )}
                            {subscription.status === SubscriptionStatus.CANCELED && (
                                <p>
                                    {this.props.t('plan.info.canceled.first')}
                                    <br/>
                                    {this.props.t('plan.info.canceled.second')}&nbsp;
                                    <a className='Text--running' href='#restore' onClick={this.resumeSubscription}>
                                        <strong>
                                            {this.props.t('plan.info.canceled.restore')}&nbsp;
                                            <PaymentRounded style={{position: 'relative', top: 3}}
                                                            fontSize={'inherit'}/>
                                        </strong>
                                    </a>
                                </p>
                            )}
                            {subscription.status === SubscriptionStatus.ACTIVE && subscription.cancelAtPeriodEnd && (
                                <p>
									<span dangerouslySetInnerHTML={{
                                        __html: this.props.t('plan.info.ending.first', {
                                            date: `<strong>${moment(subscription.currentPeriodEnd).format('MMMM Do YYYY')}</strong>`
                                        })
                                    }}/>
                                    <br/>
                                    {this.props.t('plan.info.ending.second')}&nbsp;
                                    <a className='Text--running' href='#restore' onClick={this.resumeSubscription}>
                                        <strong>
                                            {this.props.t('plan.info.ending.restore')}&nbsp;
                                            <RefreshRounded style={{position: 'relative', top: 3}}
                                                            fontSize={'inherit'}/>
                                        </strong>
                                    </a>
                                </p>
                            )}
                            {subscription.status === SubscriptionStatus.ACTIVE && !subscription.cancelAtPeriodEnd && (
                                <p>
                                    {this.props.t('plan.info.active.first')}&nbsp;
                                    <strong>
                                        <a href='#change-period' onClick={this.onChangingPlanOpened}>
                                            {this.props.t('plan.info.active.second')}&nbsp;
                                            <DateRangeRounded style={{position: 'relative', top: 3}}
                                                              fontSize={'inherit'}/>
                                        </a>
                                    </strong>
                                    &nbsp;{this.props.t('plan.info.active.third')}&nbsp;
                                    <strong>
                                        <a className='Text--dns' href='#cancel-subscription'
                                           onClick={this.onCancelingOpened}>
                                            {this.props.t('plan.info.active.fourth')}&nbsp;
                                            <CancelRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
                                        </a>
                                    </strong>.
                                </p>
                            )}
                            <Paper variant={'outlined'} className='Plan'>
                                <div className='Plan__section'>
                                    <div className='Plan__sectionTitle'>{this.props.t('plan.status.label')}</div>
                                    <div className='Plan__sectionValue'>
                                        {subscription.status === SubscriptionStatus.ACTIVE ? (
                                            <span className='Text--running'>
												{this.props.t('plan.status.statuses.active').toUpperCase()}
											</span>
                                        ) : (
                                            <span className='Text--dns'>
												{this.props.t(`plan.status.statuses.${subscription.status}`).toUpperCase()}
											</span>
                                        )}
                                    </div>
                                </div>
                                <div className='Plan__section'>
                                    <div className='Plan__sectionTitle'>{this.props.t('plan.plan')}</div>
                                    <div className='Plan__sectionValue'>
                                        {subscription.plan.amount.toFixed(0)} {subscription.plan.currency} / {subscription.plan.interval.toLowerCase()}
                                    </div>
                                </div>
                                <div className='Plan__section'>
                                    <div className='Plan__sectionTitle'>{this.props.t('plan.validUntil')}</div>
                                    <div
                                        className='Plan__sectionValue'>{moment(subscription.currentPeriodEnd).format('MMMM Do YYYY')}</div>
                                </div>
                                {subscription.status !== SubscriptionStatus.CANCELED && (
                                    <div className='Plan__section'>
                                        <div className='Plan__sectionTitle'>{this.props.t('plan.renewal')}</div>
                                        <div className='Plan__sectionValue'>
                                            {subscription.cancelAtPeriodEnd ? (
                                                <span className='Text--dns'>{this.props.t('plan.disabled')}</span>
                                            ) : (
                                                <span>{this.props.t('plan.enabled')}</span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Paper>
                        </div>
                    </div>
                    {subscription.card !== null && (
                        <div className='Form__column Form__column--full'>
                            <div className='Form__group Form__card'>
                                <h2>{this.props.t('card.title')}</h2>
                                <p>{this.props.t('card.description')} <strong>**** ****
                                    **** {subscription.card.last4}</strong> ({this.props.t('card.validUntil')}: {subscription.card.expMonth}/{subscription.card.expYear}).</p>
                                <Button onClick={() => this.onCardUpdatingOpened(true)} className='Button'
                                        variant="outlined" size="small" color="primary"
                                        startIcon={(<EditRounded/>)}>
                                    {this.props.t('card.update')}
                                </Button>
                            </div>
                        </div>
                    )}
                    {this.state.isCardUpdating && (
                        <Dialog
                            disableBackdropClick={false}
                            disableEscapeKeyDown={false}
                            className='Dialog'
                            open={true}
                            onClose={this.onCancelingClosed}>
                            <h2>{this.props.t('card.modal.title')}</h2>
                            <DialogContent>
                                <DialogContentText style={{marginBottom: 24}}>
                                    {this.props.t('card.modal.description')}
                                </DialogContentText>
                                <TextField
                                    value={this.state.cardHolder}
                                    onChange={(e) => this.setState({cardHolder: e.target.value})}
                                    required={true}
                                    className='TextField'
                                    label={this.props.t('card.modal.holder')}
                                    variant="outlined"
                                    fullWidth={true}
                                />
                                <CardElement options={{
                                    style: {base: {fontFamily: 'SF Mono,SF Pro Icons,Menlo,monospace'}},
                                    value: {postalCode: this.props.billing?.zipCode},
                                    hidePostalCode: true
                                }} onChange={(e) => this.setState({cardCompleted: e.complete && !e.empty && !e.error})}/>
                                <Button
                                    disabled={this.state.isCardLoading || !this.isCardUpdateValid()}
                                    onClick={this.updateCard}
                                    variant="contained"
                                    color="primary"
                                    size="large">
                                    {this.state.isCardLoading ? this.props.t('card.modal.loading') : this.props.t('card.modal.update')}
                                </Button>
                                <Button
                                    className='Dialog__cancel'
                                    onClick={() => this.onCardUpdatingOpened(false)}
                                    size="large"
                                    variant='outlined'
                                    color="default">
                                    {this.props.t('card.modal.dismiss')}
                                </Button>
                            </DialogContent>
                            <DialogActions/>
                        </Dialog>
                    )}
                    {subscription.balance > 0 && (
                        <div className='Form__column Form__column--full'>
                            <div className='Form__group'>
                                <h2>{this.props.t('plan.balance.title')}</h2>
                                <div
                                    className='Balance'>{subscription.balance.toFixed(2)} {subscription.plan.currency}</div>
                                <p style={{marginTop: -2}}
                                   dangerouslySetInnerHTML={{__html: this.props.t('plan.balance.message')}}/>
                            </div>
                        </div>
                    )}
                    <div className='Form__column Form__column--full'>
                        <div className='Form__group'>
                            <h2>{this.props.t('plan.invoices.title')}</h2>
                            <div className='Invoice__list'>
                                {this.props.invoices.map((invoice, key) => (
                                    <div className='Invoice' key={key}>
                                        <div className='Invoice__section'>
                                            <div className='Invoice__title'>{invoice.description}</div>
                                            <div
                                                className='Invoice__subtitle'>{moment(invoice.created).format('MMMM Do YYYY')}</div>
                                        </div>
                                        <div className='Invoice__section'>
                                            <div
                                                className='Invoice__title'>{invoice.total.toFixed(2)} {invoice.currency}</div>
                                            <div className='Invoice__subtitle'>
                                                {invoice.paid ? (
                                                    <span
                                                        className='Text--running'>{this.props.t('plan.invoices.paid')}</span>
                                                ) : (
                                                    <span
                                                        className='Text--paused'>{this.props.t('plan.invoices.unpaid')}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className='Invoice__section'>
                                            <a href={invoice.link} target={'_blank'} rel='noopener noreferrer'>
                                                <Button className='Button' variant="outlined" size="small"
                                                        color="primary"
                                                        startIcon={(<OpenInNewRounded/>)}>
                                                    {this.props.t('plan.invoices.details')}
                                                </Button>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isCanceling && (
                    <Dialog
                        disableBackdropClick={false}
                        disableEscapeKeyDown={false}
                        className='Dialog'
                        open={true}
                        onClose={this.onCancelingClosed}>
                        <h2>{this.props.t('plan.cancel.title')}</h2>
                        <DialogContent>
                            <DialogContentText style={{marginBottom: 24}} dangerouslySetInnerHTML={{
                                __html: this.props.t('plan.cancel.message', {
                                    date: `<strong>${moment(subscription.currentPeriodEnd).format('MMMM Do YYYY')}</strong>`
                                })
                            }}/>
                            <Button
                                onClick={this.cancelSubscription}
                                variant="contained"
                                color="primary"
                                size="large">
                                {this.props.t('plan.cancel.cancel')}
                            </Button>
                            <Button
                                className='Dialog__cancel'
                                onClick={this.onCancelingClosed}
                                size="large"
                                variant='outlined'
                                color="default">
                                {this.props.t('plan.cancel.dismiss')}
                            </Button>
                        </DialogContent>
                        <DialogActions/>
                    </Dialog>
                )}
                {this.state.isChangingPlan && (
                    <Dialog
                        disableBackdropClick={false}
                        disableEscapeKeyDown={false}
                        className='Dialog'
                        open={true}
                        onClose={this.onChangingPlanClosed}>
                        <h2>{isMonthly ? this.props.t('plan.change.toYearly.title') : this.props.t('plan.change.toMonthly.title')}</h2>
                        <DialogContent>
                            <DialogContentText style={{marginBottom: 24}}>
                                {isMonthly && (
                                    <span>{this.props.t('plan.change.toYearly.message')}</span>
                                )}
                                {!isMonthly && (
                                    <span>{this.props.t('plan.change.toMonthly.message')}</span>
                                )}
                            </DialogContentText>
                            <Button
                                onClick={this.changePeriod}
                                variant="contained"
                                color="primary"
                                size="large">
                                {this.props.t(`plan.change.${isMonthly ? 'toYearly' : 'toMonthly'}.change`)}
                            </Button>
                            <Button
                                className='Dialog__cancel'
                                onClick={this.onChangingPlanClosed}
                                size="large"
                                variant='outlined'
                                color="default">
                                {this.props.t('plan.change.dismiss')}
                            </Button>
                        </DialogContent>
                        <DialogActions/>
                    </Dialog>
                )}
            </>
        );
    };

    protected onChangingPlanOpened = (e: any) => {
        e.preventDefault();
        this.setState({isChangingPlan: true});
    };

    protected onChangingPlanClosed = () => this.setState({isChangingPlan: false});

    protected changePeriod = () => {
        const {subscription} = this.props;
        if (!subscription) {
            return;
        }

        const isMonthly = subscription.plan.interval.toString() === 'MONTH';

        this.props.changePlan(isMonthly ? SubscriptionPlan.YEARLY : SubscriptionPlan.MONTHLY);
        this.setState({isChangingPlan: false});
    };

    protected onCardUpdatingOpened = (isCardUpdating: boolean) => {

        if (isCardUpdating && this.props.subscription && this.props.subscription.card) {
            this.setState({cardHolder: this.props.subscription.card.holder});
        }

        if (isCardUpdating) {
            this.setState({cardCompleted: false});
        }

        this.setState({isCardUpdating});
    };

    protected isCardUpdateValid = (): boolean => {
        const {elements} = this.props;
        const {cardHolder} = this.state;
        if (!elements) {
            return false;
        }

        const card = elements.getElement(CardElement);

        return !!card && cardHolder.length > 0;
    };

    protected updateCard = async () => {
        const {stripe, elements} = this.props;
        const {cardHolder} = this.state;

        if (!stripe || !elements || !cardHolder || cardHolder.length === 0) {
            return;
        }

        const card = elements.getElement(CardElement);
        if (!card) {
            return;
        }

        this.setState({isCardLoading: true});
        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: {
                name: cardHolder
            }
        });

        const {paymentMethod} = payload;
        if (!paymentMethod || !paymentMethod.id) {
            this.setState({isCardLoading: false});
            return;
        }

        this.props.updateCard(paymentMethod.id);
        this.onCardUpdatingOpened(false);
        this.setState({isCardLoading: false});
    };

    protected onCancelingOpened = (e: any) => {
        e.preventDefault();
        this.setState({isCanceling: true});
    };

    protected onCancelingClosed = () => this.setState({isCanceling: false});

    protected cancelSubscription = () => {
        this.setState({isCanceling: false});
        this.props.cancelSubscription();
    };

    protected resumeSubscription = (e: any) => {
        e.preventDefault();
        this.props.resumeSubscription();
    };

    protected checkout = async () => {
        const {sessionId} = this.props;
        if (!sessionId) {
            return;
        }

        const stripe = await stripePromise(this.props.publishableKey);
        if (!stripe) {
            return;
        }

        const {error} = await stripe.redirectToCheckout({sessionId});
        if (error && error.message) {
            sendAlert(AlertType.error, error.message);
        }
    };
}


export const SubscriptionInfoView = withTranslation('app')(connect(
    (state: AppState) => ({
        info: state.subscription.info,
        subscription: state.subscription.subscription,
        invoices: state.subscription.invoices || [],
        resume: state.subscription.resume,
        sessionId: state.subscription.sessionId,
        cancel: state.subscription.cancel,
        plan: state.subscription.plan,
        billing: state.user.billing,
        publishableKey: state.user.user?.publishableKey || ''
    }),
    (dispatch: AppThunkDispatch) => ({
        load: () => dispatch(actionSubscriptionInfo()),
        cancelSubscription: () => dispatch(actionSubscriptionCancel()),
        resumeSubscription: () => dispatch(actionSubscriptionResume()),
        changePlan: (plan: SubscriptionPlan) => dispatch(actionSubscriptionChangePlan(plan)),
        updateCard: (id: string) => dispatch(actionSubscriptionUpdateCard(id)).then(() => dispatch(actionSubscriptionInfo()))
    })
)(BareSubscriptionInfoView));