import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {EventCreateStatus, EventLoadingStatus, EventState} from '../eventState';
import {EventListAction, TYPE_LIST} from './types';
import {eventListRequest} from '../../../api/Event';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionEventListAction = (isLoading: boolean): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	let {events: {events}} = getState();
	dispatch<EventListAction>({
		type: TYPE_LIST,
		events: isLoading ? [] : events,
		loading: isLoading ? EventLoadingStatus.loading : EventLoadingStatus.loaded,
		creating: EventCreateStatus.prepared
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await eventListRequest(accessToken).then((result: any) =>
	{
		const {payload} = result;
		dispatch<EventListAction>({
			type: TYPE_LIST,
			loading: EventLoadingStatus.loaded,
			events: payload
		});
	}).catch((result: any) =>
	{
		dispatch<EventListAction>({
			type: TYPE_LIST,
			loading: EventLoadingStatus.loaded,
			events: []
		});

		if (!result.response) {
			return;
		}

		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		sendAlert(AlertType.error, message);
	});
};

export const reduceEventListAction = (state: EventState, action: EventListAction): EventState => ({
	...state,
	...action
});