import {
	SubscriptionAction,
	TYPE_CANCEL,
	TYPE_CHANGE_PLAN,
	TYPE_COMPLETE,
	TYPE_INFO,
	TYPE_RESUME,
	TYPE_START,
	TYPE_UPDATE_CARD
} from './actions/types';
import {Reducer} from 'redux';
import {combineCaseReducers} from '../reducerCombiners';
import {initialSubscriptionState, SubscriptionState} from './subscriptionState';
import {reduceSubscriptionStart} from './actions/SubscriptionStartAction';
import {reduceSubscriptionComplete} from './actions/SubscriptionCompleteAction';
import {reduceSubscriptionInfo} from './actions/SubscriptionInfoAction';
import {reduceSubscriptionResume} from './actions/SubscriptionResumeAction';
import {reduceSubscriptionCancel} from './actions/SubscriptionCancelAction';
import {reduceSubscriptionChangePlan} from './actions/SubscriptionChangePlanAction';
import {reduceSubscriptionUpdateCard} from "./actions/SubscriptionCardAction";


const caseReducers: { [k in SubscriptionAction["type"]]: Reducer } = {
	[TYPE_START]: reduceSubscriptionStart,
	[TYPE_RESUME]: reduceSubscriptionResume,
	[TYPE_CANCEL]: reduceSubscriptionCancel,
	[TYPE_COMPLETE]: reduceSubscriptionComplete,
	[TYPE_INFO]: reduceSubscriptionInfo,
	[TYPE_CHANGE_PLAN]: reduceSubscriptionChangePlan,
	[TYPE_UPDATE_CARD]: reduceSubscriptionUpdateCard
};

export const subscriptionReducer = combineCaseReducers<SubscriptionState>(
	initialSubscriptionState,
	caseReducers
);