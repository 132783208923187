import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {RecoveryProcessAction, TYPE_LOADING_RECOVERY, TYPE_PROCESS_RECOVERY} from './types';
import {RecoveryState, RecoveryStatus} from '../recoveryState';
import {recoveryProcessRequest} from '../../../api/Recovery';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionRecoveryProcess = (recoveryToken: string, password: string): AppThunkAction => async (dispatch: AppThunkDispatch) =>
{
	dispatch({type: TYPE_LOADING_RECOVERY, status: RecoveryStatus.loading});
	await recoveryProcessRequest(recoveryToken, password).then((result: any) =>
	{
		const {message} = result;
		const status = RecoveryStatus.success;

		dispatch({
			type: TYPE_PROCESS_RECOVERY,
			status
		});

		sendAlert(AlertType.success, message);
	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;
		const status = RecoveryStatus.failed;

		dispatch({
			type: TYPE_PROCESS_RECOVERY,
			status
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceRecoveryProcess = (state: RecoveryState, action: RecoveryProcessAction): RecoveryState => ({
	...state,
	...action
});