import {initialUserState, UserState} from './user/userState';
import {initialRecoveryState, RecoveryState} from './recovery/recoveryState';
import {DeviceState, initialDeviceState} from './device/deviceState';
import {EventState, initialEventState} from './event/eventState';
import {initialSubscriptionState, SubscriptionState} from './subscription/subscriptionState';
import {CouponState, initialCouponState} from "./coupon/couponState";


export interface AppState {
    user: UserState;
    recovery: RecoveryState;
    device: DeviceState;
    events: EventState;
    subscription: SubscriptionState;
    coupon: CouponState;
}


export const initialAppState: AppState = {
    user: initialUserState,
    recovery: initialRecoveryState,
    device: initialDeviceState,
    events: initialEventState,
    subscription: initialSubscriptionState,
    coupon: initialCouponState
};