import React from 'react';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {EVENTS_CREATE} from '../../routes';
import {Layout} from '../../components/Layout';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Event, EventStatus} from '../../api/Event';
import {actionEventListAction} from '../../model/event/actions/ListAction';
import {EventLoadingStatus} from '../../model/event/eventState';
import {EventListItem} from '../../components/event/EventListItem';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	events: Event[];
	status: EventLoadingStatus;
}


interface DispatchProps
{
	load: (isLoading: boolean) => void;
}


type Props = StateProps & DispatchProps;


interface State
{

}


class BareAllEventsScreen extends React.PureComponent<Props & WithTranslation, State>
{

	public state: State = {};

	protected timeOut?: NodeJS.Timeout;


	public componentDidMount(): void
	{
		this.props.load(true);
	}


	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void
	{
		let isCreating = false;
		const {events} = this.props;

		events.forEach((e: Event) =>
		{
			if (e.status === EventStatus.creating || e.status === EventStatus.queue) {
				isCreating = true;
			}
		});

		if (this.timeOut) {
			clearInterval(this.timeOut);
		}

		if (!isCreating) {
			return;
		}

		this.timeOut = setInterval(() => this.props.load(false), 1000 * 30);
	}


	public componentWillUnmount(): void
	{
		if (this.timeOut) {
			clearInterval(this.timeOut);
		}
	}


	public render = () =>
	{
		return (
			<Layout title={this.props.t('events.list.title')}>
				<div className='PageHeader'>
					<h1>{this.props.t('events.list.title')}</h1>
					<Link to={EVENTS_CREATE}>
						<Button
							className='Button'
							variant="contained"
							size="large"
							color="secondary">
							{this.props.t('events.list.create')}
						</Button>
					</Link>
				</div>
				{this.props.status === EventLoadingStatus.loading && (<div className="Loader">Loading…</div>)}
				{this.props.events.length > 0 && (
					<div className='Animated'>
						<div className='EventList'>
							{this.props.events.map((event, key) => (
								<EventListItem key={key} event={event}/>
							))}
						</div>
					</div>
				)}
				{this.props.status !== EventLoadingStatus.loading && this.props.events.length === 0 && (
					<div className='Animated'>
						<div className='EventEmpty'>
							<h2>{this.props.t('events.list.empty.title')}</h2>
							<p>{this.props.t('events.list.empty.message')}</p>
						</div>
					</div>
				)}
			</Layout>
		);
	};
}


export const AllEventsScreen = withTranslation('app')(connect(
	(state: AppState) => ({
		events: state.events.events || [],
		status: state.events.loading
	}),
	(dispatch: AppThunkDispatch) => ({
		load: (isLoading: boolean) => dispatch(actionEventListAction(isLoading))
	})
)(BareAllEventsScreen));