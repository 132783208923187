import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Event, UpdatingEvent} from '../../api/Event';
import {actionEventUpdate} from '../../model/event/actions/UpdateAction';
import {EventCreateStatus} from '../../model/event/eventState';
import {Button, TextField} from '@material-ui/core';
import {generateHtmlId} from '../../utils/htmlId';
import PasswordField from '../form/PasswordField';
import {PasswordChecker} from '../form/PasswordChecker';
import {OpenInNewRounded} from '@material-ui/icons';
import {actionEventDetail} from '../../model/event/actions/DetailAction';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	status: EventCreateStatus;
	event: Event;
}


interface DispatchProps
{
	update: (uid: string, event: UpdatingEvent) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	adminPassword?: string;
}


class BareEventAdminForm extends React.Component<Props, State>
{
	public state: State = {
		adminPassword: undefined
	};

	protected onPasswordChanged = (adminPassword: string) => this.setState({adminPassword});

	protected isFormValid = (): boolean =>
	{
		return !!this.state.adminPassword && this.state.adminPassword.length > 0;
	};

	protected save = (e: any) =>
	{
		e.preventDefault();
		if (!this.isFormValid()) {
			return;
		}

		let {adminPassword} = this.state;
		this.props.update(this.props.event.uid, {adminPassword});
	};

	public render = () =>
	{
		const {event, status} = this.props;
		const hasError = status === EventCreateStatus.failed;
		const isSaving = status === EventCreateStatus.loading;

		return (
			<form className='Form Form--full' onSubmit={this.save}>
				<div className='Form__column Form__column--full'>
					<p>{this.props.t('events.edit.admin.message')}</p>
					<div className='Form__group'>
						<div className='Form__item'>
							<TextField
								disabled={true}
								error={hasError}
								type={'email'}
								required={true}
								defaultValue={event.sharedInfo.adminEmail}
								className='TextField'
								label={this.props.t('events.edit.admin.email')}
								variant="outlined"
								fullWidth={true}
								autoComplete={generateHtmlId()}
							/>
						</div>
						<div className='Form__item'>
							<PasswordField
								disabled={isSaving}
								error={hasError}
								required={true}
								label={this.props.t('events.edit.admin.password')}
								value={this.state.adminPassword || ''}
								onChange={this.onPasswordChanged}
								autoComplete={generateHtmlId()}
							/>
							<PasswordChecker password={this.state.adminPassword || ''}/>
						</div>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<Button
						disabled={!this.isFormValid() || isSaving}
						onSubmit={this.save}
						type='submit'
						className='Button'
						variant="contained"
						size="large"
						color="secondary">
						{isSaving ? this.props.t('events.edit.admin.change.changing') : this.props.t('events.edit.admin.change.default')}
					</Button>
					<a href={`https://${event.key}.event.simpleflow.io/admin`} target='_blank' rel='noopener noreferrer' style={{marginLeft: 16}}>
						<Button className='Button' variant="outlined" size="large" color="primary" startIcon={(<OpenInNewRounded/>)}>
							{this.props.t('events.edit.admin.admin')}
						</Button>
					</a>
				</div>
			</form>
		);
	};
}


export const EventAdminForm = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.events.updating
	}),
	(dispatch: AppThunkDispatch) => ({
		update: (uid: string, event: UpdatingEvent) => dispatch(actionEventUpdate(uid, event))
			.then(() => dispatch(actionEventDetail(uid)))
	})
)(BareEventAdminForm));