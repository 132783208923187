import React from 'react';
import {Redirect, RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Layout} from '../../components/Layout';
import {Link} from 'react-router-dom';
import {EVENTS_LIST} from '../../routes';
import {ButtonBase, IconButton} from '@material-ui/core';
import {ArrowBackRounded} from '@material-ui/icons';
import {Event, EventStatus} from '../../api/Event';
import {actionEventDetail} from '../../model/event/actions/DetailAction';
import {EventCreateStatus, EventLoadingStatus} from '../../model/event/eventState';
import {EventApiForm} from '../../components/event/EventApiForm';
import {EventAdminForm} from '../../components/event/EventAdminForm';
import {EventGeneralForm} from '../../components/event/EventGeneralForm';
import {EventModulesForm} from '../../components/event/EventModulesForm';
import {EventDomainForm} from '../../components/event/EventDomainForm';
import {EventLanguagesForm} from '../../components/event/EventLanguagesForm';
import {withTranslation, WithTranslation} from 'react-i18next';


enum EditEventTab
{
	general,
	modules,
	domain,
	languages,
	admin,
	api,
	danger
}


interface StateProps
{
	event?: Event;
	isDisabled: boolean;
	status: EventLoadingStatus;
}


interface DispatchProps
{
	load: (uid: string) => void;
}


type Props = StateProps & DispatchProps & RouteComponentProps<{ uid: string }> & WithTranslation;


interface State
{
	tab: EditEventTab;
}


class BareEditEventScreen extends React.PureComponent<Props, State>
{

	public state: State = {
		tab: EditEventTab.general
	};


	public componentDidMount(): void
	{
		const {uid} = this.props.match.params;
		this.props.load(uid);
	}


	protected changeTab = (tab: EditEventTab) => this.setState({tab});

	public render = () =>
	{
		const {status, event, isDisabled} = this.props;
		if (status === EventLoadingStatus.loaded && !event) {
			return (<Redirect to={EVENTS_LIST}/>);
		}

		if (status === EventLoadingStatus.loading || !event) {
			return (
				<Layout title='Loading…'>
					<div className="Loader">Loading…</div>
				</Layout>
			);
		}

		if (event.status === EventStatus.queue || event.status === EventStatus.creating) {
			return (<Redirect to={EVENTS_LIST}/>);
		}

		return (
			<Layout title={event.name} link={EVENTS_LIST}>
				<div className='PageHeader'>
					<Link to={EVENTS_LIST}>
						<IconButton disabled={isDisabled} className='PageHeader__back'>
							<ArrowBackRounded fontSize="default"/>
						</IconButton>
					</Link>
					<h1>
						{event.name}
						<div className={`Event__status Event__status--${event.status}`}
							 onClick={() => this.changeTab(event.status === EventStatus.paused ? EditEventTab.danger : EditEventTab.domain)}>
							{this.props.t(`event.status.${event.status}`)}
						</div>
					</h1>
				</div>
				<div className='Animated'>
					<div className='Tabs'>
						<ButtonBase disabled={isDisabled} onClick={() => this.changeTab(EditEventTab.general)}
									className={`Tabs__item ${this.state.tab === EditEventTab.general && 'Tabs__item--active'}`}>
							{this.props.t('events.edit.tabs.general')}
						</ButtonBase>
						<ButtonBase disabled={isDisabled} onClick={() => this.changeTab(EditEventTab.modules)}
									className={`Tabs__item ${this.state.tab === EditEventTab.modules && 'Tabs__item--active'}`}>
							{this.props.t('events.edit.tabs.modules')}
						</ButtonBase>
						<ButtonBase disabled={isDisabled} onClick={() => this.changeTab(EditEventTab.domain)}
									className={`Tabs__item ${this.state.tab === EditEventTab.domain && 'Tabs__item--active'}`}>
							{this.props.t('events.edit.tabs.domain')}
						</ButtonBase>
						<ButtonBase disabled={isDisabled} onClick={() => this.changeTab(EditEventTab.languages)}
									className={`Tabs__item ${this.state.tab === EditEventTab.languages && 'Tabs__item--active'}`}>
							{this.props.t('events.edit.tabs.languages')}
						</ButtonBase>
						<ButtonBase disabled={isDisabled} onClick={() => this.changeTab(EditEventTab.admin)}
									className={`Tabs__item ${this.state.tab === EditEventTab.admin && 'Tabs__item--active'}`}>
							{this.props.t('events.edit.tabs.admin')}
						</ButtonBase>
						<ButtonBase disabled={isDisabled} onClick={() => this.changeTab(EditEventTab.api)}
									className={`Tabs__item ${this.state.tab === EditEventTab.api && 'Tabs__item--active'}`}>
							{this.props.t('events.edit.tabs.api')}
						</ButtonBase>
					</div>
					{this.state.tab === EditEventTab.api ? (<EventApiForm event={event}/>) : null}
					{this.state.tab === EditEventTab.admin ? (<EventAdminForm event={event}/>) : null}
					{this.state.tab === EditEventTab.general ? (<EventGeneralForm event={event}/>) : null}
					{this.state.tab === EditEventTab.modules ? (<EventModulesForm event={event}/>) : null}
					{this.state.tab === EditEventTab.domain ? (<EventDomainForm event={event}/>) : null}
					{this.state.tab === EditEventTab.languages ? (<EventLanguagesForm event={event}/>) : null}
				</div>
			</Layout>
		);
	};
}


export const EditEventScreen = withTranslation('app')(connect(
	(state: AppState) => ({
		event: state.events.event,
		status: state.events.detail,
		isDisabled: state.events.updating === EventCreateStatus.loading || state.events.updating === EventCreateStatus.uploading
	}),
	(dispatch: AppThunkDispatch) => ({
		load: (uid: string) => dispatch(actionEventDetail(uid))
	})
)(BareEditEventScreen));