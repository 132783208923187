import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {EVENTS_LIST, SETTINGS} from '../routes';
import {IconButton, List, ListItem, ListItemText, Menu, MenuItem} from '@material-ui/core';
import {ArrowBackRounded} from '@material-ui/icons';
import {connect} from 'react-redux';
import {AppState} from '../model/State';
import {AppThunkDispatch} from '../model/Actions';
import {actionLogout} from '../model/user/actions/LogoutAction';
import {User, UserStatus} from '../api/User';
import {WithTranslation, withTranslation} from 'react-i18next';


interface Props
{
	disabled: boolean;
	user?: User;
	title: string;
	link?: string;
	logout: () => void;
}


interface State
{
	isUserOpened: boolean;
	isTitleVisible: boolean;
}


export const userAvatar = (user?: User): ReactNode =>
{
	const givenName = user?.givenName.charAt(0);
	const familyName = user?.familyName.charAt(0);

	return (
		<div className='Navigation__userAvatar'>{givenName}{familyName}</div>
	);
};


class BareNavigation extends React.Component<Props & WithTranslation, State>
{
	public state: State = {
		isUserOpened: false,
		isTitleVisible: false
	};

	public userRef = React.createRef<HTMLDivElement>();
	public navigationRef = React.createRef<HTMLDivElement>();


	public componentDidMount(): void
	{
		window.addEventListener('scroll', this.onScroll);
		this.onScroll();
	}


	public componentWillUnmount(): void
	{
		window.removeEventListener('scroll', this.onScroll);
	}


	protected onScroll = () =>
	{
		const navigation = this.navigationRef.current;
		if (!navigation) {
			return;
		}

		const doc = document.documentElement;
		const offset = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

		if (offset > 16) {
			navigation.classList.add('Navigation--bordered');
		} else {
			navigation.classList.remove('Navigation--bordered');
		}

		if (this.props.disabled) {
			return;
		}

		this.setState({isTitleVisible: offset > 64});
	};

	protected openUserMenu = () => this.setState({isUserOpened: true});
	protected closeUserMenu = () => this.setState({isUserOpened: false});
	protected scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

	protected logout = () =>
	{
		this.closeUserMenu();
		this.props.logout();
	};

	public render = () => (
		<>
			<div className='Navigation__helper'/>
			<div className='Navigation' ref={this.navigationRef}>
				<div className='Container'>
					{this.state.isTitleVisible ? (
						<div className='Navigation__title'>
							{this.props.link && (
								<Link to={this.props.link}>
									<IconButton className='Navigation__back'>
										<ArrowBackRounded fontSize="small"/>
									</IconButton>
								</Link>
							)}
							<span onClick={this.scrollToTop}>{this.props.title}</span>
						</div>
					) : (
						<Link to={EVENTS_LIST} className={`Brand Brand--large`}>
							Simpleflow
						</Link>
					)}

					<List component="nav" className='Navigation__user'>
						<ListItem ref={this.userRef} button className='Navigation__userItem' onClick={this.openUserMenu}>
							<ListItemText
								className='Navigation__userItemText'
								primary={this.props.user?.compositeName}
								secondary={this.props.user?.email}
							/>
							{userAvatar(this.props.user)}
						</ListItem>
					</List>
				</div>
			</div>
			<Menu
				anchorEl={this.userRef.current}
				getContentAnchorEl={null}
				keepMounted
				open={this.state.isUserOpened}
				onClose={this.closeUserMenu}
				MenuListProps={{className: 'Menu'}}
				style={{width: '100%'}}
			>
				<div className='Menu__header'>
					<span>{this.props.user?.compositeName}</span>
					<p>{this.props.user?.email}</p>
				</div>
				<Link to={SETTINGS}>
					<MenuItem disabled={this.props.user?.status !== UserStatus.active}>
						{this.props.t('layout.navigation.account')}
					</MenuItem>
				</Link>
				<MenuItem onClick={this.logout}>
					{this.props.t('layout.navigation.logout')}
				</MenuItem>
			</Menu>
		</>
	);
}


export const Navigation = withTranslation('app')(connect(
	(state: AppState) => ({
		user: state.user.user
	}),
	(dispatch: AppThunkDispatch) => ({
		logout: () =>
		{
			dispatch(actionLogout);
		}
	})
)(BareNavigation));