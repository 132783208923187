import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {userRecoveryRequest} from '../../../api/User';
import {LoginRecoveryStatus, UserState} from '../userState';
import {LoginRecoverAction, TYPE_LOGIN_RECOVER} from './types';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionLoginRecover = (email: string): AppThunkAction => async (dispatch: AppThunkDispatch) =>
{
	dispatch({
		type: TYPE_LOGIN_RECOVER,
		recovery: LoginRecoveryStatus.loading
	});

	await userRecoveryRequest(email).then((result: any) =>
	{
		dispatch({
			type: TYPE_LOGIN_RECOVER,
			recovery: LoginRecoveryStatus.success
		});

		const {message} = result;
		sendAlert(AlertType.info, message);
	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch({
			type: TYPE_LOGIN_RECOVER,
			recovery: LoginRecoveryStatus.failed
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceLoginRecover = (state: UserState, action: LoginRecoverAction): UserState => ({
	...state,
	...action
});