import React from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
} from '@material-ui/core';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	servers: string[];
	domain: string;
}


interface DispatchProps
{
	onClose: () => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{

}


class BareDNSDialog extends React.Component<Props, State>
{

	public state: State = {};

	public render = () =>
	{
		return (
			<Dialog
				maxWidth={'sm'}
				className={'Dialog--help'}
				open={true}
				onClose={this.props.onClose}>
				<DialogTitle>{this.props.t('dns.title')}</DialogTitle>
				<DialogContent dividers={true}>
					<p dangerouslySetInnerHTML={{
						__html: this.props.t('dns.message', {
							domain: this.props.domain,
							servers: this.props.servers.join(', ')
						})
					}}/>
					<p dangerouslySetInnerHTML={{
						__html: this.props.t('dns.recommend', {
							domain: this.props.domain,
							servers: this.props.servers.join(', ')
						})
					}}/>
					<p>{this.props.t('dns.additional')}</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onClose} color="primary">
						{this.props.t('dns.dismiss')}
					</Button>
				</DialogActions>
			</Dialog>
		);
	};
}


export const DNSDialog = withTranslation('app')(connect(
	(state: AppState) => ({
		servers: state.user.servers || []
	}),
	(dispatch: AppThunkDispatch) => ({})
)(BareDNSDialog));