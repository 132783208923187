import {LoginStatus, UserState, ValidateStatus} from "../userState";
import {LoginAction, TYPE_LOGIN} from "./types";
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {userAuthRequest, userValidateRequest} from '../../../api/User';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionLoginFromLocalStorage: AppThunkAction = async (dispatch: AppThunkDispatch) =>
{
	const accessToken = localStorage.getItem('accessToken');
	if (!accessToken) {
		dispatch({
			type: TYPE_LOGIN,
			validate: ValidateStatus.validated
		});
		return;
	}

	dispatch({
		type: TYPE_LOGIN,
		login: LoginStatus.loading,
		validate: ValidateStatus.loading
	});

	await userValidateRequest(accessToken).then((result: any) =>
	{
		const {payload} = result;

		dispatch({
			type: TYPE_LOGIN,
			login: LoginStatus.success,
			validate: ValidateStatus.validated,
			...payload
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		localStorage.removeItem('accessToken');
		dispatch({
			type: TYPE_LOGIN,
			login: LoginStatus.prepared,
			validate: ValidateStatus.validated
		});

		sendAlert(AlertType.error, message);
	});
};

export const actionLoginByCredentials = (email: string, password: string): AppThunkAction => async (dispatch: AppThunkDispatch) =>
{
	dispatch({
		type: TYPE_LOGIN,
		login: LoginStatus.loading
	});

	await userAuthRequest(email, password).then((result: any) =>
	{
		const {payload} = result;

		localStorage.setItem('accessToken', payload.device.accessToken);
		dispatch({
			type: TYPE_LOGIN,
			login: LoginStatus.success,
			...payload
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch({
			type: TYPE_LOGIN,
			login: LoginStatus.failed
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceLogin = (state: UserState, action: LoginAction): UserState => ({
	...state,
	...action
});
