export const EVENTS_LIST = '/events';
export const EVENTS_DETAIL = '/events/detail';
export const EVENTS_CREATE = '/events/create';

export const SETTINGS = '/settings';

export const USER_LOGIN = '/auth';
export const USER_REGISTER = '/register';
export const USER_RECOVERY = '/recovery';
export const USER_CONFIRM = '/confirm';

export const SUBSCRIPTION_CANCEL = '/subscription/cancel';
export const SUBSCRIPTION_COMPLETE = '/subscription/success';

export const PAYMENT_CANCEL = '/payment/cancel';
export const PAYMENT_COMPLETE = '/payment/success';
