import {SubscriptionInfo, SubscriptionInvoice} from '../../api/Subscription';


export enum SubscriptionStartStatus
{
	prepared,
	loading,
	started
}


export enum SubscriptionResumeStatus
{
	prepared,
	loading,
	started
}


export enum SubscriptionCancelStatus
{
	prepared,
	loading,
	canceled
}


export enum SubscriptionChangePlanStatus
{
	prepared,
	loading,
	changed
}


export enum SubscriptionCompleteStatus
{
	prepared,
	completing,
	completed,
	failed
}


export enum SubscriptionInfoStatus
{
	loaded,
	loading
}


export interface SubscriptionState
{
	// Data
	sessionId?: string;
	subscription?: SubscriptionInfo;
	invoices?: SubscriptionInvoice[];

	// Statuses
	start: SubscriptionStartStatus;
	resume: SubscriptionResumeStatus;
	complete: SubscriptionCompleteStatus;
	info: SubscriptionInfoStatus;
	cancel: SubscriptionCancelStatus;
	plan: SubscriptionChangePlanStatus;
}


export const initialSubscriptionState: SubscriptionState = {
	start: SubscriptionStartStatus.prepared,
	resume: SubscriptionResumeStatus.prepared,
	complete: SubscriptionCompleteStatus.prepared,
	info: SubscriptionInfoStatus.loading,
	cancel: SubscriptionCancelStatus.prepared,
	plan: SubscriptionChangePlanStatus.prepared
};