import {eventResumeRequest} from '../../../api/Event';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {EventResumeAction, TYPE_RESUME} from './types';
import {EventCreateStatus, EventState} from '../eventState';
import {AlertType, sendAlert} from '../../../components/Alert';
import {ApiErrorResponse} from '../../../api/ApiResponse';


export const actionEventResume = (uid: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<EventResumeAction>({
		type: TYPE_RESUME,
		updating: EventCreateStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	const error = (result: any) =>
	{
		dispatch<EventResumeAction>({
			type: TYPE_RESUME,
			updating: EventCreateStatus.failed
		});

		const response: ApiErrorResponse = result.response.data;
		const {message} = response;
		sendAlert(AlertType.error, message);
	};

	await eventResumeRequest(accessToken, uid).then((result: any) =>
	{
		const {message, code} = result;

		dispatch<EventResumeAction>({
			type: TYPE_RESUME,
			updating: EventCreateStatus.success
		});

		sendAlert(code === 423 ? AlertType.warning : AlertType.success, message);

	}).catch(error);
};

export const reduceEventResume = (state: EventState, action: EventResumeAction): EventState => ({
	...state,
	...action
});