import {userConfirmResendRequest} from '../../../api/User';
import {ConfirmResendAction, TYPE_CONFIRM, TYPE_CONFIRM_RESEND} from './types';
import {ConfirmationResendStatus, UserState} from '../userState';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionUserConfirmResend = (): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch({
		type: TYPE_CONFIRM_RESEND,
		confirmationResend: ConfirmationResendStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await userConfirmResendRequest(accessToken).then((result: any) =>
	{
		dispatch({
			type: TYPE_CONFIRM,
			confirmationResend: ConfirmationResendStatus.default
		});

		const {message} = result;
		sendAlert(AlertType.info, message);
	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch({
			type: TYPE_CONFIRM,
			confirmationResend: ConfirmationResendStatus.default
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceUserConfirmResend = (state: UserState, action: ConfirmResendAction): UserState => ({
	...state,
	...action
});

