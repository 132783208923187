import {eventPayCompleteRequest} from '../../../api/Event';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {EventPayCompleteStatus, EventState} from '../eventState';
import {EventPayCompleteAction, TYPE_PAY_COMPLETE} from './types';
import {AlertType, sendAlert} from '../../../components/Alert';
import {ApiErrorResponse} from '../../../api/ApiResponse';


export const actionEventPayComplete = (sessionId: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<EventPayCompleteAction>({
		type: TYPE_PAY_COMPLETE,
		payComplete: EventPayCompleteStatus.completing
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	const error = (result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<EventPayCompleteAction>({
			type: TYPE_PAY_COMPLETE,
			payComplete: EventPayCompleteStatus.failed,
			sessionId: undefined
		});

		sendAlert(AlertType.error, message);
	};

	await eventPayCompleteRequest(accessToken, sessionId).then(() =>
	{
		dispatch<EventPayCompleteAction>({
			type: TYPE_PAY_COMPLETE,
			payComplete: EventPayCompleteStatus.completed,
			sessionId: sessionId
		});

	}).catch(error);
};

export const reduceEventPayComplete = (state: EventState, action: EventPayCompleteAction): EventState => ({
	...state,
	...action
});