import React from 'react';
import {Button} from '@material-ui/core';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {ConfirmationResendStatus} from '../../model/user/userState';
import {actionUserConfirmResend} from '../../model/user/actions/ConfirmResendAction';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	status: ConfirmationResendStatus;
}


interface DispatchProps
{
	resend: () => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{

}


class BareOnboardingConfirmation extends React.Component<Props, State>
{

	public render = () => (
		<div className='Onboarding__content Onboarding__content--center'>
			<span className='Onboarding__icon' role={'img'} aria-label={'hello emoji'}>👋</span>
			<h1>{this.props.t('onboarding.welcome.title')}</h1>
			<p dangerouslySetInnerHTML={{__html: this.props.t('onboarding.welcome.message')}}/>
			<Button
				disabled={this.props.status === ConfirmationResendStatus.loading}
				className='Button'
				variant="outlined"
				size="large"
				onClick={this.props.resend}
				color="primary">
				{this.props.status === ConfirmationResendStatus.loading ?
					this.props.t('onboarding.welcome.resend.loading') :
					this.props.t('onboarding.welcome.resend.default')
				}
			</Button>
		</div>
	);
}


export const OnboardingConfirmation = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.user.confirmationResend
	}),
	(dispatch: AppThunkDispatch) => ({
		resend: () => dispatch(actionUserConfirmResend())
	})
)(BareOnboardingConfirmation));