import React from 'react';
import {Button} from '@material-ui/core';
import {CloudUploadRounded} from '@material-ui/icons';
import {withTranslation, WithTranslation} from 'react-i18next';


interface Props
{
	defaultImage?: string;
	disabled?: boolean;
	tabIndex?: number;
	label: string;
	onChange: (file: File) => void;
}


interface State
{
	url: string;
}


class BareUploadField extends React.Component<Props & WithTranslation, State>
{
	public state: State = {
		url: ''
	};

	protected input: HTMLInputElement | null = null;

	protected chooseFile = () =>
	{
		if (!this.input) {
			return;
		}

		this.input.click();
	};

	protected onFilesChanged = () =>
	{
		if (!this.input || this.input.files === null || this.input.files.length === 0) {
			return;
		}

		const file = this.input.files[0];
		this.props.onChange(file);

		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (event) =>
		{
			if (!event.target) {
				return;
			}

			this.setState({url: event.target.result as string});
		};
	};

	public render = () =>
	{
		const isFilled = this.state.url.length > 0 || !!this.props.defaultImage;
		return (
			<div className={`Upload ${isFilled ? 'Upload--filled' : ''}`}>
				{this.state.url.length > 0 && (
					<div
						className='Upload__thumbnail'
						style={{backgroundImage: `url(${this.state.url})`}}
					/>
				)}
				{this.props.defaultImage && this.state.url.length === 0 && (
					<div
						className='Upload__thumbnail'
						style={{backgroundImage: `url(data:image/webp;base64,${this.props.defaultImage})`}}
					/>
				)}
				<span className='Upload__title'>{this.props.label}</span>
				<input
					disabled={this.props.disabled}
					ref={ref => this.input = ref}
					style={{display: 'none'}}
					multiple={true}
					type="file"
					onChange={this.onFilesChanged}
				/>
				<Button onClick={this.chooseFile}
						startIcon={<CloudUploadRounded/>}
						variant='outlined'
						disabled={this.props.disabled}
						tabIndex={this.props.tabIndex}
						className={!isFilled ? 'Upload__button' : 'Upload__button Upload__button--moved'}>
					{!isFilled ? this.props.t('upload.upload') : this.props.t('upload.change')} {this.props.label}
				</Button>
				<small
					className={!isFilled ? '' : '--moved'}
					dangerouslySetInnerHTML={{__html: this.props.t('upload.recommend')}}
				/>
			</div>
		);
	};
}


export const UploadField = withTranslation('app')(BareUploadField);