import * as React from "react";

let lastId = 0;

export function generateHtmlId(prefix?: string) {
    prefix = prefix ? prefix : "__id";
    lastId++;
    return `${prefix}${lastId}`;
}

export function withGeneratedId(callback: (id: string) => React.ReactNode) {
    return callback(generateHtmlId());
}
