import {UserDevice} from '../../api/User';


export enum DeviceLoadingStatus
{
	loaded,
	loading
}


export enum DeviceRemoveStatus
{
	prepared,
	removing,
	success,
	failed
}


export interface DeviceState
{
	loading: DeviceLoadingStatus;
	remove: DeviceRemoveStatus;
	devices: UserDevice[];
}


export const initialDeviceState: DeviceState = {
	loading: DeviceLoadingStatus.loading,
	remove: DeviceRemoveStatus.prepared,
	devices: []
};