import {Reducer} from 'redux';
import {
	EventAction,
	TYPE_CREATE,
	TYPE_DETAIL,
	TYPE_LIST,
	TYPE_PAUSE,
	TYPE_PAY,
	TYPE_PAY_COMPLETE, TYPE_PAY_EXTRA,
	TYPE_REMOVE,
	TYPE_RESUME,
	TYPE_UPDATE
} from './actions/types';
import {reduceEventListAction} from './actions/ListAction';
import {combineCaseReducers} from '../reducerCombiners';
import {EventState, initialEventState} from './eventState';
import {reduceEventCreate} from './actions/CreateAction';
import {reduceEventDetailAction} from './actions/DetailAction';
import {reduceEventUpdate} from './actions/UpdateAction';
import {reduceEventPause} from './actions/PauseAction';
import {reduceEventResume} from './actions/ResumeAction';
import {reduceEventRemove} from './actions/RemoveAction';
import {reduceEventPay} from './actions/PayAction';
import {reduceEventPayComplete} from './actions/PayCompleteAction';
import {reduceEventPayExtra} from './actions/PayExtraAction';


const caseReducers: { [k in EventAction["type"]]: Reducer } = {
	[TYPE_LIST]: reduceEventListAction,
	[TYPE_CREATE]: reduceEventCreate,
	[TYPE_DETAIL]: reduceEventDetailAction,
	[TYPE_UPDATE]: reduceEventUpdate,
	[TYPE_PAUSE]: reduceEventPause,
	[TYPE_RESUME]: reduceEventResume,
	[TYPE_REMOVE]: reduceEventRemove,
	[TYPE_PAY]: reduceEventPay,
	[TYPE_PAY_COMPLETE]: reduceEventPayComplete,
	[TYPE_PAY_EXTRA]: reduceEventPayExtra
};

export const eventReducer = combineCaseReducers<EventState>(
	initialEventState,
	caseReducers
);