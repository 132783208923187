import {eventPauseRequest} from '../../../api/Event';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {EventPauseAction, TYPE_PAUSE} from './types';
import {EventCreateStatus, EventState} from '../eventState';
import {AlertType, sendAlert} from '../../../components/Alert';
import {ApiErrorResponse} from '../../../api/ApiResponse';


export const actionEventPause = (uid: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<EventPauseAction>({
		type: TYPE_PAUSE,
		updating: EventCreateStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	const error = (result: any) =>
	{
		dispatch<EventPauseAction>({
			type: TYPE_PAUSE,
			updating: EventCreateStatus.failed
		});

		const response: ApiErrorResponse = result.response.data;
		const {message} = response;
		sendAlert(AlertType.error, message);
	};

	await eventPauseRequest(accessToken, uid).then((result: any) =>
	{
		const {message, code} = result;

		dispatch<EventPauseAction>({
			type: TYPE_PAUSE,
			updating: EventCreateStatus.success
		});

		sendAlert(code === 423 ? AlertType.warning : AlertType.success, message);

	}).catch(error);
};

export const reduceEventPause = (state: EventState, action: EventPauseAction): EventState => ({
	...state,
	...action
});