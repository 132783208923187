import {Coupon} from "../../api/Coupon";


export enum CouponValidationStatus {
    prepared,
    loading,
    valid,
    invalid
}


export interface CouponState {
    // Data
    coupon?: Coupon;

    // States
    validation: CouponValidationStatus;
}


export const initialCouponState: CouponState = {
    validation: CouponValidationStatus.prepared
};