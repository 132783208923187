import React, {ReactNode} from 'react';
import {AuthLayout} from '../../components/AuthLayout';
import PasswordField from '../../components/form/PasswordField';
import {Button} from '@material-ui/core';
import {RouteComponentProps} from "react-router";
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {actionRecoveryValidate} from '../../model/recovery/actions/RecoveryValidateAction';
import {RecoveryStatus} from '../../model/recovery/recoveryState';
import {actionRecoveryProcess} from '../../model/recovery/actions/RecoveryProcessAction';
import {Link} from 'react-router-dom';
import {USER_LOGIN} from '../../routes';
import {withTranslation, WithTranslation} from 'react-i18next';


interface StateProps
{
	status: RecoveryStatus;
	email?: string;
}


interface DispatchProps
{
	validate: (recoveryToken: string) => void;
	process: (recoveryToken: string, password: string) => void;
}


type Props = StateProps & DispatchProps & RouteComponentProps<{ recoveryToken: string }> & WithTranslation;


interface State
{
	password: string;
}


class BareRecoveryScreen extends React.PureComponent<Props, State>
{

	public state = {
		password: ''
	};


	public componentDidMount(): void
	{
		const {recoveryToken} = this.props.match.params;
		this.props.validate(recoveryToken);
	}


	protected changePassword = () =>
	{
		if (!this.isFormValid()) {
			return;
		}

		const {recoveryToken} = this.props.match.params;
		this.props.process(recoveryToken, this.state.password);
	};

	protected getMessage = (): ReactNode =>
	{
		const {status} = this.props;
		if (status === RecoveryStatus.validating || status === RecoveryStatus.success || status === RecoveryStatus.expired) {
			return (<p/>);
		}

		return (
			<p dangerouslySetInnerHTML={{
				__html: this.props.t('auth.recovery.message', {
					email: `<strong>${this.props.email}</strong>`
				})
			}}/>
		);
	};

	protected getContent = (): ReactNode =>
	{
		const {status} = this.props;
		if (status === RecoveryStatus.success || status === RecoveryStatus.expired) {
			return (
				<Link to={USER_LOGIN}>
					<Button
						variant="outlined"
						fullWidth={true}
						size="large"
						color="primary">
						{this.props.t('auth.recovery.login')}
					</Button>
				</Link>
			);
		}

		return (
			<>
				<form onSubmit={this.changePassword}>
					<PasswordField
						error={this.props.status === RecoveryStatus.failed}
						disabled={this.props.status === RecoveryStatus.loading}
						label={this.props.t('auth.recovery.password')}
						value={this.state.password}
						onChange={(password) => this.setState({password})}
					/>
					<Button
						type='submit'
						variant="contained"
						fullWidth={true}
						size="large"
						color="secondary"
						disabled={this.props.status === RecoveryStatus.loading || !this.isFormValid()}
						onClick={this.changePassword}>
						{this.props.t('auth.recovery.restore')}
					</Button>
				</form>
			</>
		);
	};

	public render = () =>
	{
		if (this.props.status === RecoveryStatus.validating) {
			return (<div/>);
		}

		return (
			<AuthLayout
				title={this.props.t('auth.recovery.title')}
				message={this.getMessage()}>
				{this.getContent()}
			</AuthLayout>
		);
	};

	protected isFormValid = (): boolean => this.state.password.length > 0;
}


export const RecoveryScreen = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.recovery.status,
		email: state.recovery.email
	}),
	(dispatch: AppThunkDispatch) => ({
		validate: (recoveryToken: string) => dispatch(actionRecoveryValidate(recoveryToken)),
		process: (recoveryToken: string, password: string) => dispatch(actionRecoveryProcess(recoveryToken, password))
	})
)(BareRecoveryScreen));