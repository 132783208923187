import {subscriptionResumeRequest} from '../../../api/Subscription';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {ResumeAction, TYPE_RESUME} from './types';
import {SubscriptionResumeStatus, SubscriptionState} from '../subscriptionState';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionSubscriptionResume = (): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<ResumeAction>({
		type: TYPE_RESUME,
		sessionId: undefined,
		resume: SubscriptionResumeStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await subscriptionResumeRequest(accessToken).then((result: any) =>
	{
		const {payload, message} = result;
		sendAlert(AlertType.success, message);

		dispatch<ResumeAction>({
			type: TYPE_RESUME,
			resume: SubscriptionResumeStatus.started,
			sessionId: payload.sessionId,
			subscription: payload.info,
			invoices: payload.invoices
		});

	}).catch((result: any) =>
	{
		if (!result.response) {
			return;
		}

		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<ResumeAction>({
			type: TYPE_RESUME,
			sessionId: undefined,
			resume: SubscriptionResumeStatus.prepared
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceSubscriptionResume = (state: SubscriptionState, action: ResumeAction): SubscriptionState => ({
	...state,
	...action
});