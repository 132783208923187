import {User, UserDevice} from '../../api/User';
import {Billing} from '../../api/Billing';
import {Module} from '../../api/Module';


export enum LoginStatus
{
	prepared,
	loading,
	success,
	failed
}


export enum RegisterStatus
{
	prepared,
	loading,
	success,
	failed
}


export enum LoginRecoveryStatus
{
	prepared,
	loading,
	success,
	failed
}


export enum ValidateStatus
{
	loading,
	validated
}


export enum ConfirmationStatus
{
	loading,
	invalid,
	confirmed
}


export enum ConfirmationResendStatus
{
	default,
	loading
}


export enum UserSyncStatus
{
	synced,
	syncing
}


export enum UserSaveStatus
{
	saved,
	saving,
	failed
}


export enum UserOnboardingCompleteStatus
{
	loaded,
	loading
}


export interface UserState
{
	// Data
	user?: User;
	device?: UserDevice;
	billing?: Billing;
	modules: Module[];
	servers: string[];

	// Statuses
	login: LoginStatus;
	register: RegisterStatus;
	recovery: LoginRecoveryStatus;
	validate: ValidateStatus;
	confirmation: ConfirmationStatus;
	confirmationResend: ConfirmationResendStatus;
	sync: UserSyncStatus;
	save: UserSaveStatus;
	onboarding: UserOnboardingCompleteStatus;
}


export const initialUserState: UserState = {
	login: LoginStatus.prepared,
	register: RegisterStatus.prepared,
	recovery: LoginRecoveryStatus.prepared,
	validate: ValidateStatus.loading,
	confirmation: ConfirmationStatus.loading,
	confirmationResend: ConfirmationResendStatus.default,
	sync: UserSyncStatus.synced,
	save: UserSaveStatus.saved,
	onboarding: UserOnboardingCompleteStatus.loaded,
	modules: [],
	servers: []
};
