import {Action, Reducer} from "redux";


export function combineCaseReducers<S>(initialState: S, caseReducers: { [key: string]: Reducer<S, Action> })
{
	return function reducer(state = initialState, action: Action): S
	{
		if (caseReducers.hasOwnProperty(action.type)) {
			return caseReducers[action.type](state, action);
		} else {
			return state;
		}
	};
}
