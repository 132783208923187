import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Button} from '@material-ui/core';
import {UserSaveStatus} from '../../model/user/userState';
import PasswordField from '../form/PasswordField';
import {PasswordChecker} from '../form/PasswordChecker';
import {actionUserChangePasswordAction} from '../../model/user/actions/ChangePasswordAction';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	status: UserSaveStatus;
}


interface DispatchProps
{
	save: (current: string, password: string) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	current: string;
	password: string;
}


class BarePasswordForm extends React.Component<Props, State>
{

	public state = {
		current: '',
		password: ''
	};

	protected onCurrentChanged = (current: string) => this.setState({current});
	protected onPasswordChanged = (password: string) => this.setState({password});

	protected isFormValid = (): boolean => this.state.current.length > 0 && this.state.password.length > 0;

	protected save = (event: any) =>
	{
		event.preventDefault();
		if (!this.isFormValid()) {
			return;
		}

		this.props.save(this.state.current, this.state.password);
	};

	public render = () =>
	{
		const hasError = this.props.status === UserSaveStatus.failed;
		const isSaving = this.props.status === UserSaveStatus.saving;

		return (
			<form className={`Form Form--full`} onSubmit={this.save} autoComplete={'off'}>
				<div className='Form__column Form__column--full'>
					<div className='Form__group'>
						<div className='Form__item'>
							<PasswordField
								error={hasError}
								disabled={isSaving}
								label={this.props.t('user.password.current')}
								value={this.state.current}
								onChange={this.onCurrentChanged}
								autoComplete={'off'}
							/>
						</div>
						<div className='Form__item'>
							<PasswordField
								error={hasError}
								disabled={isSaving}
								label={this.props.t('user.password.new')}
								value={this.state.password}
								onChange={this.onPasswordChanged}
								autoComplete={'off'}
							/>
							<PasswordChecker password={this.state.password}/>
						</div>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<Button
						disabled={!this.isFormValid() || isSaving}
						onSubmit={this.save}
						type='submit'
						className='Button'
						variant="contained"
						size="large"
						color="secondary">
						{isSaving ?
							this.props.t('user.password.save.loading') :
							this.props.t('user.password.save.default')
						}
					</Button>
				</div>
			</form>
		);
	};
}


export const PasswordForm = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.user.save ?? UserSaveStatus.saved
	}),
	(dispatch: AppThunkDispatch) => ({
		save: (current: string, password: string) => dispatch(actionUserChangePasswordAction(current, password))
	})
)(BarePasswordForm));