import React from 'react';
import {Button, TextField} from '@material-ui/core';
import PasswordField from '../../components/form/PasswordField';
import CheckboxField from '../../components/form/CheckboxField';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {actionLoginByCredentials, actionLoginFromLocalStorage} from '../../model/user/actions/LoginAction';
import {connect} from 'react-redux';
import {USER_REGISTER} from '../../routes';
import {Link} from 'react-router-dom';
import {AuthLayout} from '../../components/AuthLayout';
import {RecoveryPasswordDialog} from '../../components/dialog/RecoveryPasswordDialog';
import {LoginRecoveryStatus, LoginStatus} from '../../model/user/userState';
import {TYPE_LOGIN, TYPE_LOGIN_RECOVER} from '../../model/user/actions/types';
import {actionLoginRecover} from '../../model/user/actions/LoginRecoverAction';
import {withTranslation, WithTranslation} from 'react-i18next';


interface Props
{
	login: LoginStatus;
	recovery: LoginRecoveryStatus;

	onLogin: (email: string, password: string) => void;
	onRecover: (email: string) => void;

	checkIfNotLoggedIn: () => void;
	resetFailedState: () => void;
}


interface State
{
	email: string;
	password: string;
	isRemembered: boolean;
	isRecovery: boolean;
}


class BareLoginScreen extends React.PureComponent<Props & WithTranslation, State>
{
	public state = {
		email: '',
		password: '',
		isRemembered: true,
		isRecovery: false
	};


	constructor(props: Props & WithTranslation)
	{
		super(props);
		this.props.checkIfNotLoggedIn();
	}


	public componentDidMount = () =>
	{
		this.props.resetFailedState();
	};

	protected login = () =>
	{
		this.props.onLogin(this.state.email, this.state.password);
	};

	protected isFormValid = (): boolean =>
	{
		return this.state.email.length > 0 && this.state.password.length > 0;
	};

	protected toggleRecovery = () =>
	{
		this.setState({isRecovery: !this.state.isRecovery});
	};

	protected recover = (email: string) =>
	{
		this.setState({isRecovery: false});
		this.props.onRecover(email);
	};

	public render = () =>
	{
		return (
			<>
				{this.state.isRecovery && (
					<RecoveryPasswordDialog
						disabled={this.props.recovery === LoginRecoveryStatus.loading}
						email={this.state.email}
						onClose={this.toggleRecovery}
						onConfirm={this.recover}
					/>
				)}

				<AuthLayout title={this.props.t('auth.login.title')} message={(
					<p>
						{this.props.t('auth.login.message')}
						<a href='/' onClick={(e) =>
						{
							e.preventDefault();
							this.toggleRecovery();
						}}>{this.props.t('auth.login.restore')}</a>
					</p>
				)}>
					<form onSubmit={this.login}>
						<TextField
							error={this.props.login === LoginStatus.failed}
							disabled={this.props.login === LoginStatus.loading}
							className='TextField'
							onChange={(e) => this.setState({email: e.target.value})}
							value={this.state.email}
							id="email"
							label={this.props.t('auth.login.email')}
							variant="outlined"
							fullWidth={true}
						/>
						<PasswordField
							error={this.props.login === LoginStatus.failed}
							disabled={this.props.login === LoginStatus.loading}
							label={this.props.t('auth.login.password')}
							value={this.state.password}
							onChange={(password) => this.setState({password})}
						/>
						<CheckboxField
							disabled={this.props.login === LoginStatus.loading}
							label={this.props.t('auth.login.remember')}
							isChecked={this.state.isRemembered}
							onChange={(isRemembered) => this.setState({isRemembered})}
						/>
						<div className='Sign__actions'>
							<div className='Sign__action'>
								<Button
									type={'submit'}
									variant="contained"
									fullWidth={true}
									size="large"
									color="secondary"
									disabled={this.props.login === LoginStatus.loading || !this.isFormValid()}
									onClick={this.login}>
									{this.props.t('auth.login.login')}
								</Button>
							</div>
							<div className='Sign__action'>
								<Link to={USER_REGISTER}>
									<Button
										variant="outlined"
										fullWidth={true}
										size="large"
										disabled={this.props.login === LoginStatus.loading}
										color="primary">
										{this.props.t('auth.login.register')}
									</Button>
								</Link>
							</div>
						</div>
					</form>
				</AuthLayout>
			</>
		);
	};
}


export const LoginScreen = withTranslation('app')(connect(
	(state: AppState) => ({
		login: state.user.login,
		recovery: state.user.recovery
	}),
	(dispatch: AppThunkDispatch) => ({
		checkIfNotLoggedIn: () =>
		{
			dispatch(actionLoginFromLocalStorage);
		},
		resetFailedState: () =>
		{
			dispatch({type: TYPE_LOGIN, login: LoginStatus.prepared});
			dispatch({type: TYPE_LOGIN_RECOVER, recovery: LoginRecoveryStatus.prepared});
		},
		onLogin: (email: string, password: string) =>
		{
			dispatch(actionLoginByCredentials(email, password));
		},
		onRecover: (email: string) =>
		{
			dispatch(actionLoginRecover(email));
		}
	})
)(BareLoginScreen));