import React from 'react';
import {Layout} from '../../components/Layout';
import {ButtonBase, IconButton} from '@material-ui/core';
import {BillingForm} from '../../components/billing/BillingForm';
import {PasswordForm} from '../../components/user/PasswordForm';
import {UserDeviceList} from '../../components/user/UserDeviceList';
import {EVENTS_LIST} from '../../routes';
import {ArrowBackRounded} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import {SubscriptionInfoView} from '../../components/billing/SubscriptionInfoView';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {Stripe} from "@stripe/stripe-js";
import {stripePromise} from "../../components/Stripe";


export enum SettingsTab
{
	billing = 'billing',
	subscription = 'subscription',
	password = 'password',
	instances = 'devices'
}


interface StateProps
{
	publishableKey: string;
}


interface DispatchProps
{

}


type Props = StateProps & DispatchProps & RouteComponentProps<{ tab?: string }> & WithTranslation;


interface State
{
	tab: SettingsTab;
}


class BareSettingsScreen extends React.PureComponent<Props, State>
{

	public state: State = {
		tab: SettingsTab.billing
	};

	public stripe: Promise<Stripe | null> = stripePromise(this.props.publishableKey);


	public componentDidMount(): void
	{
		const {tab} = this.props.match.params;
		if (!tab) {
			return;
		}

		let t = SettingsTab.billing;
		switch (tab) {
			case SettingsTab.subscription:
				t = SettingsTab.subscription;
				break;

			case SettingsTab.instances:
				t = SettingsTab.instances;
				break;

			case SettingsTab.password:
				t = SettingsTab.password;
				break;

		}

		this.setState({tab: t});
	}


	protected changeTab = (tab: SettingsTab) => this.setState({tab});

	public render = () => (
		<Layout title={this.props.t('settings.title')} link={EVENTS_LIST}>
			<div className='PageHeader'>
				<Link to={EVENTS_LIST}>
					<IconButton className='PageHeader__back'>
						<ArrowBackRounded fontSize="default"/>
					</IconButton>
				</Link>
				<h1>{this.props.t('settings.title')}</h1>
			</div>
			<div className='Animated'>
				<div className='Tabs'>
					<ButtonBase onClick={() => this.changeTab(SettingsTab.billing)}
								className={`Tabs__item ${this.state.tab === SettingsTab.billing && 'Tabs__item--active'}`}>
						{this.props.t('settings.tabs.billing')}
					</ButtonBase>
					<ButtonBase onClick={() => this.changeTab(SettingsTab.subscription)}
								className={`Tabs__item ${this.state.tab === SettingsTab.subscription && 'Tabs__item--active'}`}>
						{this.props.t('settings.tabs.plan')}
					</ButtonBase>
					<ButtonBase onClick={() => this.changeTab(SettingsTab.password)}
								className={`Tabs__item ${this.state.tab === SettingsTab.password && 'Tabs__item--active'}`}>
						{this.props.t('settings.tabs.password')}
					</ButtonBase>
					<ButtonBase onClick={() => this.changeTab(SettingsTab.instances)}
								className={`Tabs__item ${this.state.tab === SettingsTab.instances && 'Tabs__item--active'}`}>
						{this.props.t('settings.tabs.instances')}
					</ButtonBase>
				</div>
				{this.state.tab === SettingsTab.billing ? (
					<BillingForm
						simplified={false}
						saveLabel={this.props.t('settings.billing.save')}
						savingLabel={this.props.t('settings.billing.saving')}/>
				) : null}
				{this.state.tab === SettingsTab.password ? (<PasswordForm/>) : null}
				{this.state.tab === SettingsTab.instances ? (<UserDeviceList/>) : null}
				{this.state.tab === SettingsTab.subscription && (
					<Elements stripe={this.stripe}>
						<ElementsConsumer>
							{({elements, stripe}) => (
								<SubscriptionInfoView elements={elements} stripe={stripe} />
							)}
						</ElementsConsumer>
					</Elements>
				)}
			</div>
		</Layout>
	);
}


export const SettingsScreen = withTranslation('app')(connect(
	(state: AppState) => ({
		publishableKey: state.user.user?.publishableKey || ''
	}),
	(dispatch: AppThunkDispatch) => ({})
)(BareSettingsScreen));