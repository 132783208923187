import React from 'react';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {SubscriptionCompleteStatus} from '../../model/subscription/subscriptionState';
import {actionSubscriptionComplete} from '../../model/subscription/actions/SubscriptionCompleteAction';
import {Link} from 'react-router-dom';
import {EVENTS_LIST, SETTINGS} from '../../routes';
import {Button} from '@material-ui/core';
import {AuthLayout} from '../../components/AuthLayout';
import {EmailRounded} from '@material-ui/icons';
import {UserStatus} from '../../api/User';
import {SettingsTab} from '../settings/SettingsScreen';
import {withTranslation, WithTranslation} from 'react-i18next';


interface StateProps
{
	status: SubscriptionCompleteStatus;
	isOnboardingCompleted: boolean;
}


interface DispatchProps
{
	validate: (sessionId: string) => void;
}


type Props = StateProps & DispatchProps & RouteComponentProps<{ sessionId: string }> & WithTranslation;


interface State
{

}


class BareSubscriptionValidationScreen extends React.PureComponent<Props, State>
{

	public state: State = {};

	protected timeOut?: NodeJS.Timeout;


	public componentDidMount(): void
	{
		this.validate();
	}


	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void
	{
		if (this.timeOut) {
			clearInterval(this.timeOut);
		}

		if (prevProps.status !== SubscriptionCompleteStatus.failed && this.props.status === SubscriptionCompleteStatus.failed) {
			this.timeOut = setInterval(this.validate, 10000);
		}
	}


	public componentWillUnmount(): void
	{
		if (this.timeOut) {
			clearInterval(this.timeOut);
		}
	}


	protected validate = () =>
	{
		const {sessionId} = this.props.match.params;
		if (!sessionId) {
			return;
		}

		this.props.validate(sessionId);
	};

	public render = () =>
	{
		const {isOnboardingCompleted, status} = this.props;
		const isCompleting = status === SubscriptionCompleteStatus.completing || status === SubscriptionCompleteStatus.prepared;
		const isFailed = status === SubscriptionCompleteStatus.failed;
		const isCompleted = status === SubscriptionCompleteStatus.completed;

		let title = this.props.t('payment.finishing.title');
		if (isCompleted) {
			title = this.props.t('payment.completed.title');
		}

		if (isFailed) {
			title = this.props.t('payment.error.title');
		}

		return (
			<AuthLayout simplified={true} title={title} message={(
				<p>
					{isCompleting && this.props.t('payment.finishing.message')}
					{isCompleted && this.props.t('payment.completed.message')}
					{isFailed && (
						<span dangerouslySetInnerHTML={{
							__html: this.props.t('payment.error.message', {
								writeUs: (
									<a href='mailto:hello@simpleflow.io'>
										<strong>
											${this.props.t('payment.error.writeUs')}&nbsp;
											<EmailRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
										</strong>
									</a>
								)
							})
						}}/>
					)}
				</p>
			)}>
				{isCompleting && (<div className="Loader">Loading…</div>)}
				{isCompleted && (
					<Link to={isOnboardingCompleted ? `${SETTINGS}/${SettingsTab.subscription}` : EVENTS_LIST}>
						<Button
							variant="outlined"
							fullWidth={true}
							size="large"
							color="primary">
							{this.props.t('payment.completed.continue')}
						</Button>
					</Link>
				)}
				{isFailed && (
					<Button
						onClick={this.validate}
						variant="outlined"
						fullWidth={true}
						size="large"
						color="primary">
						{this.props.t('payment.error.again')}
					</Button>
				)}
			</AuthLayout>
		);
	};
}


export const SubscriptionValidationScreen = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.subscription.complete,
		isOnboardingCompleted: state.user.user?.status === UserStatus.active
	}),
	(dispatch: AppThunkDispatch) => ({
		validate: (sessionId: string) => dispatch(actionSubscriptionComplete(sessionId))
	})
)(BareSubscriptionValidationScreen));