import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {SyncAction, TYPE_SYNC} from './types';
import {UserState, UserSyncStatus} from '../userState';
import {userValidateRequest} from '../../../api/User';


export const actionSyncUser = (): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch({
		type: TYPE_SYNC,
		sync: UserSyncStatus.syncing
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await userValidateRequest(accessToken).then((result: any) =>
	{
		const {payload} = result;
		dispatch({
			type: TYPE_SYNC,
			sync: UserSyncStatus.synced,
			...payload
		});
	}).catch(() =>
	{
		dispatch({
			type: TYPE_SYNC,
			sync: UserSyncStatus.synced
		});
	});
};

export const reduceUserSync = (state: UserState, action: SyncAction): UserState => ({
	...state,
	...action
});
