import {initialUserState, UserState} from "./userState";
import {Reducer} from "redux";
import {reduceLogin} from "./actions/LoginAction";
import {combineCaseReducers} from '../reducerCombiners';
import {reduceRegister} from './actions/RegisterAction';
import {
	TYPE_BILLING_SAVE, TYPE_CHANGE_PASSWORD,
	TYPE_CONFIRM,
	TYPE_CONFIRM_RESEND,
	TYPE_LOGIN,
	TYPE_LOGIN_RECOVER,
	TYPE_LOGOUT, TYPE_ONBOARDING_COMPLETE,
	TYPE_REGISTER, TYPE_SAVE,
	TYPE_SYNC,
	UserAction
} from './actions/types';
import {reduceLoginRecover} from './actions/LoginRecoverAction';
import {reduceLogout} from './actions/LogoutAction';
import {reduceUserConfirm} from './actions/ConfirmAction';
import {reduceUserConfirmResend} from './actions/ConfirmResendAction';
import {reduceUserSync} from './actions/SyncAction';
import {reduceBillingSave} from './actions/BillingSaveAction';
import {reduceOnboardingComplete} from './actions/OnboardingAction';
import {reduceUserSave} from './actions/SaveAction';
import {reduceUserChangePassword} from './actions/ChangePasswordAction';

// the type annotation ensures that no action type is omitted here
const caseReducers: { [k in UserAction["type"]]: Reducer } = {
	[TYPE_LOGIN]: reduceLogin,
	[TYPE_LOGIN_RECOVER]: reduceLoginRecover,
	[TYPE_REGISTER]: reduceRegister,
	[TYPE_LOGOUT]: reduceLogout,
	[TYPE_CONFIRM]: reduceUserConfirm,
	[TYPE_CONFIRM_RESEND]: reduceUserConfirmResend,
	[TYPE_SYNC]: reduceUserSync,
	[TYPE_BILLING_SAVE]: reduceBillingSave,
	[TYPE_ONBOARDING_COMPLETE]: reduceOnboardingComplete,
	[TYPE_SAVE]: reduceUserSave,
	[TYPE_CHANGE_PASSWORD]: reduceUserChangePassword
};

export const userReducer = combineCaseReducers<UserState>(
	initialUserState,
	caseReducers
);
