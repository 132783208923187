import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import {Snackbar} from '@material-ui/core';
import StandardEvent from '../utils/StandardEvent';
import {WithTranslation, withTranslation} from 'react-i18next';


/**
 * @param {AlertType} type
 * @param {string} message
 */
export const sendAlert = (type: AlertType, message: string) =>
{
	if (!message) {
		return;
	}

	window.dispatchEvent(StandardEvent.get('app:alert', {
		detail: {
			type, message
		}
	}));
};


interface Props
{

}


export enum AlertType
{
	success = 'success',
	info = 'info',
	warning = 'warning',
	error = 'error'
}


interface State
{
	message?: string;
	type: AlertType;
	opened: boolean;
}


class BareAlert extends React.Component<Props & WithTranslation, State>
{
	public state = {
		message: undefined,
		type: AlertType.success,
		opened: false
	};


	public componentDidMount(): void
	{
		window.addEventListener('app:alert', this.open);
	}


	public componentWillUnmount(): void
	{
		window.removeEventListener('app:alert', this.close);
	}


	protected open = (event: any) =>
	{
		this.setState({opened: false}, () => this.setState({message: undefined}, () =>
		{
			const {type, message} = event.detail;
			this.setState({
				opened: true,
				type,
				message
			});
		}));
	};

	protected close = () =>
	{
		this.setState({opened: false}, () => this.setState({message: undefined}));
	};

	public render = () => (
		<Snackbar
			open={this.state.opened}
			autoHideDuration={5000}
			onClose={this.close}
			anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
			{!!this.state.message ? (
				<MuiAlert
					onClose={this.close}
					elevation={6}
					style={{borderRadius: 24}}
					variant="standard"
					severity={this.state.type}>
					{this.props.t(`api.${this.state.message}`)}
				</MuiAlert>
			) : null}
		</Snackbar>
	);
}


export const Alert = withTranslation('app')(BareAlert);