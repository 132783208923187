import {Reducer} from 'redux';
import {RecoveryAction, RecoveryLoadingAction, TYPE_LOADING_RECOVERY, TYPE_PROCESS_RECOVERY, TYPE_VALIDATE_RECOVERY} from './actions/types';
import {reduceRecoveryValidate} from './actions/RecoveryValidateAction';
import {initialRecoveryState, RecoveryState} from './recoveryState';
import {combineCaseReducers} from '../reducerCombiners';
import {reduceRecoveryProcess} from './actions/RecoveryProcessAction';


const reduceLoading = (state: RecoveryState, action: RecoveryLoadingAction): RecoveryState => ({...state, status: action.status || state.status});

const caseReducers: { [k in RecoveryAction["type"]]: Reducer } = {
	[TYPE_VALIDATE_RECOVERY]: reduceRecoveryValidate,
	[TYPE_LOADING_RECOVERY]: reduceLoading,
	[TYPE_PROCESS_RECOVERY]: reduceRecoveryProcess
};

export const recoveryReducer = combineCaseReducers<RecoveryState>(
	initialRecoveryState,
	caseReducers
);