import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {actionOnboardingCompleteAction} from '../../model/user/actions/OnboardingAction';
import {UserOnboardingCompleteStatus} from '../../model/user/userState';
import {EventForm} from '../event/EventForm';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	onboarding: UserOnboardingCompleteStatus;
}


interface DispatchProps
{
	skip: () => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{

}


class BareOnboardingEvent extends React.Component<Props, State>
{
	public componentDidMount(): void
	{
		window.scrollTo({top: 0});
	}


	public render = () => (
		<div className='Onboarding__content Onboarding__content--center'>
			<h1>{this.props.t('onboarding.event.title')}</h1>
			<p>
				{this.props.t('onboarding.event.messageOne')}&nbsp;
				<a href={'#skip'} onClick={this.skip}><strong>
					{this.props.t('onboarding.event.messageTwo')}
				</strong></a>&nbsp;
				{this.props.t('onboarding.event.messageThree')}
			</p>
			<EventForm simplified={true}/>
		</div>
	);

	protected skip = (e: any) =>
	{
		e.preventDefault();
		if (this.props.onboarding === UserOnboardingCompleteStatus.loading) {
			return;
		}

		this.props.skip();
	};
}


export const OnboardingEvent = withTranslation('app')(connect(
	(state: AppState) => ({
		onboarding: state.user.onboarding
	}),
	(dispatch: AppThunkDispatch) => ({
		skip: () => dispatch(actionOnboardingCompleteAction())
	})
)
(BareOnboardingEvent));