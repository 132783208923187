import {Billing, billingSaveRequest} from '../../../api/Billing';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {UserSaveStatus, UserState} from '../userState';
import {BillingSaveAction, TYPE_BILLING_SAVE} from './types';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionBillingSaveAction = (billing: Billing): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch({
		type: TYPE_BILLING_SAVE,
		save: UserSaveStatus.saving
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await billingSaveRequest(accessToken, billing).then((result: any) =>
	{
		const {payload, message} = result;
		sendAlert(AlertType.success, message);

		dispatch({
			type: TYPE_BILLING_SAVE,
			save: UserSaveStatus.saved,
			...payload
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch({
			type: TYPE_BILLING_SAVE,
			save: UserSaveStatus.failed,
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceBillingSave = (state: UserState, action: BillingSaveAction): UserState => ({
	...state,
	...action
});