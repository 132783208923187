import axios from "axios";
import {ApiResponse, getHeaders} from './ApiResponse';
import {UserDevice} from './User';


export type DevicesApiResponse = ApiResponse<UserDevice[]>;

export type DeviceApiResponse = ApiResponse<{}>;

export async function deviceListRequest(accessToken: string): Promise<DevicesApiResponse>
{
	const response = await axios.get<DevicesApiResponse>('/api/device', {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function deviceRemoveRequest(accessToken: string, uid: string): Promise<DeviceApiResponse>
{
	const response = await axios.delete<DeviceApiResponse>(`/api/device/${uid}`, {
		headers: getHeaders(accessToken)
	});

	return response.data;
};