import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {ListAction, TYPE_LIST} from './types';
import {DeviceLoadingStatus, DeviceState} from '../deviceState';
import {deviceListRequest} from '../../../api/Device';


export const actionDeviceList = (): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch({
		type: TYPE_LIST,
		loading: DeviceLoadingStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await deviceListRequest(accessToken).then((result: any) =>
	{
		const {payload} = result;
		dispatch({
			type: TYPE_LIST,
			loading: DeviceLoadingStatus.loaded,
			devices: payload
		});
	}).catch(() =>
	{
		dispatch({
			type: TYPE_LIST,
			loading: DeviceLoadingStatus.loaded,
			devices: []
		});
	});
};

export const reduceDeviceList = (state: DeviceState, action: ListAction): DeviceState => ({
	...state,
	...action
});