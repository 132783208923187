import {ApiResponse, getHeaders} from "./ApiResponse";
import axios from "axios";

export interface Coupon {
    coupon: string;
    name: string | null;
    isValid: boolean;
}

export type CouponValidationApiResponse = ApiResponse<Coupon>;


export async function couponValidateRequest(accessToken: string, coupon: string): Promise<CouponValidationApiResponse> {
    const response = await axios.post<CouponValidationApiResponse>('/api/coupon/validate', {coupon}, {
        headers: getHeaders(accessToken)
    });

    return response.data;
}