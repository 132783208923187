import {eventUpdateRequest, eventUploadIconRequest, UpdatingEvent} from '../../../api/Event';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {EventUpdateAction, TYPE_UPDATE} from './types';
import {EventCreateStatus, EventState} from '../eventState';
import {AlertType, sendAlert} from '../../../components/Alert';
import {ApiErrorResponse} from '../../../api/ApiResponse';


export const actionEventUpdate = (uid: string, event: UpdatingEvent, icon?: File): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<EventUpdateAction>({
		type: TYPE_UPDATE,
		updating: EventCreateStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	const error = (result: any) =>
	{
		dispatch<EventUpdateAction>({
			type: TYPE_UPDATE,
			updating: EventCreateStatus.failed
		});

		const response: ApiErrorResponse = result.response.data;
		const {message} = response;
		sendAlert(AlertType.error, message);
	};

	await eventUpdateRequest(accessToken, uid, event).then(async (result: any) =>
	{
		const {message, code} = result;

		if (!icon || code === 423) {
			dispatch<EventUpdateAction>({
				type: TYPE_UPDATE,
				updating: EventCreateStatus.success
			});

			sendAlert(code === 423 ? AlertType.warning : AlertType.success, message);
			return;
		}

		dispatch<EventUpdateAction>({
			type: TYPE_UPDATE,
			updating: EventCreateStatus.uploading
		});

		await eventUploadIconRequest(accessToken, uid, icon).then(() =>
		{
			dispatch<EventUpdateAction>({
				type: TYPE_UPDATE,
				updating: EventCreateStatus.success
			});

			sendAlert(code === 423 ? AlertType.warning : AlertType.success, message);

		}).catch(error);

	}).catch(error);
};

export const reduceEventUpdate = (state: EventState, action: EventUpdateAction): EventState => ({
	...state,
	...action
});