import React from 'react';
import {Link} from 'react-router-dom';
import {USER_LOGIN} from '../routes';
import {WithTranslation, withTranslation} from 'react-i18next';
import {LanguageSelect} from './utils/LanguageSelect';


interface Props
{
	title: string;
	message: React.ReactNode;
	simplified?: boolean;
}


interface State
{

}


class BareAuthLayout extends React.Component<Props & WithTranslation, State>
{

	public render = () => (
		<div className='Sign'>
			<div className='Sign__box'>
				<div className='Sign__boxContent'>
					{!this.props.simplified && (<Link to={USER_LOGIN} className='Brand'>Simpleflow</Link>)}
					<h1>{this.props.title}</h1>
					{this.props.message}
					{this.props.children}
					{!this.props.simplified && (
						<div className='Sign__footer'>
							&copy; {new Date().getFullYear()} <a href='https://simpleflow.io' target='_blank' rel='noopener noreferrer'>Simpleflow,
							s.r.o.</a>, {this.props.t('layout.footer.copyright')}
						</div>
					)}
				</div>
			</div>
			<LanguageSelect/>
		</div>
	);
}


export const AuthLayout = withTranslation('app')(BareAuthLayout);