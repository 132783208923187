import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {UserOnboardingCompleteStatus, UserState} from '../userState';
import {BillingSaveAction, TYPE_ONBOARDING_COMPLETE} from './types';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';
import {userCompleteRequest} from '../../../api/User';


export const actionOnboardingCompleteAction = (): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch({
		type: TYPE_ONBOARDING_COMPLETE,
		onboarding: UserOnboardingCompleteStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await userCompleteRequest(accessToken).then((result: any) =>
	{
		const {payload} = result;

		dispatch({
			type: TYPE_ONBOARDING_COMPLETE,
			onboarding: UserOnboardingCompleteStatus.loaded,
			...payload
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch({
			type: TYPE_ONBOARDING_COMPLETE,
			onboarding: UserOnboardingCompleteStatus.loaded
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceOnboardingComplete = (state: UserState, action: BillingSaveAction): UserState => ({
	...state,
	...action
});