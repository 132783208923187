import React from 'react';
import {IconButton,} from '@material-ui/core';
import {Layout} from '../../components/Layout';
import {EventForm} from '../../components/event/EventForm';
import {ArrowBackRounded} from '@material-ui/icons';
import {EVENTS_LIST} from '../../routes';
import {Link} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';


interface Props
{

}


interface State
{

}


class BareCreateEventScreen extends React.PureComponent<Props & WithTranslation, State>
{

	public state: State = {};

	public render = () => (
		<Layout title={this.props.t('events.create.title')} link={EVENTS_LIST}>
			<div className='PageHeader'>
				<Link to={EVENTS_LIST}>
					<IconButton className='PageHeader__back'>
						<ArrowBackRounded fontSize="default"/>
					</IconButton>
				</Link>
				<h1>{this.props.t('events.create.title')}</h1>
			</div>
			<div className='Animated'>
				<EventForm simplified={false}/>
			</div>
		</Layout>
	);
}


export const CreateEventScreen = withTranslation('app')(BareCreateEventScreen);