export enum RecoveryStatus
{
	waiting,
	validating,
	expired,
	prepared,
	loading,
	failed,
	success
}


export interface RecoveryState
{
	status: RecoveryStatus;
	email?: string;
}


export const initialRecoveryState: RecoveryState = {status: RecoveryStatus.waiting};