import React from 'react';
import * as Routes from './routes';
import './scss/common.scss';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {AppState} from './model/State';
import {AppThunkDispatch} from './model/Actions';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {AllEventsScreen} from './screens/events/AllEventsScreen';
import {LoginScreen} from './screens/auth/LoginScreen';
import {RegisterScreen} from './screens/auth/RegisterScreen';
import {RecoveryScreen} from './screens/auth/RecoveryScreen';
import {Alert} from './components/Alert';
import {actionLoginFromLocalStorage} from './model/user/actions/LoginAction';
import {ValidateStatus} from './model/user/userState';
import {ConfirmationScreen} from './screens/auth/ConfirmationScreen';
import {SettingsScreen} from './screens/settings/SettingsScreen';
import {CreateEventScreen} from './screens/events/CreateEventScreen';
import {EditEventScreen} from './screens/events/EditEventScreen';
import {SubscriptionValidationScreen} from './screens/subscription/SubscriptionValidationScreen';
import {SubscriptionCancelScreen} from './screens/subscription/SubscriptionCancelScreen';
import {PaymentValidationScreen} from './screens/payments/PaymentValidationScreen';


const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#757ce8',
			main: '#4e79b7',
			dark: '#486bb7',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ff7961',
			main: '#3faa1d',
			dark: '#3D9E1E',
			contrastText: '#fff',
		},
	},
});


interface StateProps
{
	isValidating: boolean;
	hasAccessToken: boolean;
}


interface DispatchProps
{
	checkLoggedIn: () => void;
}


type Props = StateProps & DispatchProps;


class BareApp extends React.PureComponent<Props>
{

	public async componentDidMount()
	{
		this.props.checkLoggedIn();
	}


	public render = () => (
		<ThemeProvider theme={theme}>
			<Alert/>
			{!this.props.isValidating && (
				<BrowserRouter basename="/">
					<Switch>
						<Route exact path={`${Routes.USER_CONFIRM}/:uid`} component={ConfirmationScreen}/>
						<Route path={`${Routes.USER_RECOVERY}/:recoveryToken`} component={RecoveryScreen}/>
						{this.props.hasAccessToken ? (
							<>
								<Route exact path="/"><Redirect to={Routes.EVENTS_LIST}/></Route>
								<Route exact path={Routes.USER_LOGIN}><Redirect to={Routes.EVENTS_LIST}/></Route>
								<Route exact path={Routes.USER_REGISTER}><Redirect to={Routes.EVENTS_LIST}/></Route>
								<Route exact path={Routes.EVENTS_CREATE} component={CreateEventScreen}/>
								<Route exact path={`${Routes.EVENTS_DETAIL}/:uid`} component={EditEventScreen}/>
								<Route exact path={Routes.EVENTS_LIST} component={AllEventsScreen}/>
								<Route exact path={Routes.SETTINGS} component={SettingsScreen}/>
								<Route exact path={`${Routes.SETTINGS}/:tab`} component={SettingsScreen}/>
								<Route exact path={Routes.SUBSCRIPTION_CANCEL} component={SubscriptionCancelScreen}/>
								<Route exact path={`${Routes.SUBSCRIPTION_COMPLETE}/:sessionId`} component={SubscriptionValidationScreen}/>
								<Route exact path={Routes.PAYMENT_CANCEL}><Redirect to={Routes.EVENTS_LIST}/></Route>
								<Route exact path={`${Routes.PAYMENT_COMPLETE}/:sessionId`} component={PaymentValidationScreen}/>
							</>
						) : (
							<>
								<Route exact path="/"><Redirect to={Routes.USER_LOGIN}/></Route>
								<Route exact path={Routes.USER_LOGIN} component={LoginScreen}/>
								<Route exact path={Routes.USER_REGISTER} component={RegisterScreen}/>
								<Route exact path={'*'}>
									<Redirect to={Routes.USER_LOGIN}/>
								</Route>
							</>
						)}
					</Switch>
				</BrowserRouter>
			)}
		</ThemeProvider>
	);
}


export const App = connect(
	(state: AppState) => ({
		isValidating: state.user.validate === ValidateStatus.loading,
		hasAccessToken: state.user.device?.accessToken !== undefined
	}),
	(dispatch: AppThunkDispatch) => ({
		checkLoggedIn: () =>
		{
			dispatch(actionLoginFromLocalStorage);
		}
	})
)(BareApp);
