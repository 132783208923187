import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {CookiesProvider} from 'react-cookie';
import {Provider} from 'react-redux';
import {configureStore} from './model/Store';
import {App} from './App';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';


ReactDOM.render(
	<CookiesProvider>
		<Provider store={configureStore()}>
			<I18nextProvider i18n={i18n}>
				<App/>
			</I18nextProvider>
		</Provider>
	</CookiesProvider>,
	document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
