import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {SubscriptionForm} from '../billing/SubscriptionForm';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{

}


interface DispatchProps
{

}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{

}


class BareOnboardingSubscription extends React.Component<Props, State>
{
	public componentDidMount(): void
	{
		window.scrollTo({top: 0});
	}


	public render = () => (
		<div className='Onboarding__content Onboarding__content--center'>
			<h1>{this.props.t('onboarding.subscription.title')}</h1>
			<p>{this.props.t('onboarding.subscription.message')}</p>
			<SubscriptionForm/>
		</div>
	);
}


export const OnboardingSubscription = withTranslation('app')(connect(
	(state: AppState) => ({}),
	(dispatch: AppThunkDispatch) => ({})
)
(BareOnboardingSubscription));