import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {UserSaveStatus, UserState} from '../userState';
import {ChangePasswordAction, TYPE_CHANGE_PASSWORD} from './types';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';
import {userChangePasswordRequest} from '../../../api/User';


export const actionUserChangePasswordAction = (current: string, password: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch({
		type: TYPE_CHANGE_PASSWORD,
		save: UserSaveStatus.saving
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await userChangePasswordRequest(accessToken, current, password).then((result: any) =>
	{
		const {message} = result;
		sendAlert(AlertType.success, message);

		dispatch({
			type: TYPE_CHANGE_PASSWORD,
			save: UserSaveStatus.saved
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch({
			type: TYPE_CHANGE_PASSWORD,
			save: UserSaveStatus.failed,
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceUserChangePassword = (state: UserState, action: ChangePasswordAction): UserState => ({
	...state,
	...action
});