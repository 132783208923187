import {RouteComponentProps} from 'react-router';
import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {ConfirmationStatus} from '../../model/user/userState';
import {AuthLayout} from '../../components/AuthLayout';
import {EVENTS_LIST} from '../../routes';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {actionUserConfirm} from '../../model/user/actions/ConfirmAction';
import {withTranslation, WithTranslation} from 'react-i18next';


interface StateProps
{
	status: ConfirmationStatus;
}


interface DispatchProps
{
	confirm: (uid: string) => void;
}


type Props = StateProps & DispatchProps & RouteComponentProps<{ uid: string }> & WithTranslation;


interface State
{

}


class BareConfirmationScreen extends React.PureComponent<Props, State>
{

	public componentDidMount(): void
	{
		const {uid} = this.props.match.params;
		this.props.confirm(uid);
	}


	public render = () =>
	{
		if (this.props.status === ConfirmationStatus.loading) {
			return (<div/>);
		}

		const isConfirmed = this.props.status === ConfirmationStatus.confirmed;
		return (
			<AuthLayout
				title={isConfirmed ? this.props.t('auth.confirm.success.title') : this.props.t('auth.confirm.error.title')}
				message={(
					<p>
						{isConfirmed ?
							this.props.t('auth.confirm.success.message') :
							this.props.t('auth.confirm.error.message')
						}
					</p>
				)}>
				{isConfirmed && (
					<Link to={EVENTS_LIST}>
						<Button
							variant="outlined"
							fullWidth={true}
							size="large"
							color="primary">
							{this.props.t('auth.confirm.success.continue')}
						</Button>
					</Link>
				)}
			</AuthLayout>
		);
	};
}


export const ConfirmationScreen = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.user.confirmation
	}),
	(dispatch: AppThunkDispatch) => ({
		confirm: (uid: string) =>
		{
			dispatch(actionUserConfirm(uid));
		}
	})
)(BareConfirmationScreen));