import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Button, ButtonGroup} from '@material-ui/core';
import {CheckCircleOutlineRounded, OpenInNewRounded} from '@material-ui/icons';
import {Elements} from '@stripe/react-stripe-js';
import {SubscriptionPlan} from '../../api/Subscription';
import {SubscriptionStartStatus} from '../../model/subscription/subscriptionState';
import {actionSubscriptionStart} from '../../model/subscription/actions/SubscriptionStartAction';
import {AlertType, sendAlert} from '../Alert';
import {WithTranslation, withTranslation} from 'react-i18next';
import {CouponField} from "../form/CouponField";
import {CouponValidationStatus} from "../../model/coupon/couponState";
import {stripePromise} from "../Stripe";
import {Stripe} from "@stripe/stripe-js";

interface StateProps {
    status: SubscriptionStartStatus;
    sessionId?: string;
    isCouponValidating: boolean;
    publishableKey: string;
}


interface DispatchProps {
    start: (plan: SubscriptionPlan, coupon?: string) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State {
    plan: SubscriptionPlan;
    coupon?: string;
}


class BareSubscriptionForm extends React.Component<Props, State> {

    public state: State = {
        plan: SubscriptionPlan.MONTHLY,
        coupon: undefined
    };

    public stripe: Promise<Stripe | null> = stripePromise(this.props.publishableKey);

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (!prevProps.sessionId && this.props.sessionId) {
            this.checkout();
        }
    }

    protected onCouponChanged = (coupon?: string) => this.setState({coupon});


    public render = () => {
        const {isCouponValidating} = this.props;
        const isMonthly = this.state.plan === SubscriptionPlan.MONTHLY;
        const isLoading = this.props.status === SubscriptionStartStatus.loading;
        const isStarted = this.props.status === SubscriptionStartStatus.started;

        return (
            <Elements stripe={this.stripe}>
                <div className='Subscription'>
                    <ButtonGroup className='Subscription__select' color="primary" disabled={isLoading || isStarted}>
                        <Button
                            className={`Subscription__type Subscription__type--${isMonthly ? 'active' : ''}`}
                            disableElevation={isMonthly}
                            onClick={() => this.changePlan(SubscriptionPlan.MONTHLY)}>
                            <div className='Subscription__typeTitle'
                                 dangerouslySetInnerHTML={{__html: this.props.t('subscription.monthly.title')}}/>
                            <div className='Subscription__typePrice'
                                 dangerouslySetInnerHTML={{__html: this.props.t('subscription.monthly.price')}}/>
                            {isMonthly && (
                                <CheckCircleOutlineRounded className='Subscription__typeIcon' fontSize={'large'}/>)}
                        </Button>
                        <Button
                            className={`Subscription__type Subscription__type--${!isMonthly ? 'active' : ''}`}
                            disableElevation={!isMonthly}
                            onClick={() => this.changePlan(SubscriptionPlan.YEARLY)}>
                            <div className='Subscription__typeTitle'
                                 dangerouslySetInnerHTML={{__html: this.props.t('subscription.yearly.title')}}/>
                            <div className='Subscription__typePrice'
                                 dangerouslySetInnerHTML={{__html: this.props.t('subscription.yearly.price')}}/>
                            {!isMonthly && (
                                <CheckCircleOutlineRounded className='Subscription__typeIcon' fontSize={'large'}/>)}
                        </Button>
                    </ButtonGroup>
                    <CouponField
                        label={this.props.t('subscription.coupon.label')}
                        onChange={this.onCouponChanged}
                    />
                    <Button
                        onClick={this.start}
                        disabled={isLoading || isStarted || isCouponValidating}
                        type='submit'
                        className='Button'
                        variant="contained"
                        size="large"
                        color="secondary">
                        {!isStarted && !isLoading && this.props.t('subscription.checkout')}
                        {isLoading && this.props.t('subscription.creating')}
                        {isStarted && this.props.t('subscription.redirecting')}
                    </Button>
                    <p>
                        {this.props.t('subscription.info')}&nbsp;
                        <a href={'https://www.simpleflow.io/pricing/'} target={'_blank'} rel='noopener noreferrer'>
                            <strong>
                                {this.props.t('subscription.seePricing')}&nbsp;
                                <OpenInNewRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
                            </strong>
                        </a>
                    </p>
                </div>
            </Elements>
        );
    };

    protected changePlan = (plan: SubscriptionPlan) => this.setState({plan});

    protected start = () => {
        const {plan, coupon} = this.state;
        this.props.start(plan, coupon);
    };

    protected checkout = async () => {
        const {sessionId} = this.props;
        if (!sessionId) {
            return;
        }

        const stripe = await this.stripe;
        if (!stripe) {
            return;
        }

        const {error} = await stripe.redirectToCheckout({sessionId});
        if (error && error.message) {
            sendAlert(AlertType.error, error.message);
        }
    };
}


export const SubscriptionForm = withTranslation('app')(connect(
    (state: AppState) => ({
        status: state.subscription.start,
        sessionId: state.subscription.sessionId,
        isCouponValidating: state.coupon.validation === CouponValidationStatus.loading,
        publishableKey: state.user.user?.publishableKey || ''
    }),
    (dispatch: AppThunkDispatch) => ({
        start: (plan: SubscriptionPlan, coupon?: string) => dispatch(actionSubscriptionStart(plan, coupon))
    })
)(BareSubscriptionForm));