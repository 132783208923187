import React from 'react';
import {Event, eventIconRequest, UpdatingEvent} from '../../api/Event';
import {EventCreateStatus} from '../../model/event/eventState';
import {Button, TextField} from '@material-ui/core';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {actionEventUpdate} from '../../model/event/actions/UpdateAction';
import {actionEventDetail} from '../../model/event/actions/DetailAction';
import {UploadField} from '../form/UploadField';
import {CancelablePromise} from '../../model/cancelablePromise';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	accessToken: string;
	status: EventCreateStatus;
	event: Event;
}


interface DispatchProps
{
	update: (uid: string, event: UpdatingEvent, file?: File) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	name?: string;
	file?: File;
	icon?: string;
}


class BareEventGeneralForm extends React.Component<Props, State>
{
	public state: State = {
		name: undefined,
		file: undefined,
		icon: undefined
	};

	protected promise?: CancelablePromise<ArrayBuffer>;


	public componentDidMount(): void
	{
		const {name} = this.props.event;
		this.setState({
			name
		});

		this.loadIcon();
	}


	public componentWillUnmount(): void
	{
		if (!this.promise) {
			return;
		}

		this.promise.cancel();
	}


	protected onTitleChanged = (name: string) => this.setState({name});

	protected loadIcon = () =>
	{
		const {accessToken, event: {uid}} = this.props;
		this.promise = eventIconRequest(accessToken, uid);
		this.promise.request.then((response: any) =>
		{
			if (!response) {
				return;
			}

			const icon = Buffer.from(response.data, 'binary').toString('base64');
			this.setState({icon});
		}).catch(() =>
		{
		});
	};

	protected isFormValid = (): boolean =>
	{
		return !!this.state.name && this.state.name.length > 0;
	};

	protected save = (e: any) =>
	{
		e.preventDefault();
		if (!this.isFormValid()) {
			return;
		}

		let {name, file} = this.state;
		this.props.update(this.props.event.uid, {name}, file);
	};

	public render = () =>
	{
		const hasError = this.props.status === EventCreateStatus.failed;
		const isSaving = this.props.status === EventCreateStatus.loading;
		const isUploading = this.props.status === EventCreateStatus.uploading;

		return (
			<form className='Form Form--full' onSubmit={this.save}>
				<div className='Form__column Form__column--full'>
					<p dangerouslySetInnerHTML={{__html: this.props.t('events.edit.general.message')}}/>
					<div className='Form__group'>
						<div className='Form__item Form__item--state'>
							<TextField
								disabled={isSaving || isUploading}
								error={hasError}
								required={true}
								value={this.state.name || ''}
								onChange={(e) => this.onTitleChanged(e.target.value)}
								className='TextField'
								label={this.props.t('events.edit.general.name')}
								variant="outlined"
								fullWidth={true}
							/>
						</div>
						<div className='Form__item' style={{paddingBottom: 16}}>
							<UploadField
								defaultImage={this.state.icon}
								disabled={isSaving || isUploading}
								tabIndex={1}
								label={this.props.t('events.edit.general.icon')}
								onChange={(file) => this.setState({file})}
							/>
						</div>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<Button
						disabled={!this.isFormValid() || isSaving || isUploading}
						onSubmit={this.save}
						type='submit'
						className='Button'
						variant="contained"
						size="large"
						color="secondary">
						{!isSaving && !isUploading && this.props.t('events.edit.general.update.default')}
						{this.props.status === EventCreateStatus.loading && this.props.t('events.edit.general.update.loading')}
						{this.props.status === EventCreateStatus.uploading && this.props.t('events.edit.general.update.uploading')}
					</Button>
				</div>
			</form>
		);
	};
}


export const EventGeneralForm = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.events.updating,
		accessToken: state.user.device?.accessToken || ''
	}),
	(dispatch: AppThunkDispatch) => ({
		update: (uid: string, event: UpdatingEvent, file?: File) => dispatch(actionEventUpdate(uid, event, file))
			.then(() => dispatch(actionEventDetail(uid)))
	})
)(BareEventGeneralForm));