import {subscriptionInfoRequest} from '../../../api/Subscription';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {InfoAction, TYPE_INFO} from './types';
import {SubscriptionInfoStatus, SubscriptionState} from '../subscriptionState';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionSubscriptionInfo = (): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<InfoAction>({
		type: TYPE_INFO,
		info: SubscriptionInfoStatus.loading,
		subscription: undefined,
		invoices: []
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await subscriptionInfoRequest(accessToken).then((result: any) =>
	{
		const {payload} = result;
		dispatch<InfoAction>({
			type: TYPE_INFO,
			info: SubscriptionInfoStatus.loaded,
			subscription: payload.info,
			invoices: payload.invoices
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<InfoAction>({
			type: TYPE_INFO,
			info: SubscriptionInfoStatus.loaded,
			subscription: undefined,
			invoices: []
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceSubscriptionInfo = (state: SubscriptionState, action: InfoAction): SubscriptionState => ({
	...state,
	...action
});