import i18next from 'i18next';
import lang_en from './translations/en-US.json';
import lang_cz from './translations/cs-CZ.json';


i18next
	.init({
		interpolation: {
			escapeValue: false
		},
		lng: 'en-US',
		fallbackLng: 'en-US',
		resources: {
			"en-US": {
				app: lang_en
			},
			"cs-CZ": {
				app: lang_cz
			}
		},
	});

export default i18next;