import React from 'react';


export const languageFlag = (code: string) =>
{
	switch (code) {
		case 'cz':
			return FlagCZ();
		case 'de':
			return FlagDE();
		case 'fr':
			return FlagFR();
		case 'sk':
			return FlagSK();
		case 'hu':
			return FlagHU();
		default:
			return FlagGB();
	}
};

export const FlagCZ = () => (
	<svg className='Flag' xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-cz" viewBox="0 0 640 480">
		<defs>
			<clipPath id="a">
				<path fillOpacity=".7" d="M-74 0h682.7v512H-74z"/>
			</clipPath>
		</defs>
		<g fillRule="evenodd" strokeWidth="1pt" clipPath="url(#a)" transform="translate(69.4) scale(.94)">
			<path fill="#e80000" d="M-74 0h768v512H-74z"/>
			<path fill="#fff" d="M-74 0h768v256H-74z"/>
			<path fill="#00006f" d="M-74 0l382.7 255.7L-74 511V0z"/>
		</g>
	</svg>
);

export const FlagDE = () => (
	<svg className='Flag' xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-de" viewBox="0 0 640 480">
		<path fill="#ffce00" d="M0 320h640v160H0z"/>
		<path d="M0 0h640v160H0z"/>
		<path fill="#d00" d="M0 160h640v160H0z"/>
	</svg>
);

export const FlagFR = () => (
	<svg className='Flag' xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-fr" viewBox="0 0 640 480">
		<g fillRule="evenodd" strokeWidth="1pt">
			<path fill="#fff" d="M0 0h640v480H0z"/>
			<path fill="#00267f" d="M0 0h213.3v480H0z"/>
			<path fill="#f31830" d="M426.7 0H640v480H426.7z"/>
		</g>
	</svg>
);

export const FlagGB = () => (
	<svg className='Flag' xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-gb" viewBox="0 0 640 480">
		<defs>
			<clipPath id="a">
				<path fillOpacity=".7" d="M-85.3 0h682.6v512H-85.3z"/>
			</clipPath>
		</defs>
		<g clipPath="url(#a)" transform="translate(80) scale(.94)">
			<g strokeWidth="1pt">
				<path fill="#012169" d="M-256 0H768v512H-256z"/>
				<path fill="#fff" d="M-256 0v57.2L653.5 512H768v-57.2L-141.5 0H-256zM768 0v57.2L-141.5 512H-256v-57.2L653.5 0H768z"/>
				<path fill="#fff" d="M170.7 0v512h170.6V0H170.7zM-256 170.7v170.6H768V170.7H-256z"/>
				<path fill="#c8102e"
					  d="M-256 204.8v102.4H768V204.8H-256zM204.8 0v512h102.4V0H204.8zM-256 512L85.3 341.3h76.4L-179.7 512H-256zm0-512L85.3 170.7H9L-256 38.2V0zm606.4 170.7L691.7 0H768L426.7 170.7h-76.3zM768 512L426.7 341.3H503l265 132.5V512z"/>
			</g>
		</g>
	</svg>
);

export const FlagHU = () => (
	<svg className='Flag' xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-hu" viewBox="0 0 640 480">
		<g fillRule="evenodd">
			<path fill="#fff" d="M640 480H0V0h640z"/>
			<path fill="#388d00" d="M640 480H0V320h640z"/>
			<path fill="#d43516" d="M640 160.1H0V.1h640z"/>
		</g>
	</svg>
);

export const FlagSK = () => (
	<svg className='Flag' xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-sk" viewBox="0 0 640 480">
		<path fill="#ee1c25" d="M0 0h640v480H0z"/>
		<path fill="#0b4ea2" d="M0 0h640v320H0z"/>
		<path fill="#fff" d="M0 0h640v160H0z"/>
		<path fill="#fff" d="M233 370.8c-43-20.7-104.6-61.9-104.6-143.2 0-81.4 4-118.4 4-118.4h201.3s3.9 37 3.9 118.4S276 350 233 370.8z"/>
		<path fill="#ee1c25" d="M233 360c-39.5-19-96-56.8-96-131.4s3.6-108.6 3.6-108.6h184.8s3.5 34 3.5 108.6C329 303.3 272.5 341 233 360z"/>
		<path fill="#fff"
			  d="M241.4 209c10.7.2 31.6.6 50.1-5.6 0 0-.4 6.7-.4 14.4s.5 14.4.5 14.4c-17-5.7-38.1-5.8-50.2-5.7v41.2h-16.8v-41.2c-12-.1-33.1 0-50.1 5.7 0 0 .5-6.7.5-14.4 0-7.8-.5-14.4-.5-14.4 18.5 6.2 39.4 5.8 50 5.6v-25.9c-9.7 0-23.7.4-39.6 5.7 0 0 .5-6.6.5-14.4 0-7.7-.5-14.4-.5-14.4 15.9 5.3 29.9 5.8 39.6 5.7-.5-16.4-5.3-37-5.3-37s9.9.7 13.8.7c4 0 13.8-.7 13.8-.7s-4.8 20.6-5.3 37c9.7.1 23.7-.4 39.6-5.7 0 0-.5 6.7-.5 14.4 0 7.8.5 14.4.5 14.4a119 119 0 0 0-39.7-5.7v26z"/>
		<path fill="#0b4ea2"
			  d="M233 263.3c-19.9 0-30.5 27.5-30.5 27.5s-6-13-22.2-13c-11 0-19 9.7-24.2 18.8 20 31.7 51.9 51.3 76.9 63.4 25-12 57-31.7 76.9-63.4-5.2-9-13.2-18.8-24.2-18.8-16.2 0-22.2 13-22.2 13S253 263.3 233 263.3z"/>
	</svg>
);