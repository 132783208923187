import {Reducer} from 'redux';
import {DeviceAction, TYPE_LIST, TYPE_REMOVE} from './actions/types';
import {combineCaseReducers} from '../reducerCombiners';
import {DeviceState, initialDeviceState} from './deviceState';
import {reduceDeviceList} from './actions/ListAction';
import {reduceRemoveDevice} from './actions/RemoveAction';


const caseReducers: { [k in DeviceAction["type"]]: Reducer } = {
	[TYPE_LIST]: reduceDeviceList,
	[TYPE_REMOVE]: reduceRemoveDevice
};

export const deviceReducer = combineCaseReducers<DeviceState>(
	initialDeviceState,
	caseReducers
);