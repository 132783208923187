import axios from "axios";
import {ApiResponse, getHeaders} from './ApiResponse';
import {CancelablePromise} from '../model/cancelablePromise';


export enum EventStatus
{
	payment = 'payment',
	queue = 'queue',
	dns = 'dns',
	creating = 'creating',
	paused = 'paused',
	running = 'running'
}

export enum EventPaymentMethod
{
	CHARGE_AUTOMATICALLY = 'charge_automatically',
	SEND_INVOICE = 'send_invoice'
}

export interface Event
{
	uid: string;
	key: string;
	name: string;
	status: EventStatus;
	domain: string;
	participants: number;
	totalPaid: number;
	sharedInfo: EventSharedInfo;
	modules: EventActiveModule[];
	languages: EventLanguage[];
	remote: EventRemoteData;
	invoiceUrl: string|null;
}


export interface CreatingEvent
{
	name: string;
	domain: string;
	language: string;
	contactEmail: string;
	adminFirstName: string;
	adminLastName: string;
	adminEmail: string;
	adminPassword: string;
	apiKeyGoogleMaps?: string;
	modules: string[];
	participants: number;
	payment: EventPaymentMethod;
}


export interface UpdatingEvent
{
	name?: string;
	language?: string;
	languages?: string[];
	contactEmail?: string;
	adminPassword?: string;
	apiKeyGoogleMaps?: string;
	modules?: string[];
}


export interface EventSharedInfo
{
	defaultLanguage: string;
	contactEmail: string;
	iconData: boolean;
	adminFirstName: string;
	adminLastName: string;
	adminEmail: string;
}


export interface EventActiveModule
{
	uid: string;
	key: string;
}


export interface EventLanguage
{
	code: string;
	isDefault: boolean;
}


export interface EventRemoteData
{
	participants: number;
}


export interface EventCreateResponse
{
	event: Event;
}


export const EventLanguageOptions = [
	'English',
	'Czech',
	'Slovak',
];

export const getEventLanguageCode = (language: string): string =>
{
	switch (language) {
		case 'Czech':
			return 'cz';
		case 'German':
			return 'de';
		case 'French':
			return 'fr';
		case 'Slovak':
			return 'sk';
		case 'Hungarian':
			return 'hu';
		default:
			return 'gb';
	}
};

export const getEventLanguage = (code: string): string =>
{
	switch (code) {
		case 'cz':
			return EventLanguageOptions[1];
		case 'sk':
			return EventLanguageOptions[2];
		default:
			return EventLanguageOptions[0];
	}
};

export type EventsApiResponse = ApiResponse<Event[]>;

export type EventApiResponse = ApiResponse<Event>;

export type EventCreateApiResponse = ApiResponse<EventCreateResponse>;

export type EventEmptyApiResponse = ApiResponse<{}>;

export async function eventListRequest(accessToken: string): Promise<EventsApiResponse>
{
	const response = await axios.get<EventsApiResponse>('/api/event', {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventDetailRequest(accessToken: string, uid: string): Promise<EventApiResponse>
{
	const response = await axios.get<EventApiResponse>(`/api/event/${uid}`, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventCreateRequest(accessToken: string, event: CreatingEvent, coupon?: string): Promise<EventCreateApiResponse>
{
	const response = await axios.post<EventCreateApiResponse>('/api/event', {...event, coupon}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventUpdateRequest(accessToken: string, uid: string, event: UpdatingEvent): Promise<EventApiResponse>
{
	const response = await axios.put<EventApiResponse>(`/api/event/${uid}`, event, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventPauseRequest(accessToken: string, uid: string): Promise<EventEmptyApiResponse>
{
	const response = await axios.put<EventApiResponse>(`/api/event/${uid}/pause`, {}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventResumeRequest(accessToken: string, uid: string): Promise<EventEmptyApiResponse>
{
	const response = await axios.put<EventApiResponse>(`/api/event/${uid}/resume`, {}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventRemoveRequest(accessToken: string, uid: string): Promise<EventEmptyApiResponse>
{
	const response = await axios.delete<EventApiResponse>(`/api/event/${uid}`, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventPayRequest(accessToken: string, uid: string): Promise<EventCreateApiResponse>
{
	const response = await axios.post<EventCreateApiResponse>(`/api/event/${uid}/pay`, {}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventPayExtraRequest(accessToken: string, uid: string, participants: number, modules: string[], payment: EventPaymentMethod): Promise<EventCreateApiResponse>
{
	const response = await axios.post<EventCreateApiResponse>(`/api/event/${uid}/pay/extra`, {
		participants,
		modules,
		payment
	}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventPayCompleteRequest(accessToken: string, sessionId: string): Promise<EventEmptyApiResponse>
{
	const response = await axios.post<EventEmptyApiResponse>(`/api/event/pay/complete`, {sessionId}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function eventUploadIconRequest(accessToken: string, uid: string, file: File): Promise<EventEmptyApiResponse>
{
	const data = new FormData();
	data.append('icon', file);

	const response = await axios.post<EventEmptyApiResponse>(`/api/event/${uid}/icon`, data, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export function eventIconRequest(accessToken: string, uid: string): CancelablePromise<ArrayBuffer>
{
	const CancelToken = axios.CancelToken;
	let cancel = () =>
	{
	};

	const request = axios.get<any, ArrayBuffer>(`/api/event/${uid}/icon`, {
		headers: getHeaders(accessToken),
		responseType: 'arraybuffer',
		cancelToken: new CancelToken((c) => cancel = c)
	});

	return {request, cancel};
}


