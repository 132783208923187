import {SubscriptionPlan, subscriptionStartRequest} from '../../../api/Subscription';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {StartAction, TYPE_START} from './types';
import {SubscriptionStartStatus, SubscriptionState} from '../subscriptionState';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionSubscriptionStart = (plan: SubscriptionPlan, coupon?: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<StartAction>({
		type: TYPE_START,
		sessionId: undefined,
		start: SubscriptionStartStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await subscriptionStartRequest(accessToken, plan, coupon).then((result: any) =>
	{
		const {payload} = result;

		dispatch<StartAction>({
			type: TYPE_START,
			start: SubscriptionStartStatus.started,
			...payload
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<StartAction>({
			type: TYPE_START,
			sessionId: undefined,
			start: SubscriptionStartStatus.prepared
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceSubscriptionStart = (state: SubscriptionState, action: StartAction): SubscriptionState => ({
	...state,
	...action
});