import React from 'react';
import {FormControl, InputLabel, Select, MenuItem, FormHelperText} from '@material-ui/core';
import {withGeneratedId} from '../../utils/htmlId';


interface Props
{
	disabled?: boolean;
	required?: boolean;
	error?: boolean;
	label: string;
	options: string[];
	value: string;
	helperText?: React.ReactNode;
	onChange: (value: string) => void;
}


interface State
{

}


class SelectField extends React.Component<Props, State>
{

	public state = {};

	public render = () => (
		<FormControl variant="outlined" className={'TextField'} disabled={this.props.disabled} error={this.props.error} fullWidth={true} required={this.props.required}>
			{withGeneratedId((id: string) => <>
				<InputLabel id={id}>{this.props.label}</InputLabel>
				<Select
					className='Select__input'
					labelId={id}
					onChange={(e) => this.props.onChange(e.target.value as string)}
					id={id}
					value={this.props.value}
					label={this.props.label + (this.props.required ? ' *' : '')}
				>
					{this.props.options.map((value, index) => (
						<MenuItem key={index} value={value}>{value}</MenuItem>
					))}
				</Select>
				<FormHelperText>{this.props.helperText}</FormHelperText>
			</>)}
		</FormControl>
	);
}


export default SelectField;