import React from 'react';
import zxcvbn from 'zxcvbn';
import {LinearProgress} from '@material-ui/core';
import {withTranslation, WithTranslation} from 'react-i18next';


interface Props
{
	password: string;
}


interface State
{

}


class BarePasswordChecker extends React.Component<Props & WithTranslation, State>
{
	protected kMin = 0;
	protected kMax = 4;

	public render = () =>
	{
		const {password} = this.props;
		const result = zxcvbn(password);

		return (
			<div className='PasswordChecker'>
				<LinearProgress
					color={result.score < 2 ? 'primary' : 'secondary'}
					className='PasswordChecker__progress'
					variant="determinate"
					value={this.normalise(result.score)}
				/>
				<div className='PasswordChecker__label'>
					<strong>{this.props.t('layout.password.strength')}</strong> {this.title(result.score)}
					<small>{result.feedback.warning}</small>
				</div>
			</div>
		);
	};

	protected normalise = (value: number) => (value - this.kMin) * 100 / (this.kMax - this.kMin);

	protected title = (score: number) =>
	{
		switch (score) {
			case 0:
				return this.props.t('layout.password.level.weak');
			case 1:
				return this.props.t('layout.password.level.weak');
			case 2:
				return this.props.t('layout.password.level.fair');
			case 3:
				return this.props.t('layout.password.level.good');
			case 4:
				return this.props.t('layout.password.level.strong');
			default:
				return this.props.t('layout.password.level.weak');
		}
	};
}


export const PasswordChecker = withTranslation('app')(BarePasswordChecker);