import {Coupon} from "../../../api/Coupon";
import {CouponValidationStatus} from "../couponState";


export const TYPE_VALIDATE = 'coupon/VALIDATE';

export interface ValidateAction {
    type: typeof TYPE_VALIDATE;
    coupon?: Coupon;
    validation: CouponValidationStatus;
}

export type CouponAction = ValidateAction;