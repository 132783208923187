import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {StartAction, TYPE_UPDATE_CARD, UpdateCardAction} from './types';
import {SubscriptionState} from '../subscriptionState';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';
import {billingUpdateCard} from "../../../api/Billing";


export const actionSubscriptionUpdateCard = (id: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) => {
    dispatch<UpdateCardAction>({type: TYPE_UPDATE_CARD});

    const {user: {device}} = getState();
    const accessToken: string = device?.accessToken || '';

    await billingUpdateCard(accessToken, id).then((result: any) => {
        const {message} = result;

        dispatch<UpdateCardAction>({type: TYPE_UPDATE_CARD});
        sendAlert(AlertType.success, message);

    }).catch((result: any) => {
        const response: ApiErrorResponse = result.response.data;
        const {message} = response;

        dispatch<UpdateCardAction>({type: TYPE_UPDATE_CARD});

        sendAlert(AlertType.error, message);
    });
};

export const reduceSubscriptionUpdateCard = (state: SubscriptionState, action: StartAction): SubscriptionState => ({
    ...state,
    ...action
});