import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {BillingForm} from '../billing/BillingForm';
import {withTranslation, WithTranslation} from 'react-i18next';


interface StateProps
{

}


interface DispatchProps
{

}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{

}


class BareOnboardingBilling extends React.Component<Props, State>
{

	public render = () => (
		<div className='Onboarding__content Onboarding__content--center'>
			<h1>{this.props.t('onboarding.billing.title')}</h1>
			<p>{this.props.t('onboarding.billing.message')}</p>
			<BillingForm
				simplified={true}
				saveLabel={this.props.t('onboarding.billing.save.default')}
				savingLabel={this.props.t('onboarding.billing.save.loading')}
			/>
		</div>
	);
}


export const OnboardingBilling = withTranslation('app')(connect(
	(state: AppState) => ({}),
	(dispatch: AppThunkDispatch) => ({})
)(BareOnboardingBilling));