import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {AppState, initialAppState} from './State';
import thunk from 'redux-thunk';
import {userReducer} from './user/userReducer';
import {recoveryReducer} from './recovery/recoveryReducer';
import {deviceReducer} from './device/deviceReducer';
import {eventReducer} from './event/eventReducer';
import {subscriptionReducer} from './subscription/subscriptionReducer';
import {couponReducer} from "./coupon/couponReducer";


const rootReducer = combineReducers({
	user: userReducer,
	recovery: recoveryReducer,
	device: deviceReducer,
	events: eventReducer,
	subscription: subscriptionReducer,
	coupon: couponReducer
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureStore = (initialState: AppState = initialAppState) => createStore(
	rootReducer,
	initialState,
	composeEnhancers(applyMiddleware(thunk))
);
