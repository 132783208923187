import {ApiResponse, getHeaders} from './ApiResponse';
import axios from 'axios';


export interface Recovery
{
	email?: string;
}


export type RecoveryApiResponse = ApiResponse<Recovery>;

export type RecoveryProcessApiResponse = ApiResponse<{}>;

export async function recoveryValidateRequest(recoveryToken: string): Promise<RecoveryApiResponse>
{
	const response = await axios.post<RecoveryApiResponse>('/api/user/recovery/validate', {
		recoveryToken
	}, {headers: getHeaders()});

	return response.data;
}

export async function recoveryProcessRequest(recoveryToken: string, password: string): Promise<RecoveryProcessApiResponse>
{
	const response = await axios.post<RecoveryApiResponse>('/api/user/recovery/change', {
		recoveryToken,
		password
	}, {headers: getHeaders()});

	return response.data;
}