import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {eventDetailRequest} from '../../../api/Event';
import {EventDetailAction, TYPE_DETAIL} from './types';
import {EventLoadingStatus, EventState} from '../eventState';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionEventDetail = (uid: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<EventDetailAction>({
		type: TYPE_DETAIL,
		event: undefined,
		detail: EventLoadingStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await eventDetailRequest(accessToken, uid).then((result: any) =>
	{
		const {payload} = result;
		dispatch<EventDetailAction>({
			type: TYPE_DETAIL,
			event: payload,
			detail: EventLoadingStatus.loaded
		});
	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<EventDetailAction>({
			type: TYPE_DETAIL,
			event: undefined,
			detail: EventLoadingStatus.loaded
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceEventDetailAction = (state: EventState, action: EventDetailAction): EventState => ({
	...state,
	...action
});