import React, {ChangeEvent, ReactNode} from 'react';
import {InputAdornment, TextField} from "@material-ui/core";
import {CheckCircleRounded, ErrorRounded, RedeemRounded} from "@material-ui/icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {AppState} from "../../model/State";
import {AppThunkDispatch} from "../../model/Actions";
import {CouponValidationStatus} from "../../model/coupon/couponState";
import {Coupon} from "../../api/Coupon";
import {actionValidateCoupon} from "../../model/coupon/actions/CouponValidateAction";


interface OwnProps {
    label?: ReactNode;
    onChange?: (coupon?: string) => void;
}

interface StateProps {
    status: CouponValidationStatus;
    result?: Coupon;
}

interface DispatchProps {
    validate: (coupon: string) => void;
}


interface State {
    coupon: string;
}

type Props = OwnProps & StateProps & DispatchProps & WithTranslation;


class BareCouponField extends React.Component<Props, State> {

    public state: State = {
        coupon: ''
    };

    protected onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.setState({coupon: event.target.value.toUpperCase()});
    };

    protected onBlur = () => {
        const {coupon} = this.state;
        this.props.validate(coupon);

        if (this.props.onChange) {
            this.props.onChange(this.state.coupon);
        }
    };

    public render = () => {
        const {label, status, result} = this.props;
        const {coupon} = this.state;

        let helperText;
        if (status === CouponValidationStatus.invalid) {
            helperText = this.props.t('api.validation.coupon.coupon.invalid');
        }

        if (status === CouponValidationStatus.valid && result) {
            helperText = this.props.t('api.validation.coupon.coupon.valid', {
                name: result.name
            });
        }

        return (
            <div className='Coupon'>
                <TextField
                    fullWidth
                    className={'TextField'}
                    variant={'outlined'}
                    size={'small'}
                    value={coupon}
                    error={status === CouponValidationStatus.invalid}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    label={label}
                    helperText={helperText}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                {status === CouponValidationStatus.prepared && (
                                    <RedeemRounded className='Icon' color={'inherit'}/>
                                )}
                                {status === CouponValidationStatus.loading && (<div className='Loader'>Loading…</div>)}
                                {status === CouponValidationStatus.invalid && (<ErrorRounded color={'error'}/>)}
                                {status === CouponValidationStatus.valid && (<CheckCircleRounded color={'secondary'}/>)}
                            </InputAdornment>
                        )
                    }}
                />
            </div>
        );
    }
}


export const CouponField = withTranslation('app')(connect(
    (state: AppState) => ({
        status: state.coupon.validation,
        result: state.coupon.coupon
    }),
    (dispatch: AppThunkDispatch) => ({
        validate: (coupon: string) => dispatch(actionValidateCoupon(coupon))
    })
)(BareCouponField));