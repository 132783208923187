import {User, UserDevice} from '../../../api/User';
import {
	ConfirmationResendStatus,
	ConfirmationStatus,
	LoginRecoveryStatus,
	LoginStatus,
	RegisterStatus, UserOnboardingCompleteStatus, UserSaveStatus,
	UserSyncStatus,
	ValidateStatus
} from '../userState';
import {Billing} from '../../../api/Billing';
import {Module} from '../../../api/Module';


export const TYPE_LOGIN = 'user/LOGIN';
export const TYPE_LOGIN_RECOVER = 'user/LOGIN_RECOVER';
export const TYPE_REGISTER = 'user/REGISTER';
export const TYPE_LOGOUT = 'user/LOGOUT';
export const TYPE_CONFIRM = 'user/CONFIRM';
export const TYPE_CONFIRM_RESEND = 'user/CONFIRM_RESEND';
export const TYPE_SYNC = 'user/SYNC';
export const TYPE_BILLING_SAVE = 'user/BILLING_SAVE';
export const TYPE_ONBOARDING_COMPLETE = 'user/ONBOARDING_COMPLETE';
export const TYPE_SAVE = 'user/SAVE';
export const TYPE_CHANGE_PASSWORD = 'user/CHANGE_PASSWORD';


export interface LoginAction
{
	type: typeof TYPE_LOGIN;
	validate?: ValidateStatus;
	login?: LoginStatus;
	user?: User;
	device?: UserDevice;
	billing?: Billing;
	modules?: Module[];
}


export interface RegisterAction
{
	type: typeof TYPE_REGISTER;
	register?: RegisterStatus;
	user?: User;
	device?: UserDevice;
	billing?: Billing;
	modules?: Module[];
}


export interface LoginRecoverAction
{
	type: typeof TYPE_LOGIN_RECOVER;
	recovery?: LoginRecoveryStatus;
}


export interface LogoutAction
{
	type: typeof TYPE_LOGOUT;
	login?: LoginStatus;
	user?: User;
	device?: UserDevice;
	billing?: Billing;
	modules?: Module[];
}


export interface ConfirmAction
{
	type: typeof TYPE_CONFIRM;
	confirmation?: ConfirmationStatus;
}


export interface ConfirmResendAction
{
	type: typeof TYPE_CONFIRM_RESEND;
	confirmationResend?: ConfirmationResendStatus;
}


export interface SyncAction
{
	type: typeof TYPE_SYNC;
	sync?: UserSyncStatus;
	user?: User;
	device?: UserDevice;
	billing?: Billing;
	modules?: Module[];
}


export interface BillingSaveAction
{
	type: typeof TYPE_BILLING_SAVE;
	save?: UserSaveStatus;
	user?: User;
	billing?: Billing;
}


export interface OnboardingCompleteAction
{
	type: typeof TYPE_ONBOARDING_COMPLETE;
	onboarding?: UserOnboardingCompleteStatus;
	user?: User;
}


export interface SaveAction
{
	type: typeof TYPE_SAVE;
	save?: UserSaveStatus;
	user?: User;
	billing?: Billing;
}


export interface ChangePasswordAction
{
	type: typeof TYPE_CHANGE_PASSWORD;
	save?: UserSaveStatus;
}


export type UserAction =
	LoginAction |
	LoginRecoverAction |
	RegisterAction |
	LogoutAction |
	ConfirmAction |
	ConfirmResendAction |
	SyncAction |
	BillingSaveAction |
	OnboardingCompleteAction |
	SaveAction |
	ChangePasswordAction;

    