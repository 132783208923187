import {Event} from '../../api/Event';


export enum EventLoadingStatus
{
	loaded,
	loading
}


export enum EventCreateStatus
{
	prepared,
	loading,
	uploading,
	success,
	failed
}


export enum EventRemoveStatus
{
	prepared,
	removing,
	removed
}


export enum EventPayStatus
{
	prepared,
	paying,
	paid
}


export enum EventPayCompleteStatus
{
	prepared,
	completing,
	completed,
	failed
}


export interface EventState
{
	// Data
	events: Event[];
	event?: Event;
	sessionId?: string;

	// Statuses
	loading: EventLoadingStatus;
	detail: EventLoadingStatus;
	creating: EventCreateStatus;
	updating: EventCreateStatus;
	remove: EventRemoveStatus;
	pay: EventPayStatus;
	payExtra: EventPayStatus;
	payComplete: EventPayCompleteStatus;
}


export const initialEventState: EventState = {

	// Data
	events: [],
	event: undefined,
	sessionId: undefined,

	// Statuses,
	loading: EventLoadingStatus.loading,
	detail: EventLoadingStatus.loading,
	creating: EventCreateStatus.prepared,
	updating: EventCreateStatus.prepared,
	remove: EventRemoveStatus.prepared,
	pay: EventPayStatus.prepared,
	payExtra: EventPayStatus.prepared,
	payComplete: EventPayCompleteStatus.prepared
};