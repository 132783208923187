import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Event, eventIconRequest, EventStatus} from '../../api/Event';
import {EventLoader} from '../utils/EventLoader';
import {CancelablePromise} from '../../model/cancelablePromise';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core';
import {CloseRounded, EmailRounded, OpenInNewRounded} from '@material-ui/icons';
import {Module} from '../../api/Module';
import {DNSDialog} from '../dialog/DNSDialog';
import {EVENTS_DETAIL} from '../../routes';
import {Link} from 'react-router-dom';
import {EventPayStatus, EventRemoveStatus} from '../../model/event/eventState';
import {actionEventRemove} from '../../model/event/actions/RemoveAction';
import {actionEventListAction} from '../../model/event/actions/ListAction';
import {actionEventPay} from '../../model/event/actions/PayAction';
import {AlertType, sendAlert} from '../Alert';
import {WithTranslation, withTranslation} from 'react-i18next';
import {stripePromise} from "../Stripe";


interface StateProps
{
	modules: Module[];
	event: Event;
	accessToken: string;
	remove: EventRemoveStatus;
	pay: EventPayStatus;
	sessionId?: string;
	publishableKey: string;
}


interface DispatchProps
{
	removeEvent: (uid: string) => void;
	payEvent: (uid: string) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	icon?: string;
	iconDefault: boolean;
	isDnsOpened: boolean;
	isRemoveOpened: boolean;
}


class BareEventListItem extends React.PureComponent<Props, State>
{

	public state: State = {
		icon: undefined,
		iconDefault: false,
		isDnsOpened: false,
		isRemoveOpened: false
	};

	protected promise?: CancelablePromise<ArrayBuffer>;


	public componentDidMount(): void
	{
		this.loadIcon();
	}


	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void
	{
		if (!prevProps.sessionId && this.props.sessionId && this.props.pay === EventPayStatus.paid) {
			this.checkout();
		}
	}


	protected loadIcon = () =>
	{
		const {accessToken, event: {uid}} = this.props;
		this.promise = eventIconRequest(accessToken, uid);
		this.promise.request.then((response: any) =>
		{
			if (!response) {
				this.setState({iconDefault: true});
				return;
			}

			const icon = Buffer.from(response.data, 'binary').toString('base64');
			this.setState({icon});
		}).catch(() =>
		{
			this.setState({iconDefault: true});
		});
	};


	public componentWillUnmount(): void
	{
		if (!this.promise) {
			return;
		}

		this.promise.cancel();
	}


	protected getTotalPrice = (): number =>
	{
		const {modules, event} = this.props;
		let price = 0;
		modules.forEach((module) =>
		{
			if (event.modules.find((m) => m.key === module.key)) {
				price += module.pricePerParticipant;
			}
		});

		return (Math.round((price + Number.EPSILON) * 100) / 100) * event.participants;
	};

	public render = () =>
	{
		const {event} = this.props;
		const {icon, iconDefault} = this.state;

		if (event.status === EventStatus.payment) {
			return (
				<>
					<div className='Event Event--payment' data-key={event.key}>
						<div className='Event__iconWrapper'>
							<div
								className='Event__icon'
								style={{
									backgroundImage: !iconDefault ? `url(data:image/webp;base64,${icon})` : 'url(/default.png)',
									opacity: icon || iconDefault ? 1 : 0
								}}
							/>
						</div>
						<h2 className='Event__name'>{event.name}</h2>
						<p className='Event__description'>{this.props.t('events.list.item.waitingForPayment')}</p>
						<div className='Event__actions'>
							<Button disabled={this.props.pay === EventPayStatus.paying || this.props.remove === EventRemoveStatus.removing}
									onClick={() => this.setState({isRemoveOpened: true})} className='Button' variant="outlined" size="medium"
									color="primary" startIcon={(<CloseRounded/>)}>
								{this.props.remove === EventRemoveStatus.removing ?
									this.props.t('events.list.item.remove.loading') :
									this.props.t('events.list.item.remove.default')
								}
							</Button>
							{this.props.event.invoiceUrl && (
								<a href={this.props.event.invoiceUrl} target='_blank' rel="noopener noreferrer">
									<Button disabled={this.props.pay === EventPayStatus.paying || this.props.remove === EventRemoveStatus.removing}
											className='Button' variant="contained" size="medium" color="primary" endIcon={(<OpenInNewRounded/>)}>
										{this.props.pay === EventPayStatus.paying ?
											this.props.t('events.list.item.pay.loading') :
											this.props.t('events.list.item.pay.default')
										}
									</Button>
								</a>
							)}
						</div>
					</div>
					{this.state.isRemoveOpened && (
						<Dialog
							disableBackdropClick={false}
							disableEscapeKeyDown={false}
							className='Dialog'
							open={true}
							onClose={() => this.setState({isRemoveOpened: false})}>
							<h2>{this.props.t('events.list.item.remove.confirm.title')}</h2>
							<DialogContent>
								<DialogContentText style={{marginBottom: 24}}>
									{this.props.t('events.list.item.remove.confirm.message')}&nbsp;
									<a href='mailto:hello@simpleflow.io' className='Text--primary'>
										<strong>
											{this.props.t('events.list.item.remove.confirm.writeUs')}&nbsp;
											<EmailRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
										</strong>
									</a>.
								</DialogContentText>
								<Button
									onClick={() =>
									{
										this.setState({isRemoveOpened: false});
										this.remove();
									}}
									variant="contained"
									color="primary"
									size="large">
									{this.props.t('events.list.item.remove.confirm.remove')}
								</Button>
								<Button
									className='Dialog__cancel'
									onClick={() => this.setState({isRemoveOpened: false})}
									size="large"
									variant='outlined'
									color="default">
									{this.props.t('events.list.item.remove.confirm.dismiss')}
								</Button>
							</DialogContent>
							<DialogActions/>
						</Dialog>
					)}
				</>
			);
		}

		if (event.status === EventStatus.creating || event.status === EventStatus.queue) {
			return (
				<div className='Event Event--creating' data-key={event.key}>
					<div className='Event__iconWrapper'>
						<div
							className='Event__icon'
							style={{
								backgroundImage: !iconDefault ? `url(data:image/webp;base64,${icon})` : 'url(/default.png)',
								opacity: icon || iconDefault ? 1 : 0
							}}
						/>
					</div>
					<h2 className='Event__name'>{event.name}</h2>
					<p className='Event__description'>{this.props.t('events.list.item.starting')}</p>
					{EventLoader}
				</div>
			);
		}

		let url = `${event.key}.event.simpleflow.io`;
		if (event.status === EventStatus.running) {
			url = `${event.domain}`;
		}

		return (
			<>
				<div className={`Event Event--${event.status}`} data-key={event.key}>
					<div className='Event__iconWrapper'>
						<div
							className='Event__icon'
							style={{
								backgroundImage: !iconDefault ? `url(data:image/webp;base64,${icon})` : 'url(/default.png)',
								opacity: icon || iconDefault ? 1 : 0
							}}
						/>
					</div>
					<h2 className='Event__name'>{event.name}</h2>
					<p className='Event__description'>
						<a href={`https://${url}`} target='_blank' rel='noopener noreferrer'>
							{url}
							<OpenInNewRounded fontSize={'inherit'}/>
						</a>
					</p>
					<div className='Event__actions'>
						<Link to={`${EVENTS_DETAIL}/${event.uid}`}>
							<Button className='Button' variant="outlined" size="medium" color="primary">
								{this.props.t('events.list.item.settings')}
							</Button>
						</Link>
						<a href={`https://${event.key}.event.simpleflow.io/admin`} target='_blank' rel='noopener noreferrer'>
							<Button className='Button' variant="contained" size="medium" color="primary" startIcon={(<OpenInNewRounded/>)}>
								{this.props.t('events.list.item.admin')}
							</Button>
						</a>
					</div>
					<div className='Event__footer'>
						<div className='Event__footerItem'>
							<div className='Event__footerTitle'>{this.props.t('events.list.item.state')}</div>
							<div className={`Event__status Event__status--${event.status}`}>{this.props.t(`event.status.${event.status}`)}</div>
							{event.status === EventStatus.dns && (
								<span>{this.props.t('events.list.item.waitingForDNS')} <a href={'#dns'} onClick={(e) =>
								{
									e.preventDefault();
									this.setState({isDnsOpened: true});
								}}>{this.props.t('events.list.item.dns.setup')}</a></span>
							)}
							{event.status === EventStatus.paused && (
								<span>
									{this.props.t('events.list.item.paused.help')}
									<a href='mailto:hello@simpleflow.io'>
										{this.props.t('events.list.item.paused.writeUs')}
									</a>.
								</span>
							)}
							{event.status === EventStatus.running && (
								<span>{this.props.t('events.list.item.running.help')}</span>
							)}
						</div>
						<div className='Event__footerItem Event__footerItem--right'>
							<div className='Event__footerTitle'>{this.props.t('events.list.item.paid')}</div>
							<div className='Event__value'>{event.totalPaid.toFixed(2)} EUR</div>
						</div>
						<div className='Event__footerItem Event__footerItem--right'>
							<div className='Event__footerTitle'>{this.props.t('events.list.item.participants')}</div>
							<div className='Event__value'>{event.remote.participants} / {event.participants}</div>
						</div>
						<div className='Event__footerItem Event__footerItem--right'>
							<div className='Event__footerTitle'>{this.props.t('events.list.item.modules')}</div>
							<div className='Event__value'>
								{this.props.t('events.list.item.modulesActive', {
									count: event.modules.length
								})}
							</div>
						</div>
					</div>
				</div>
				{this.state.isDnsOpened && <DNSDialog domain={event.domain} onClose={() => this.setState({isDnsOpened: false})}/>}
			</>
		);
	};

	protected remove = () => this.props.removeEvent(this.props.event.uid);

	protected checkout = async () =>
	{
		const {sessionId} = this.props;
		if (!sessionId) {
			return;
		}

		const stripe = await stripePromise(this.props.publishableKey);
		if (!stripe) {
			return;
		}

		const {error} = await stripe.redirectToCheckout({sessionId});
		if (error && error.message) {
			sendAlert(AlertType.error, error.message);
		}
	};
}


export const EventListItem = withTranslation('app')(connect(
	(state: AppState) => ({
		modules: state.user.modules || [],
		accessToken: state.user.device?.accessToken || '',
		remove: state.events.remove,
		pay: state.events.pay,
		sessionId: state.events.sessionId,
		publishableKey: state.user.user?.publishableKey || ''
	}),
	(dispatch: AppThunkDispatch) => ({
		removeEvent: (uid: string) => dispatch(actionEventRemove(uid)).then(() => dispatch(actionEventListAction(true))),
		payEvent: (uid: string) => dispatch(actionEventPay(uid))
	})
)(BareEventListItem));