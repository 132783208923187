import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Event, EventLanguageOptions, getEventLanguage, getEventLanguageCode, UpdatingEvent} from '../../api/Event';
import SelectField from '../form/SelectField';
import {languageFlag} from '../../utils/Flags';
import {Button, Switch} from '@material-ui/core';
import {EventCreateStatus} from '../../model/event/eventState';
import {actionEventUpdate} from '../../model/event/actions/UpdateAction';
import {actionEventDetail} from '../../model/event/actions/DetailAction';
import {withTranslation, WithTranslation} from 'react-i18next';


interface StateProps
{
	event: Event;
	status: EventCreateStatus;
}


interface DispatchProps
{
	update: (uid: string, event: UpdatingEvent) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	defaultLanguage?: string;
	languages: string[];
}


class BareEventLanguagesForm extends React.Component<Props, State>
{
	public state: State = {
		languages: []
	};


	public componentDidMount()
	{
		const languages = this.props.event.languages.map((l) => l.code);
		const defaultLanguage = this.props.event.sharedInfo.defaultLanguage;

		this.setState({languages, defaultLanguage});
	}


	public render = () =>
	{
		const hasError = this.props.status === EventCreateStatus.failed;
		const isSaving = this.props.status === EventCreateStatus.loading;

		const enabledLanguageNames = this.state.languages.map((code) => getEventLanguage(code));

		return (
			<form className='Form Form--full' onSubmit={this.save}>
				<div className='Form__column Form__column--full'>
					<h2>{this.props.t('events.edit.language.enabled.title')}</h2>
					<p>
						{this.props.t('events.edit.language.enabled.message')}
						<br/>
						<br/>
						<strong>
							{this.props.t('events.edit.language.enabled.warning')}
						</strong>
					</p>
					<div className='Form__column Form__column--full Form__languageList'>
						{EventLanguageOptions.map((name, key) =>
						{
							const code = getEventLanguageCode(name);
							const isDefault = this.state.defaultLanguage === code;
							const isEnabled = this.state.languages.includes(code);
							return (
								<div key={key} className={`Form__item Form__language`}>
									<div className='Form__languageInfo'>
										<div className={`Form__languageName ${isEnabled ? '' : 'Form__languageName--disabled'}`}>
											{languageFlag(code)}
											<span>{name} {isDefault && <small>{this.props.t('events.edit.language.enabled.default')}</small>}</span>
										</div>
									</div>
									<Switch
										disabled={isSaving}
										color={'primary'}
										className='Form__languageSwitch'
										checked={isEnabled}
										onChange={() => this.onLanguageChanged(code)}
									/>
								</div>
							);
						})}
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<h2>{this.props.t('events.edit.language.default.title')}</h2>
					<p>{this.props.t('events.edit.language.default.message')}</p>
					<div className='Form__group'>
						<div className='Form__item Form__item--state'>
							<div className='Form__segment Form__segment--medium'>
								{this.state.defaultLanguage && (
									<SelectField
										disabled={isSaving}
										error={hasError}
										required={true}
										onChange={(language: string) => this.onDefaultLanguageChanged(getEventLanguageCode(language))}
										value={getEventLanguage(this.state.defaultLanguage || '')}
										label={this.props.t('events.edit.language.default.language')}
										options={enabledLanguageNames}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<Button
						disabled={!this.isFormValid() || isSaving}
						onSubmit={this.save}
						type='submit'
						className='Button'
						variant="contained"
						size="large"
						color="secondary">
						{isSaving ?
							this.props.t('events.edit.language.update.loading') :
							this.props.t('events.edit.language.update.default')
						}
					</Button>
				</div>
			</form>
		);
	};

	protected save = (e: any) =>
	{
		e.preventDefault();
		if (!this.isFormValid()) {
			return;
		}

		const {languages, defaultLanguage} = this.state;
		this.props.update(this.props.event.uid, {
			language: defaultLanguage,
			languages
		});
	};

	protected isFormValid = (): boolean =>
	{
		return !!this.state.defaultLanguage && this.state.languages.length > 0;
	};

	protected onDefaultLanguageChanged = (defaultLanguage: string) =>
	{
		const {languages} = this.state;
		if (!languages.includes(defaultLanguage)) {
			languages.push(defaultLanguage);
		}

		this.setState({defaultLanguage, languages});
	};

	protected onLanguageChanged = (code: string) =>
	{
		if (this.state.defaultLanguage === code) {
			return;
		}

		let {languages} = this.state;

		if (!this.state.languages.includes(code)) {
			languages.push(code);
		} else {
			languages = languages.filter(c => c !== code);
		}

		this.setState({languages});
	};
}


export const EventLanguagesForm = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.events.updating
	}),
	(dispatch: AppThunkDispatch) => ({
		update: (uid: string, event: UpdatingEvent) => dispatch(actionEventUpdate(uid, event))
			.then(() => dispatch(actionEventDetail(uid)))
	})
)(BareEventLanguagesForm));