import {subscriptionCompleteRequest} from '../../../api/Subscription';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {CompleteAction, TYPE_COMPLETE} from './types';
import {SubscriptionCompleteStatus, SubscriptionState} from '../subscriptionState';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionSubscriptionComplete = (sessionId: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<CompleteAction>({
		type: TYPE_COMPLETE,
		complete: SubscriptionCompleteStatus.completing
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await subscriptionCompleteRequest(accessToken, sessionId).then((result: any) =>
	{
		const {payload} = result;

		dispatch<CompleteAction>({
			type: TYPE_COMPLETE,
			complete: SubscriptionCompleteStatus.completed,
			sessionId: undefined,
			...payload
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<CompleteAction>({
			type: TYPE_COMPLETE,
			complete: SubscriptionCompleteStatus.failed
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceSubscriptionComplete = (state: SubscriptionState, action: CompleteAction): SubscriptionState => ({
	...state,
	...action
});