import {subscriptionChangePlanRequest, SubscriptionPlan} from '../../../api/Subscription';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {ChangePlanAction, TYPE_CHANGE_PLAN} from './types';
import {SubscriptionChangePlanStatus, SubscriptionState} from '../subscriptionState';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionSubscriptionChangePlan = (plan: SubscriptionPlan): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<ChangePlanAction>({
		type: TYPE_CHANGE_PLAN,
		plan: SubscriptionChangePlanStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await subscriptionChangePlanRequest(accessToken, plan).then((result: any) =>
	{
		const {payload, message} = result;
		sendAlert(AlertType.success, message);

		dispatch<ChangePlanAction>({
			type: TYPE_CHANGE_PLAN,
			plan: SubscriptionChangePlanStatus.changed,
			subscription: payload.info,
			invoices: payload.invoices
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<ChangePlanAction>({
			type: TYPE_CHANGE_PLAN,
			plan: SubscriptionChangePlanStatus.prepared
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceSubscriptionChangePlan = (state: SubscriptionState, action: ChangePlanAction): SubscriptionState => ({
	...state,
	...action
});