import {DeviceLoadingStatus, DeviceRemoveStatus} from '../deviceState';
import {UserDevice} from '../../../api/User';


export const TYPE_LIST = 'device/LIST';
export const TYPE_REMOVE = 'device/REMOVE';


export interface ListAction
{
	type: typeof TYPE_LIST;
	loading?: DeviceLoadingStatus;
	devices?: UserDevice[];
}


export interface RemoveAction
{
	type: typeof TYPE_REMOVE;
	remove?: DeviceRemoveStatus;
}


export type DeviceAction = ListAction | RemoveAction;