import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Event, UpdatingEvent} from '../../api/Event';
import PasswordField from '../form/PasswordField';
import {generateHtmlId} from '../../utils/htmlId';
import {Button} from '@material-ui/core';
import {OpenInNewRounded} from '@material-ui/icons';
import {actionEventUpdate} from '../../model/event/actions/UpdateAction';
import {EventCreateStatus} from '../../model/event/eventState';
import {actionEventDetail} from '../../model/event/actions/DetailAction';
import {withTranslation, WithTranslation} from 'react-i18next';


interface StateProps
{
	status: EventCreateStatus;
	event: Event;
}


interface DispatchProps
{
	update: (uid: string, event: UpdatingEvent) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	apiKeyGoogleMaps?: string;
}


class BareEventApiForm extends React.Component<Props, State>
{

	public state: State = {
		apiKeyGoogleMaps: undefined
	};

	protected onGoogleMapKeyChanged = (apiKeyGoogleMaps: string) => this.setState({apiKeyGoogleMaps});

	protected save = (e: any) =>
	{
		e.preventDefault();
		let {apiKeyGoogleMaps} = this.state;

		if (!apiKeyGoogleMaps) {
			apiKeyGoogleMaps = undefined;
		}

		this.props.update(this.props.event.uid, {
			apiKeyGoogleMaps
		});
	};

	public render = () =>
	{
		const hasError = this.props.status === EventCreateStatus.failed;
		const isSaving = this.props.status === EventCreateStatus.loading;

		return (
			<form className='Form Form--full' onSubmit={this.save}>
				<div className='Form__column Form__column--full'>
					<p>
						{this.props.t('events.edit.api.message')}&nbsp;
						<a href={'https://developers.google.com/maps/documentation/javascript/get-api-key'} target='_blank' rel='noreferrer noopener'>
							<strong>
								{this.props.t('events.edit.api.obtain')}&nbsp;
								<OpenInNewRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
							</strong>
						</a>.
					</p>
					<div className='Form__group'>
						<div className='Form__item'>
							<PasswordField
								notched={true}
								disabled={isSaving}
								error={hasError}
								placeholder={this.props.t('events.edit.api.security')}
								required={false}
								label={this.props.t('events.edit.api.googleKey')}
								value={this.state.apiKeyGoogleMaps || ''}
								onChange={this.onGoogleMapKeyChanged}
								autoComplete={generateHtmlId()}
							/>
						</div>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<Button
						disabled={isSaving}
						onSubmit={this.save}
						type='submit'
						className='Button'
						variant="contained"
						size="large"
						color="secondary">
						{isSaving ?
							this.props.t('events.edit.api.update.updating') :
							this.props.t('events.edit.api.update.default')
						}
					</Button>
				</div>
			</form>
		);
	};
}


export const EventApiForm = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.events.updating
	}),
	(dispatch: AppThunkDispatch) => ({
		update: (uid: string, event: UpdatingEvent) => dispatch(actionEventUpdate(uid, event))
			.then(() => dispatch(actionEventDetail(uid)))
	})
)(BareEventApiForm));