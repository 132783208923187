import React from 'react';
import {Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions} from '@material-ui/core';
import {WithTranslation, withTranslation} from 'react-i18next';


interface Props
{
	disabled?: boolean;
	email?: string;
	onClose: () => void;
	onConfirm: (email: string) => void;
}


interface State
{
	email: string;
}


class BareRecoveryPasswordDialog extends React.Component<Props & WithTranslation, State>
{

	public state = {
		email: ''
	};


	public componentDidMount(): void
	{
		this.setState({email: this.props.email || ''});
	}


	protected isFormValid = (): boolean =>
	{
		return this.state.email.length > 0;
	};

	public render = () => (
		<Dialog
			disableBackdropClick={this.props.disabled}
			disableEscapeKeyDown={this.props.disabled}
			className='Dialog'
			open={true}
			onClose={this.props.onClose}>
			<h2>{this.props.t('auth.recoveryDialog.title')}</h2>
			<DialogContent>
				<DialogContentText>
					{this.props.t('auth.recoveryDialog.message')}
				</DialogContentText>
				<form onSubmit={() => this.props.onConfirm(this.state.email)}>
					<TextField
						disabled={this.props.disabled}
						className='TextField'
						onChange={(e) => this.setState({email: e.target.value})}
						value={this.state.email}
						label={this.props.t('auth.recoveryDialog.email')}
						variant="outlined"
						fullWidth={true}
					/>
					<Button
						type={'submit'}
						disabled={this.props.disabled || !this.isFormValid()}
						variant="contained"
						color="primary"
						size="large"
						onClick={() => this.props.onConfirm(this.state.email)}>
						{this.props.t('auth.recoveryDialog.send')}
					</Button>
					<Button
						disabled={this.props.disabled}
						className='Dialog__cancel'
						onClick={this.props.onClose}
						size="large"
						variant='outlined'
						color="primary">
						{this.props.t('auth.recoveryDialog.dismiss')}
					</Button>
				</form>
			</DialogContent>
			<DialogActions>


			</DialogActions>
		</Dialog>
	);
}


export const RecoveryPasswordDialog = withTranslation('app')(BareRecoveryPasswordDialog);