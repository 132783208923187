import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {connect} from 'react-redux';
import React from 'react';
import {AuthLayout} from '../../components/AuthLayout';
import {Button, TextField} from '@material-ui/core';
import PasswordField from '../../components/form/PasswordField';
import {Link} from 'react-router-dom';
import {USER_LOGIN} from '../../routes';
import CheckboxField from '../../components/form/CheckboxField';
import {TYPE_REGISTER} from '../../model/user/actions/types';
import {actionRegister} from '../../model/user/actions/RegisterAction';
import {RegisterStatus} from '../../model/user/userState';
import {PasswordChecker} from '../../components/form/PasswordChecker';
import {withTranslation, WithTranslation} from 'react-i18next';


interface Props
{
	status: RegisterStatus;

	onRegister: (name: string, email: string, password: string) => void;
	resetFailedState: () => void;
}


interface State
{
	name: string;
	email: string;
	password: string;
	isAgree: boolean;
}


class BareRegisterScreen extends React.PureComponent<Props & WithTranslation, State>
{

	public state = {
		isAgree: false,
		name: '',
		email: '',
		password: ''
	};

	public componentDidMount = () =>
	{
		this.props.resetFailedState();
	};

	protected isFormValid = (): boolean =>
	{
		return this.state.name.length > 0 && this.state.email.length > 0 && this.state.password.length > 0 && this.state.isAgree;
	};

	protected register = () =>
	{
		if (!this.isFormValid()) {
			return;
		}

		this.props.onRegister(this.state.name, this.state.email, this.state.password);
	};

	public render = () => (
		<>
			<AuthLayout title={this.props.t('auth.register.title')} message={(
				<p>{this.props.t('auth.register.message')}</p>
			)}>
				<form onSubmit={this.register}>
					<TextField
						error={this.props.status === RegisterStatus.failed}
						disabled={this.props.status === RegisterStatus.loading}
						className='TextField'
						onChange={(e) => this.setState({name: e.target.value})}
						value={this.state.name}
						id="name"
						label={this.props.t('auth.register.name')}
						variant="outlined"
						fullWidth={true}
					/>
					<TextField
						error={this.props.status === RegisterStatus.failed}
						disabled={this.props.status === RegisterStatus.loading}
						className='TextField'
						onChange={(e) => this.setState({email: e.target.value})}
						value={this.state.email}
						id="email"
						label={this.props.t('auth.register.email')}
						variant="outlined"
						fullWidth={true}
					/>
					<PasswordField
						error={this.props.status === RegisterStatus.failed}
						disabled={this.props.status === RegisterStatus.loading}
						label={this.props.t('auth.register.password')}
						value={this.state.password}
						onChange={(password) => this.setState({password})}
					/>
					<PasswordChecker password={this.state.password}/>
					<CheckboxField
						disabled={this.props.status === RegisterStatus.loading}
						isChecked={this.state.isAgree}
						onChange={(isAgree) => this.setState({isAgree})}
						label={(<span dangerouslySetInnerHTML={{
							__html: this.props.t('auth.register.agreement.text', {
								agreement: `<a href='https://www.simpleflow.io/' target='_blank' rel='noopener noreferrer'>${this.props.t('auth.register.agreement.agreement')}</a>`
							})
						}}/>)}
					/>
					<div className='Sign__actions'>
						<div className='Sign__action'>
							<Button
								type={'submit'}
								disabled={this.props.status === RegisterStatus.loading || !this.isFormValid()}
								onClick={this.register}
								variant="contained"
								fullWidth={true}
								size="large"
								color="secondary">
								{this.props.t('auth.register.register')}
							</Button>
						</div>
						<div className='Sign__action'>
							<Link to={USER_LOGIN}>
								<Button
									disabled={this.props.status === RegisterStatus.loading}
									variant="outlined"
									fullWidth={true}
									size="large"
									color="primary">
									{this.props.t('auth.register.login')}
								</Button>
							</Link>
						</div>
					</div>
				</form>
			</AuthLayout>
		</>
	);
}


export const RegisterScreen = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.user.register
	}),
	(dispatch: AppThunkDispatch) => ({
		resetFailedState: () =>
		{
			dispatch({type: TYPE_REGISTER, register: RegisterStatus.prepared});
		},
		onRegister: (name: string, email: string, password: string) =>
		{
			dispatch(actionRegister(name, email, password));
		}
	})
)(BareRegisterScreen));