import {eventPayRequest} from '../../../api/Event';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {EventPayStatus, EventState} from '../eventState';
import {EventPayAction, TYPE_PAY} from './types';
import {AlertType, sendAlert} from '../../../components/Alert';
import {ApiErrorResponse} from '../../../api/ApiResponse';


export const actionEventPay = (uid: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<EventPayAction>({
		type: TYPE_PAY,
		pay: EventPayStatus.paying,
		sessionId: undefined
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	const error = (result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<EventPayAction>({
			type: TYPE_PAY,
			pay: EventPayStatus.prepared,
			sessionId: undefined
		});

		sendAlert(AlertType.error, message);
	};

	await eventPayRequest(accessToken, uid).then((result: any) =>
	{
		const {payload: {sessionId}, message} = result;

		dispatch<EventPayAction>({
			type: TYPE_PAY,
			pay: EventPayStatus.paid,
			sessionId
		});

		sendAlert(AlertType.success, message);

	}).catch(error);
};

export const reduceEventPay = (state: EventState, action: EventPayAction): EventState => ({
	...state,
	...action
});