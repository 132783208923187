import React from 'react';
import {Navigation} from './Navigation';
import {connect} from 'react-redux';
import {AppState} from '../model/State';
import {AppThunkDispatch} from '../model/Actions';
import {UserStatus} from '../api/User';
import {Onboarding} from './onboarding/Onboarding';
import {LanguageSelect} from './utils/LanguageSelect';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	isUserActive: boolean;
	children: React.ReactNode;
	title: string;
	link?: string;
}


interface DispatchProps
{

}


type Props = StateProps & DispatchProps;


interface State
{

}


class BareLayout extends React.Component<Props & WithTranslation, State>
{

	public render = () => (
		<div className='App'>
			<Navigation disabled={!this.props.isUserActive} title={this.props.title} link={this.props.link}/>
			<div className='Content'>
				<div className='Container'>
					{this.props.isUserActive ? this.props.children : <Onboarding/>}
				</div>
			</div>
			<div className='Footer'>
				<div className='Container'>
					<div className='Copyright'>
						&copy; {new Date().getFullYear()} <a href='https://simpleflow.io' target='_blank' rel='noopener noreferrer'>Simpleflow,
						s.r.o.</a>, {this.props.t('layout.footer.copyright')}
					</div>
					<LanguageSelect/>
				</div>
			</div>
		</div>
	);
}


export const Layout = withTranslation('app')(connect(
	(state: AppState) => ({
		isUserActive: state.user.user?.status === UserStatus.active
	}),
	(dispatch: AppThunkDispatch) => ({})
)(BareLayout));