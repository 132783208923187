import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {RecoveryValidateAction, TYPE_LOADING_RECOVERY, TYPE_VALIDATE_RECOVERY} from './types';
import {RecoveryState, RecoveryStatus} from '../recoveryState';
import {recoveryValidateRequest} from '../../../api/Recovery';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionRecoveryValidate = (recoveryToken: string): AppThunkAction => async (dispatch: AppThunkDispatch) =>
{
	dispatch({type: TYPE_LOADING_RECOVERY, status: RecoveryStatus.validating});
	await recoveryValidateRequest(recoveryToken).then((result: any) =>
	{
		const {payload: {email}, message} = result;

		if (email === null) {

			dispatch({
				type: TYPE_VALIDATE_RECOVERY,
				status: RecoveryStatus.expired,
				email
			});

			sendAlert(AlertType.error, message);

		} else {

			dispatch({
				type: TYPE_VALIDATE_RECOVERY,
				status: RecoveryStatus.prepared,
				email
			});
		}
	});
};

export const reduceRecoveryValidate = (state: RecoveryState, action: RecoveryValidateAction): RecoveryState => ({
	...state,
	...action
});