import {
	SubscriptionCancelStatus, SubscriptionChangePlanStatus,
	SubscriptionCompleteStatus,
	SubscriptionInfoStatus,
	SubscriptionResumeStatus,
	SubscriptionStartStatus
} from '../subscriptionState';
import {SubscriptionInfo, SubscriptionInvoice} from '../../../api/Subscription';


export const TYPE_START = 'subscription/START';
export const TYPE_RESUME = 'subscription/RESUME';
export const TYPE_CANCEL = 'subscription/CANCEL';
export const TYPE_COMPLETE = 'subscription/COMPLETE';
export const TYPE_INFO = 'subscription/INFO';
export const TYPE_CHANGE_PLAN = 'subscription/CHANGE_PLAN';
export const TYPE_UPDATE_CARD = 'subscription/UPDATE_CARD';


export interface StartAction
{
	type: typeof TYPE_START;
	sessionId?: string;
	start?: SubscriptionStartStatus;
}


export interface ResumeAction
{
	type: typeof TYPE_RESUME;
	sessionId?: string;
	resume?: SubscriptionResumeStatus;
	subscription?: SubscriptionInfo;
	invoices?: SubscriptionInvoice[];
}


export interface CancelAction
{
	type: typeof TYPE_CANCEL;
	cancel?: SubscriptionCancelStatus;
	subscription?: SubscriptionInfo;
	invoices?: SubscriptionInvoice[];
}


export interface CompleteAction
{
	type: typeof TYPE_COMPLETE;
	complete?: SubscriptionCompleteStatus;
	sessionId?: string;
}


export interface InfoAction
{
	type: typeof TYPE_INFO;
	info?: SubscriptionInfoStatus;
	subscription?: SubscriptionInfo;
	invoices?: SubscriptionInvoice[];
}


export interface ChangePlanAction
{
	type: typeof TYPE_CHANGE_PLAN;
	plan?: SubscriptionChangePlanStatus;
	subscription?: SubscriptionInfo;
	invoices?: SubscriptionInvoice[];
}

export interface UpdateCardAction
{
	type: typeof TYPE_UPDATE_CARD;
}


export type SubscriptionAction = StartAction | CompleteAction | InfoAction | ResumeAction | CancelAction | ChangePlanAction | UpdateCardAction;