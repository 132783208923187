import {CreatingEvent, eventCreateRequest, eventUploadIconRequest} from '../../../api/Event';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {EventCreateStatus, EventState} from '../eventState';
import {EventCreateAction, TYPE_CREATE} from './types';
import {AlertType, sendAlert} from '../../../components/Alert';
import {ApiErrorResponse} from '../../../api/ApiResponse';


export const actionEventCreate = (event: CreatingEvent, icon?: File, coupon?: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<EventCreateAction>({
		type: TYPE_CREATE,
		creating: EventCreateStatus.loading,
		sessionId: undefined
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	const error = (result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<EventCreateAction>({
			type: TYPE_CREATE,
			creating: EventCreateStatus.failed,
			sessionId: undefined
		});

		sendAlert(AlertType.error, message);
	};

	await eventCreateRequest(accessToken, event, coupon).then((result: any) =>
	{
		const {payload: {event}, message} = result;

		if (!icon) {
			dispatch<EventCreateAction>({
				type: TYPE_CREATE,
				creating: EventCreateStatus.success,
				sessionId: undefined
			});

			sendAlert(AlertType.success, message);
			return;
		}

		dispatch<EventCreateAction>({
			type: TYPE_CREATE,
			creating: EventCreateStatus.uploading
		});

		eventUploadIconRequest(accessToken, event.uid, icon).then(() =>
		{
			dispatch<EventCreateAction>({
				type: TYPE_CREATE,
				creating: EventCreateStatus.success
			});

			sendAlert(AlertType.success, message);

		}).catch(error);

	}).catch(error);
};

export const reduceEventCreate = (state: EventState, action: EventCreateAction): EventState => ({
	...state,
	...action
});