import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {DeviceRemoveStatus, DeviceState} from '../deviceState';
import {RemoveAction, TYPE_REMOVE} from './types';
import {deviceRemoveRequest} from '../../../api/Device';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionRemoveDevice = (uid: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch({
		type: TYPE_REMOVE,
		remove: DeviceRemoveStatus.removing
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await deviceRemoveRequest(accessToken, uid).then((result: any) =>
	{
		const {message} = result;
		sendAlert(AlertType.info, message);

		dispatch({
			type: TYPE_REMOVE,
			remove: DeviceRemoveStatus.prepared
		});
	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch({
			type: TYPE_REMOVE,
			remove: DeviceRemoveStatus.failed
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceRemoveDevice = (state: DeviceState, action: RemoveAction): DeviceState => ({
	...state,
	...action
});