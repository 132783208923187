import {RecoveryStatus} from '../recoveryState';


export const TYPE_VALIDATE_RECOVERY = 'recovery/VALIDATE';
export const TYPE_PROCESS_RECOVERY = 'recovery/PROCESS';
export const TYPE_LOADING_RECOVERY = 'recovery/LOADING';


export interface RecoveryValidateAction
{
	type: typeof TYPE_VALIDATE_RECOVERY;
	status?: RecoveryStatus;
	email?: string;
}


export interface RecoveryProcessAction
{
	type: typeof TYPE_PROCESS_RECOVERY;
	status?: RecoveryStatus;
}


export interface RecoveryLoadingAction
{
	type: typeof TYPE_LOADING_RECOVERY;
	status?: RecoveryStatus;
}


export type RecoveryAction =
	RecoveryValidateAction |
	RecoveryLoadingAction |
	RecoveryProcessAction;