import React from 'react';
import {EventCreateStatus} from '../../model/event/eventState';
import {Event, EventStatus, UpdatingEvent} from '../../api/Event';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {actionEventUpdate} from '../../model/event/actions/UpdateAction';
import {Button, InputAdornment, TextField} from '@material-ui/core';
import {actionEventDetail} from '../../model/event/actions/DetailAction';
import {EmailRounded, OpenInNewRounded, DnsRounded} from '@material-ui/icons';
import {DNSDialog} from '../dialog/DNSDialog';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	status: EventCreateStatus;
	event: Event;
}


interface DispatchProps
{
	update: (uid: string, event: UpdatingEvent) => void;
	reload: (uid: string) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	isDnsOpened: boolean;
	contactEmail?: string;
}


class BareEventDomainForm extends React.Component<Props, State>
{

	public state: State = {
		contactEmail: undefined,
		isDnsOpened: false
	};


	public componentDidMount(): void
	{
		const {sharedInfo: {contactEmail}} = this.props.event;
		const components = contactEmail.split('@');
		this.setState({contactEmail: components[0]});
	}


	protected onEmailChanged = (contactEmail: string) => this.setState({contactEmail});

	protected isFormValid = (): boolean =>
	{
		return !!this.state.contactEmail && this.state.contactEmail.length > 0;
	};

	protected save = (e: any) =>
	{
		e.preventDefault();
		if (!this.isFormValid()) {
			return;
		}

		let {contactEmail} = this.state;
		this.props.update(this.props.event.uid, {
			contactEmail: `${contactEmail}@${this.props.event.domain}`
		});
	};

	public render = () =>
	{
		const {event, status} = this.props;
		const hasError = status === EventCreateStatus.failed;
		const isSaving = status === EventCreateStatus.loading;

		let url = `${event.key}.event.simpleflow.io`;
		if (event.status === EventStatus.running) {
			url = `${event.domain}`;
		}

		return (
			<form className='Form Form--full' onSubmit={this.save}>
				<div className='Form__column Form__column--full'>
					<div className='Form__group'>
						<h2>{this.props.t('events.edit.domain.title')}</h2>
						<p className='DomainName'>
							<a href={`http://${url}`} target='_blank' rel='noopener noreferrer'>
								{url}
								<OpenInNewRounded fontSize={'inherit'}/>
							</a>
						</p>
						{event.status === EventStatus.running && (
							<span style={{paddingTop: 4, paddingLeft: 16}} className={`Text--${event.status}`}>
								{this.props.t('events.edit.domain.correct')}
							</span>
						)}
						{event.status === EventStatus.dns && (
							<>
								<p className={`Text--${event.status}`}>
									{this.props.t('events.edit.domain.dnsFirst')}&nbsp;
									<a href={`http://${event.domain}`} target='_blank' rel='noopener noreferrer'>
										{event.domain}&nbsp;
										<OpenInNewRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
									</a>&nbsp;
									{this.props.t('events.edit.domain.dnsSecond')}&nbsp;
									<a href={'#dns'} onClick={(e) =>
									{
										e.preventDefault();
										this.setState({isDnsOpened: true});
									}}>{this.props.t('events.edit.domain.dnsSetup')}</a>
								</p>
								<Button
									onClick={() => this.props.reload(event.uid)}
									startIcon={(<DnsRounded/>)}
									className='Button'
									variant="outlined"
									size="small"
									style={{marginTop: 0}}
									color="primary">
									{this.props.t('events.edit.domain.check')}
								</Button>
							</>
						)}
						{event.status === EventStatus.paused && (
							<p className={`Text--${event.status}`}>
								{this.props.t('events.edit.domain.paused')}&nbsp;
								<a href='mailto:hello@simpleflow.io'>
									<strong>
										{this.props.t('events.edit.domain.writeUs')}&nbsp;
										<EmailRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
									</strong>
								</a>.
							</p>
						)}
						{event.status === EventStatus.running && (
							<p>
								{this.props.t('events.edit.domain.runningSecond')}&nbsp;
								<a href='mailto:hello@simpleflow.io'>
									<strong>
										{this.props.t('events.edit.domain.writeUs')}&nbsp;
										<EmailRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
									</strong>
								</a>.
							</p>
						)}
						<h2>{this.props.t('events.edit.contact.title')}</h2>
						<p>{this.props.t('events.edit.contact.message')}</p>
						<div className='Form__item'>
							<TextField
								disabled={isSaving}
								error={hasError}
								required={true}
								value={this.state.contactEmail || ''}
								onChange={(e) => this.onEmailChanged(e.target.value)}
								className='TextField'
								label={this.props.t('events.edit.contact.email')}
								variant="outlined"
								fullWidth={true}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end" className='TextField__endAdornment'>
											<p>@{event.domain}</p>
										</InputAdornment>
									)
								}}
							/>
						</div>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<Button
						disabled={!this.isFormValid() || isSaving}
						onSubmit={this.save}
						type='submit'
						className='Button'
						variant="contained"
						size="large"
						color="secondary">
						{isSaving ? this.props.t('events.edit.contact.update.updating') : this.props.t('events.edit.contact.update.default')}
					</Button>
				</div>
				{this.state.isDnsOpened && <DNSDialog domain={event.domain} onClose={() => this.setState({isDnsOpened: false})}/>}
			</form>
		);
	};
}


export const EventDomainForm = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.events.updating
	}),
	(dispatch: AppThunkDispatch) => ({
		update: (uid: string, event: UpdatingEvent) => dispatch(actionEventUpdate(uid, event)).then(() => dispatch(actionEventDetail(uid))),
		reload: (uid: string) => dispatch(actionEventDetail(uid))
	})
)(BareEventDomainForm));