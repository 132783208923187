import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {LogoutAction, TYPE_LOGOUT} from './types';
import {LoginStatus, UserState} from '../userState';
import {userRevokeRequest} from '../../../api/User';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionLogout: AppThunkAction = async (dispatch: AppThunkDispatch) =>
{
	const accessToken = localStorage.getItem('accessToken');
	if (!accessToken) {
		return;
	}

	await userRevokeRequest(accessToken).then((result: any) =>
	{
		const {message} = result;
		sendAlert(AlertType.info, message);
	});

	localStorage.removeItem('accessToken');

	dispatch({
		type: TYPE_LOGOUT,
		login: LoginStatus.prepared,
		user: undefined,
		device: undefined
	});
};

export const reduceLogout = (state: UserState, action: LogoutAction): UserState => ({
	...state,
	...action
});