import {eventRemoveRequest} from '../../../api/Event';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {EventRemoveAction, TYPE_REMOVE} from './types';
import {EventRemoveStatus, EventState} from '../eventState';
import {AlertType, sendAlert} from '../../../components/Alert';
import {ApiErrorResponse} from '../../../api/ApiResponse';


export const actionEventRemove = (uid: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<EventRemoveAction>({
		type: TYPE_REMOVE,
		remove: EventRemoveStatus.removing
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	const error = (result: any) =>
	{
		dispatch<EventRemoveAction>({
			type: TYPE_REMOVE,
			remove: EventRemoveStatus.prepared
		});

		const response: ApiErrorResponse = result.response.data;
		const {message} = response;
		sendAlert(AlertType.error, message);
	};

	await eventRemoveRequest(accessToken, uid).then((result: any) =>
	{
		const {message} = result;

		dispatch<EventRemoveAction>({
			type: TYPE_REMOVE,
			remove: EventRemoveStatus.removed
		});

		sendAlert(AlertType.info, message);

	}).catch(error);
};

export const reduceEventRemove = (state: EventState, action: EventRemoveAction): EventState => ({
	...state,
	...action
});