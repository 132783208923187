import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Button, TextField} from '@material-ui/core';
import SelectField from '../form/SelectField';
import {Billing} from '../../api/Billing';
import {getNames} from 'country-list';
import {actionBillingSaveAction} from '../../model/user/actions/BillingSaveAction';
import {UserSaveStatus} from '../../model/user/userState';
import CheckboxField from '../form/CheckboxField';
import {withTranslation, WithTranslation} from 'react-i18next';


interface StateProps
{
	simplified: boolean;
	saveLabel: string;
	savingLabel: string;
	status: UserSaveStatus;
	billing?: Billing;
	compositeName: string;
}


interface DispatchProps
{
	save: (billing: Billing) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	billing: Billing;
	isCompanyCustomer: boolean;
}


class BareBillingForm extends React.Component<Props, State>
{

	public state = {
		isCompanyCustomer: false,
		billing: this.props.billing || {
			compositeName: this.props.compositeName,
			addressOne: '',
			addressTwo: '',
			city: '',
			country: '',
			zipCode: '',
			companyName: '',
			crn: '',
			vat: ''
		}
	};


	public componentDidMount(): void
	{
		const {billing} = this.props;
		if (!billing) {
			return;
		}

		const isCompanyCustomer = billing?.companyName.length > 0 && billing.crn.length > 0 && billing.vat.length > 0;
		this.setState({isCompanyCustomer});
	}


	protected onFullNameChanged = (compositeName: string) => this.setState({billing: {...this.state.billing, compositeName}});
	protected onAddressOneChanged = (addressOne: string) => this.setState({billing: {...this.state.billing, addressOne}});
	protected onAddressTwoChanged = (addressTwo: string) => this.setState({billing: {...this.state.billing, addressTwo}});
	protected onCityChanged = (city: string) => this.setState({billing: {...this.state.billing, city}});
	protected onCountryChanged = (country: string) => this.setState({billing: {...this.state.billing, country}});
	protected onZipCodeChanged = (zipCode: string) => this.setState({billing: {...this.state.billing, zipCode}});
	protected onCompanyNameChanged = (companyName: string) => this.setState({billing: {...this.state.billing, companyName}});
	protected onCrnChanged = (crn: string) => this.setState({billing: {...this.state.billing, crn}});
	protected onVatChanged = (vat: string) => this.setState({billing: {...this.state.billing, vat}});
	protected onCompanyCustomerChanged = (isCompanyCustomer: boolean) => this.setState({
		isCompanyCustomer,
		billing: {
			...this.state.billing,
			companyName: isCompanyCustomer ? this.state.billing.companyName : '',
			crn: isCompanyCustomer ? this.state.billing.crn : '',
			vat: isCompanyCustomer ? this.state.billing.vat : '',
		}
	});

	protected isFormValid = (): boolean =>
	{
		let isValid = this.state.billing.compositeName.length > 0 &&
			this.state.billing.addressOne.length > 0 &&
			this.state.billing.city.length > 0 &&
			this.state.billing.country.length > 0 &&
			this.state.billing.zipCode.length > 0;

		const {companyName, crn, vat} = this.state.billing;
		if (this.state.isCompanyCustomer && (companyName.length === 0 || crn.length === 0 || vat.length === 0)) {
			isValid = false;
		}

		return isValid;
	};

	protected save = (event: any) =>
	{
		event.preventDefault();
		if (!this.isFormValid()) {
			return;
		}

		this.props.save(this.state.billing);
	};

	public render = () =>
	{
		const hasError = this.props.status === UserSaveStatus.failed;
		const isSaving = this.props.status === UserSaveStatus.saving;

		return (
			<form className={`Form ${this.props.simplified ? 'Form--centered' : 'Form--full'}`} onSubmit={this.save}>
				<div className='Form__column Form__column--full'>
					<div className='Form__group'>
						<div className='Form__item'>
							<TextField
								required={true}
								error={hasError}
								disabled={isSaving}
								value={this.state.billing.compositeName}
								onChange={(e) => this.onFullNameChanged(e.target.value)}
								className='TextField'
								label={this.props.t('billing.name')}
								variant="outlined"
								fullWidth={true}
							/>
						</div>
					</div>
				</div>
				<div className='Form__column'>
					<div className='Form__group'>
						<div className='Form__item'>
							<TextField
								required={true}
								error={hasError}
								disabled={isSaving}
								className='TextField'
								label={this.props.t('billing.addressOne')}
								variant="outlined"
								fullWidth={true}
								value={this.state.billing.addressOne}
								onChange={(e) => this.onAddressOneChanged(e.target.value)}
							/>
						</div>
						<div className='Form__item'>
							<TextField
								error={hasError}
								disabled={isSaving}
								className='TextField'
								label={this.props.t('billing.addressTwo')}
								variant="outlined"
								fullWidth={true}
								value={this.state.billing.addressTwo}
								onChange={(e) => this.onAddressTwoChanged(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className='Form__column'>
					<div className='Form__group'>
						<div className='Form__item'>
							<TextField
								required={true}
								error={hasError}
								disabled={isSaving}
								className='TextField'
								label={this.props.t('billing.city')}
								variant="outlined"
								fullWidth={true}
								value={this.state.billing.city}
								onChange={(e) => this.onCityChanged(e.target.value)}
							/>
						</div>
						<div className='Form__item Form__item--state'>
							<div className='Form__segment Form__segment--medium'>
								<SelectField
									disabled={isSaving}
									error={hasError}
									required={true}
									onChange={this.onCountryChanged}
									value={this.state.billing.country}
									label={this.props.t('billing.country')}
									options={getNames()}
								/>
							</div>
							<div className='Form__segment Form__segment--small'>
								<TextField
									type='number'
									required={true}
									error={hasError}
									disabled={isSaving}
									className='TextField TextField--hideNumbers'
									label={this.props.t('billing.zip')}
									variant="outlined"
									fullWidth={true}
									value={this.state.billing.zipCode}
									onChange={(e) => this.onZipCodeChanged(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					<div className='Form__item'>
						<CheckboxField
							disabled={isSaving}
							label={this.props.t('billing.company.check')}
							isChecked={this.state.isCompanyCustomer}
							onChange={this.onCompanyCustomerChanged}
						/>
					</div>
				</div>
				<div className='Form__column Form__column--full'>
					{this.state.isCompanyCustomer && (
						<div className='Form__group'>
							<div className='Form__item'>
								<TextField
									required={true}
									error={hasError}
									disabled={isSaving}
									className='TextField'
									label={this.props.t('billing.company.name')}
									variant="outlined"
									fullWidth={true}
									value={this.state.billing.companyName}
									onChange={(e) => this.onCompanyNameChanged(e.target.value)}
								/>
							</div>
							<div className='Form__item Form__item--state'>
								<div className='Form__segment Form__segment--half'>
									<TextField
										required={true}
										error={hasError}
										disabled={isSaving}
										className='TextField'
										label={this.props.t('billing.company.crn')}
										variant="outlined"
										fullWidth={true}
										value={this.state.billing.crn}
										onChange={(e) => this.onCrnChanged(e.target.value)}
									/>
								</div>
								<div className='Form__segment Form__segment--half'>
									<TextField
										required={true}
										error={hasError}
										disabled={isSaving}
										className='TextField'
										label={this.props.t('billing.company.vat')}
										variant="outlined"
										fullWidth={true}
										value={this.state.billing.vat}
										onChange={(e) => this.onVatChanged(e.target.value)}
									/>
								</div>
							</div>
						</div>
					)}
					<Button
						disabled={!this.isFormValid() || isSaving}
						onSubmit={this.save}
						type='submit'
						className='Button'
						variant="contained"
						size="large"
						color="secondary">
						{isSaving ? this.props.savingLabel : this.props.saveLabel}
					</Button>
				</div>
			</form>
		);
	};
}


export const BillingForm = withTranslation('app')(connect(
	(state: AppState) => ({
		billing: state.user.billing,
		compositeName: state.user.user?.compositeName ?? '',
		status: state.user.save ?? UserSaveStatus.saved
	}),
	(dispatch: AppThunkDispatch) => ({
		save: (billing: Billing) => dispatch(actionBillingSaveAction(billing))
	})
)(BareBillingForm));