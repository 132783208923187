import {subscriptionCancelRequest} from '../../../api/Subscription';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {CancelAction, TYPE_CANCEL} from './types';
import {SubscriptionCancelStatus, SubscriptionState} from '../subscriptionState';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionSubscriptionCancel = (): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<CancelAction>({
		type: TYPE_CANCEL,
		cancel: SubscriptionCancelStatus.loading
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await subscriptionCancelRequest(accessToken).then((result: any) =>
	{
		const {payload, message} = result;
		sendAlert(AlertType.info, message);

		dispatch<CancelAction>({
			type: TYPE_CANCEL,
			cancel: SubscriptionCancelStatus.canceled,
			subscription: payload.info,
			invoices: payload.invoices
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<CancelAction>({
			type: TYPE_CANCEL,
			cancel: SubscriptionCancelStatus.prepared
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceSubscriptionCancel = (state: SubscriptionState, action: CancelAction): SubscriptionState => ({
	...state,
	...action
});