import React from 'react';
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {withGeneratedId} from '../../utils/htmlId';


interface Props
{
	notched?: boolean;
	placeholder?: string;
	required?: boolean;
	error?: boolean;
	disabled?: boolean;
	label: string;
	value: string;
	autoComplete?: string;
	onChange: (value: string) => void;
}


interface State
{
	isProtected: boolean;
}


class PasswordField extends React.Component<Props, State>
{

	public state = {
		isProtected: true
	};

	protected toggle = () =>
	{
		this.setState({isProtected: !this.state.isProtected});
	};

	public render = () => (
		<FormControl fullWidth={true} className='TextField' variant="outlined" required={this.props.required} error={this.props.error}>
			{withGeneratedId((id: string) => <>
				<InputLabel shrink={this.props.notched} htmlFor={id}>{this.props.label}</InputLabel>
				<OutlinedInput
					notched={this.props.notched}
					id={id}
					placeholder={this.props.placeholder}
					required={this.props.required}
					onChange={(e) => this.props.onChange(e.target.value)}
					value={this.props.value}
					disabled={this.props.disabled}
					type={this.state.isProtected ? 'password' : 'text'}
					label={this.props.label + (this.props.required ? ' *' : '')}
					endAdornment={
						<InputAdornment position="end">
							<IconButton onClick={this.toggle} edge="end" tabIndex={-1}>
								{this.state.isProtected ? <VisibilityOff/> : <Visibility/>}
							</IconButton>
						</InputAdornment>
					}
					autoComplete={this.props.autoComplete}
				/>
			</>)}
		</FormControl>
	);
}


export default PasswordField;