import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {
	Button,
	Chip,
	Dialog,
	DialogContent,
	DialogContentText,
	FormControlLabel,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField
} from '@material-ui/core';
import SelectField from '../form/SelectField';
import {
	CreatingEvent,
	EventLanguageOptions,
	EventPaymentMethod,
	getEventLanguage,
	getEventLanguageCode
} from '../../api/Event';
import PasswordField from '../form/PasswordField';
import {PasswordChecker} from '../form/PasswordChecker';
import CheckboxField from '../form/CheckboxField';
import {generateHtmlId} from '../../utils/htmlId';
import {UploadField} from '../form/UploadField';
import {LockRounded, OpenInNewRounded} from '@material-ui/icons';
import {Module} from '../../api/Module';
import {EventCreateStatus} from '../../model/event/eventState';
import {actionEventCreate} from '../../model/event/actions/CreateAction';
import {DNSDialog} from '../dialog/DNSDialog';
import {User} from '../../api/User';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import {EVENTS_LIST} from '../../routes';
import {CouponField} from "../form/CouponField";
import {actionSyncUser} from "../../model/user/actions/SyncAction";
import {actionEventListAction} from "../../model/event/actions/ListAction";


interface StateProps
{
	simplified: boolean;
	modules: Module[];
	status: EventCreateStatus;
	user?: User;
}


interface DispatchProps
{
	create: (event: CreatingEvent, simplified: boolean, icon?: File, coupon?: string) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	isConfirming: boolean;
	isDnsOpened: boolean;
	event: CreatingEvent;
	coupon?: string;
	file?: File;
}


class BareEventForm extends React.Component<Props, State>
{

	public state: State = {
		isConfirming: false,
		isDnsOpened: false,
		event: {
			name: '',
			language: getEventLanguageCode(EventLanguageOptions[0]),
			domain: '',
			contactEmail: '',
			adminFirstName: '',
			adminLastName: '',
			adminEmail: '',
			adminPassword: '',
			apiKeyGoogleMaps: '',
			participants: 50,
			modules: ['default'],
			payment: EventPaymentMethod.CHARGE_AUTOMATICALLY
		},
        coupon: undefined,
		file: undefined
	};

	protected onTitleChanged = (name: string) => this.setState({event: {...this.state.event, name}});
	protected onDomainChanged = (domain: string) => this.setState({event: {...this.state.event, domain}});
	protected onLanguageChanged = (language: string) => this.setState({event: {...this.state.event, language}});
	protected onEmailChanged = (contactEmail: string) => this.setState({event: {...this.state.event, contactEmail}});
	protected onAdminChanged = (adminEmail: string) => this.setState({event: {...this.state.event, adminEmail}});
	protected onAdminFirstNameChanged = (adminFirstName: string) => this.setState({event: {...this.state.event, adminFirstName}});
	protected onAdminLastNameChanged = (adminLastName: string) => this.setState({event: {...this.state.event, adminLastName}});
	protected onPasswordChanged = (adminPassword: string) => this.setState({event: {...this.state.event, adminPassword}});
	protected onGoogleMapKeyChanged = (apiKeyGoogleMaps: string) => this.setState({event: {...this.state.event, apiKeyGoogleMaps}});
	protected onParticipantsChanged = (participants: number) => this.setState({event: {...this.state.event, participants}});
	protected onPaymentChange = (payment: EventPaymentMethod) => this.setState({event: {...this.state.event, payment}});
	protected onCouponChanged = (coupon?: string) => this.setState({coupon});
	protected onConfirmClosed = () => this.setState({isConfirming: false});

	protected onModuleChanged = (module: Module, enabled: boolean) =>
	{
		let {modules} = this.state.event;
		if (enabled && !modules.includes(module.key)) {
			modules.push(module.key);
		}

		if (!enabled) {
			modules = modules.filter((key) => key !== module.key);
		}

		this.setState({event: {...this.state.event, modules}});
	};

	protected getModulePrice = (): number =>
	{
		let price = 0;
		this.props.modules.forEach((module) =>
		{
			if (this.state.event.modules.includes(module.key)) {
				price += module.pricePerParticipant;
			}
		});

		return Math.round((price + Number.EPSILON) * 10) / 10;
	};

	protected getTotalPrice = (): number =>
	{
		let price = 0;
		this.props.modules.forEach((module) =>
		{
			if (this.state.event.modules.includes(module.key)) {
				price += module.pricePerParticipant;
			}
		});

		const result = Math.round((price + Number.EPSILON) * 10) / 10;
		return result * this.state.event.participants;
	};

	protected isFormValid = (): boolean =>
	{
		const isValid = this.state.event.name.length > 0 &&
			this.state.event.language.length > 0 &&
			this.state.event.domain.length > 0 &&
			this.state.event.contactEmail.length > 0 &&
			this.state.event.adminEmail.length > 0 &&
			this.state.event.adminPassword.length > 0 &&
			this.state.event.modules.length > 0 &&
			this.state.event.modules.includes('default') &&
			this.state.event.participants > 0;

		if (isValid) {
			const domain = new RegExp('^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$');
			if (!domain.test(this.state.event.domain) || !this.state.event.domain.includes('.')) {
				return false;
			}
		}

		return isValid;
	};

	protected confirm = (e: any) =>
	{
		e.preventDefault();
		if (!this.isFormValid()) {
			return;
		}

		this.setState({isConfirming: true});
	};

	protected save = () =>
	{
		if (!this.isFormValid()) {
			return;
		}

		this.setState({isConfirming: false}, () =>
		{
			const {simplified} = this.props;
			const {event, file, coupon} = this.state;

			const _event = Object.assign({}, event);
			_event.contactEmail += `@${_event.domain}`;
			_event.apiKeyGoogleMaps = _event.apiKeyGoogleMaps || undefined;

			this.props.create(_event, simplified, file, coupon);
		});
	};


	public componentDidMount()
	{
		const {user} = this.props;
		this.setState({
			event: {
				...this.state.event,
				adminFirstName: user?.givenName || '',
				adminLastName: user?.familyName || '',
				adminEmail: user?.email || '',
				payment: EventPaymentMethod.CHARGE_AUTOMATICALLY
			},
			coupon: undefined
		});
	}


	public render = () =>
	{
		const hasError = this.props.status === EventCreateStatus.failed;
		const isCreating = this.props.status === EventCreateStatus.loading || this.props.status === EventCreateStatus.uploading || this.props.status === EventCreateStatus.success;

		if (this.props.status === EventCreateStatus.success) {
			return (<Redirect to={EVENTS_LIST}/>);
		}

		return (
			<>
				<form className={`Form ${this.props.simplified ? 'Form--centered Form--wide' : 'Form--full'}`} onSubmit={this.confirm}
					  autoComplete={generateHtmlId()}>
					<div className='Form__column Form__column--full'>
						<h2>{this.props.t('events.create.general.title')}</h2>
						<p dangerouslySetInnerHTML={{__html: this.props.t('events.create.general.message')}}/>
						<div className='Form__group'>
							<div className='Form__item Form__item--state'>
								<div className='Form__segment Form__segment--medium'>
									<TextField
										disabled={isCreating}
										error={hasError}
										required={true}
										value={this.state.event.name}
										onChange={(e) => this.onTitleChanged(e.target.value)}
										className='TextField'
										label={this.props.t('events.create.general.name')}
										variant="outlined"
										fullWidth={true}
										autoFocus={true}
									/>
								</div>
								<div className='Form__segment Form__segment--small'>
									<SelectField
										disabled={isCreating}
										error={hasError}
										required={true}
										onChange={(language: string) => this.onLanguageChanged(getEventLanguageCode(language))}
										value={getEventLanguage(this.state.event.language)}
										label={this.props.t('events.create.general.language')}
										options={EventLanguageOptions}
									/>
								</div>
							</div>
							<div className='Form__item' style={{paddingBottom: 16}}>
								<UploadField
									disabled={isCreating}
									tabIndex={1}
									label={this.props.t('events.create.general.icon')}
									onChange={(file) => this.setState({file})}
								/>
							</div>
						</div>
						<h2>{this.props.t('events.create.domain.title')}</h2>
						<p>{this.props.t('events.create.domain.message')}</p>
						<p>{this.props.t('events.create.domain.dns.message')} <a href={'#dns'} onClick={(e) =>
						{
							e.preventDefault();
							this.setState({isDnsOpened: true});
						}}><strong>{this.props.t('events.create.domain.dns.setup')}</strong></a>
						</p>
						<div className='Form__group'>
							<div className='Form__item'>
								<TextField
									disabled={isCreating}
									error={hasError}
									required={true}
									value={this.state.event.domain}
									onChange={(e) => this.onDomainChanged(e.target.value)}
									className='TextField TextField--leftOffset'
									label={this.props.t('events.create.domain.domain')}
									variant="outlined"
									fullWidth={true}
									InputProps={{
										startAdornment: (
											<InputAdornment className='TextField__startAdornment' position="start">
												<p>www.</p>
											</InputAdornment>
										)
									}}
								/>
							</div>
							<p>{this.props.t('events.create.domain.contact.message')}</p>
							<div className='Form__item'>
								<TextField
									disabled={isCreating}
									error={hasError}
									required={true}
									value={this.state.event.contactEmail}
									onChange={(e) => this.onEmailChanged(e.target.value)}
									className='TextField'
									label={this.props.t('events.create.domain.contact.email')}
									variant="outlined"
									fullWidth={true}
									InputProps={{
										endAdornment: this.state.event.domain.length > 0 && (
											<InputAdornment position="end" className='TextField__endAdornment'>
												<p>@{this.state.event.domain}</p>
											</InputAdornment>
										)
									}}
								/>
							</div>
						</div>
						<h2>{this.props.t('events.create.administrator.title')}</h2>
						<p>{this.props.t('events.create.administrator.message')}</p>
						<div className='Form__group'>
							<div className='Form__item'>
								<div className='Form__segment Form__segment--half'>
									<TextField
										disabled={isCreating}
										error={hasError}
										required={true}
										value={this.state.event.adminFirstName}
										onChange={(e) => this.onAdminFirstNameChanged(e.target.value)}
										className='TextField'
										label={this.props.t('events.create.administrator.firstName')}
										variant="outlined"
										fullWidth={true}
										autoComplete={generateHtmlId()}
									/>
								</div>
								<div className='Form__segment Form__segment--half'>
									<TextField
										disabled={isCreating}
										error={hasError}
										required={true}
										value={this.state.event.adminLastName}
										onChange={(e) => this.onAdminLastNameChanged(e.target.value)}
										className='TextField'
										label={this.props.t('events.create.administrator.lastName')}
										variant="outlined"
										fullWidth={true}
										autoComplete={generateHtmlId()}
									/>
								</div>
							</div>
							<div className='Form__item'>
								<TextField
									disabled={isCreating}
									error={hasError}
									type={'email'}
									required={true}
									value={this.state.event.adminEmail}
									onChange={(e) => this.onAdminChanged(e.target.value)}
									className='TextField'
									label={this.props.t('events.create.administrator.email')}
									variant="outlined"
									fullWidth={true}
									autoComplete={generateHtmlId()}
								/>
							</div>
							<div className='Form__item'>
								<PasswordField
									disabled={isCreating}
									error={hasError}
									required={true}
									label={this.props.t('events.create.administrator.password')}
									value={this.state.event.adminPassword}
									onChange={this.onPasswordChanged}
									autoComplete={generateHtmlId()}
								/>
								<PasswordChecker password={this.state.event.adminPassword}/>
							</div>
						</div>
						<h2>{this.props.t('events.create.modules.title')}</h2>
						<p>
							{this.props.t('events.create.modules.message.text')}&nbsp;
							<a href={'https://www.simpleflow.io/pricing/'} target={'_blank'} rel='noopener noreferrer'>
								<strong>
									{this.props.t('events.create.modules.message.pricing')}&nbsp;
									<OpenInNewRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
								</strong>
							</a>
						</p>
						<div className='Form__column Form__column--full Form__moduleList'>
							{this.props.modules.map((module, key) => (
								<div key={key} className='Form__item Form__module'>
									<CheckboxField
										disabled={isCreating || module.isDefault}
										label={(
											<div className='Form__moduleInfo'>
												<div className='Form__moduleName'>
													{module.name}
													&nbsp;
													{!module.isPublic && (
														<LockRounded style={{position: 'relative', top: 2}} color={'disabled'} fontSize={'inherit'}/>
													)}
												</div>
												<div className='Form__modulePrice'>
													{module.pricePerParticipant.toFixed(2)} EUR
												</div>
												<p>{module.description}</p>
											</div>
										)}
										isChecked={this.state.event.modules.includes(module.key)}
										onChange={(enabled) => this.onModuleChanged(module, enabled)}
									/>
								</div>
							))}
							<div className='Form__item Form__module Form__module--total'>
								<p>{this.props.t('events.create.modules.price')}</p>
								<div className='Form__modulePrice'>{this.getModulePrice().toFixed(2)} EUR</div>
							</div>
						</div>
						<h2>{this.props.t('events.create.participants.title')}</h2>
						<p>{this.props.t('events.create.participants.message')}</p>
						<div className='Form__group'>
							<div className='Form__item'>
								<div className='Form__segment Form__segment--half' style={{paddingLeft: 0}}>
									<TextField
										disabled={isCreating}
										error={hasError}
										type={'number'}
										required={true}
										value={this.state.event.participants}
										onChange={(e) =>
										{
											const value = parseInt(e.target.value ? e.target.value : '0', 10);
											this.onParticipantsChanged(value < 0 ? 0 : value);
										}}
										className='TextField'
										aria-valuemin={1}
										label={this.props.t('events.create.participants.number')}
										variant="outlined"
										fullWidth={true}
										autoComplete={generateHtmlId()}
									/>
								</div>
							</div>
						</div>
						<h2 dangerouslySetInnerHTML={{__html: this.props.t('events.create.api.title')}}/>
						<p>
							{this.props.t('events.create.api.message')}&nbsp;
							<a href={'https://developers.google.com/maps/documentation/javascript/get-api-key'} target='_blank'
							   rel='noreferrer noopener'>
								<strong>
									{this.props.t('events.create.api.obtain')}&nbsp;
									<OpenInNewRounded style={{position: 'relative', top: 3}} fontSize={'inherit'}/>
								</strong>
							</a>.
						</p>
						<div className='Form__group'>
							<div className='Form__item'>
								<PasswordField
									disabled={isCreating}
									error={hasError}
									required={false}
									label={this.props.t('events.create.api.key')}
									value={this.state.event.apiKeyGoogleMaps || ''}
									onChange={this.onGoogleMapKeyChanged}
									autoComplete={generateHtmlId()}
								/>
							</div>
						</div>
					</div>
					<div className={`Form__column Form__column--full ${this.props.simplified ? 'Form__column--centerOnWide' : ''}`}>
						<Button
							disabled={!this.isFormValid() || isCreating}
							onSubmit={this.confirm}
							type='submit'
							className='Button'
							variant="contained"
							size="large"
							color="secondary">
							{!isCreating && this.props.t('events.create.submit.default', {price: this.getTotalPrice()})}
							{this.props.status === EventCreateStatus.loading && this.props.t('events.create.submit.creating')}
							{this.props.status === EventCreateStatus.uploading && this.props.t('events.create.submit.uploading')}
						</Button>
					</div>
				</form>
				{this.state.isDnsOpened &&
                <DNSDialog domain={this.state.event.domain || 'yourdomain.com'} onClose={() => this.setState({isDnsOpened: false})}/>}
				{this.state.isConfirming && (
					<Dialog
						disableBackdropClick={false}
						disableEscapeKeyDown={false}
						className='Dialog'
						maxWidth={'md'}
						fullWidth
						open={true}
						onClose={this.onConfirmClosed}>
						<h2>{this.props.t('events.create.confirm.title')}</h2>
						<DialogContent>
							<DialogContentText>{this.props.t('events.create.confirm.message')}</DialogContentText>
							<div className='Dialog__box'>
								<h4>{this.props.t('events.create.confirm.name.label')}</h4>
								<p dangerouslySetInnerHTML={{
									__html: this.props.t('events.create.confirm.name.value', {
										name: `<strong class='Text--primary'>${this.state.event.name}</strong>`,
										language: `<strong class='Text--primary'>${getEventLanguage(this.state.event.language)}</strong>`
									})
								}}/>
								<h4>{this.props.t('events.create.confirm.admin.label')}</h4>
								<p><strong className='Text--primary'>{this.state.event.adminEmail}</strong></p>
								<h4>{this.props.t('events.create.confirm.api.label')}</h4>
								{this.state.event.apiKeyGoogleMaps ? (
									<div className='Chips'>
										<Chip
											color={'primary'}
											label={this.props.t('events.create.confirm.api.key')}
											variant="outlined"
										/>
									</div>
								) : (
									<p style={{marginBottom: 24}}>
										{this.props.t('events.create.confirm.api.empty')}
									</p>
								)}
							</div>
							<div className='Dialog__box'>
								<h4>{this.props.t('events.create.confirm.domain.label')}</h4>
								<p dangerouslySetInnerHTML={{
									__html: this.props.t('events.create.confirm.domain.value', {
										domain: `<strong class='Text--primary'>${this.state.event.domain}</strong>`,
										email: `<strong class='Text--primary'>${this.state.event.contactEmail}@${this.state.event.domain}</strong>`
									})
								}}/>
								<h4>{this.props.t('events.create.confirm.modules.label')}</h4>
								<div className='Chips'>
									{this.state.event.modules.map((moduleKey, key) =>
									{
										const module = this.props.modules.find((m) => m.key === moduleKey);
										return (<Chip key={key} color={'primary'} label={module ? module.name.toUpperCase() : ''} variant="outlined"/>);
									})}
								</div>
							</div>
							<div className='clearfix'/>
							<h4>{this.props.t('events.create.confirm.price.label')}</h4>
							<div style={{marginBottom: 24}}>
								<div className='Balance' style={{marginTop: 4}}>{this.getTotalPrice()} EUR</div>
								<p
									style={{paddingTop: 13}}
									dangerouslySetInnerHTML={{
										__html: this.props.t('events.create.confirm.price.participant', {
											price: `<strong>${this.getModulePrice()} EUR</strong>`
										})
									}}
								/>
								<div style={{clear: 'both'}}/>
                                <CouponField label={this.props.t('events.create.confirm.payment.coupon')} onChange={this.onCouponChanged}/>
                                <RadioGroup
                                    value={this.state.event.payment}
                                    onChange={(e) => this.onPaymentChange(e.target.value as EventPaymentMethod)}>
                                    <FormControlLabel
                                        value={EventPaymentMethod.CHARGE_AUTOMATICALLY}
                                        control={<Radio color={'primary'}/>}
                                        label={this.props.t('events.create.confirm.payment.chargeAutomatically')}
                                    />
                                    <FormControlLabel
                                        value={EventPaymentMethod.SEND_INVOICE}
                                        control={<Radio color={'primary'}/>}
                                        label={this.props.t('events.create.confirm.payment.sendInvoice')}
                                    />
                                </RadioGroup>
							</div>
							<Button
								disabled={!this.isFormValid() || isCreating}
								onClick={this.save}
								className='Dialog__primary'
								variant="contained"
								color="secondary"
								size="large">
								{!isCreating && this.state.event.payment === EventPaymentMethod.CHARGE_AUTOMATICALLY && this.props.t('events.create.confirm.submit.pay')}
								{!isCreating && this.state.event.payment === EventPaymentMethod.SEND_INVOICE && this.props.t('events.create.confirm.submit.send')}
								{this.props.status === EventCreateStatus.loading && this.props.t('events.create.confirm.submit.creating')}
								{this.props.status === EventCreateStatus.uploading && this.props.t('events.create.confirm.submit.uploading')}
							</Button>
							<Button
								className='Dialog__cancel'
								onClick={this.onConfirmClosed}
								size="large"
								variant='outlined'
								color="primary">
								{this.props.t('events.create.confirm.dismiss')}
							</Button>
							<div className='Dialog__spacer'/>
						</DialogContent>
					</Dialog>
				)}
			</>
		);
	};
}


export const EventForm = withTranslation('app')(connect(
	(state: AppState) => ({
		modules: state.user.modules || [],
		status: state.events.creating,
		user: state.user.user
	}),
	(dispatch: AppThunkDispatch) => ({
		create: (event: CreatingEvent, simplified: boolean, icon?: File, coupon?: string) => {
			if (simplified) {
				dispatch(actionEventCreate(event, icon, coupon))
					.then(() => dispatch(actionSyncUser()))
					.then(() => dispatch(actionEventListAction(true)));
			} else {
				dispatch(actionEventCreate(event, icon, coupon)).then(() => dispatch(actionSyncUser()));
			}
		}
	})
)(BareEventForm));