import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {ConfirmAction, TYPE_CONFIRM} from './types';
import {ConfirmationStatus, UserState} from '../userState';
import {userConfirmRequest} from '../../../api/User';


export const actionUserConfirm = (uid: string): AppThunkAction => async (dispatch: AppThunkDispatch) =>
{
	dispatch({
		type: TYPE_CONFIRM,
		confirmation: ConfirmationStatus.loading
	});

	await userConfirmRequest(uid).then(() =>
	{
		dispatch({
			type: TYPE_CONFIRM,
			confirmation: ConfirmationStatus.confirmed
		});

	}).catch(() =>
	{
		dispatch({
			type: TYPE_CONFIRM,
			confirmation: ConfirmationStatus.invalid
		});
	});
};

export const reduceUserConfirm = (state: UserState, action: ConfirmAction): UserState => ({
	...state,
	...action
});
