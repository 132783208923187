import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {UserSaveStatus, UserState} from '../userState';
import {SaveAction, TYPE_SAVE} from './types';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';
import {userSaveRequest} from '../../../api/User';


export const actionUserSaveAction = (email: string, name: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch({
		type: TYPE_SAVE,
		save: UserSaveStatus.saving
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	await userSaveRequest(accessToken, email, name).then((result: any) =>
	{
		const {payload, message} = result;
		sendAlert(AlertType.success, message);

		dispatch({
			type: TYPE_SAVE,
			save: UserSaveStatus.saved,
			...payload
		});

	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch({
			type: TYPE_SAVE,
			save: UserSaveStatus.failed,
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceUserSave = (state: UserState, action: SaveAction): UserState => ({
	...state,
	...action
});