import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {UserStatus} from '../../api/User';
import {Redirect} from 'react-router';
import {EVENTS_LIST, SETTINGS} from '../../routes';
import {SettingsTab} from '../settings/SettingsScreen';


interface StateProps
{
	isOnboardingCompleted: boolean;
}


interface DispatchProps
{

}


type Props = StateProps & DispatchProps;


interface State
{

}


class BareSubscriptionCancelScreen extends React.PureComponent<Props, State>
{

	public state: State = {};

	public render = () =>
	{
		if (this.props.isOnboardingCompleted) {
			return (<Redirect to={`${SETTINGS}/${SettingsTab.subscription}`}/>);
		} else {
			return (<Redirect to={EVENTS_LIST}/>);
		}
	};
}


export const SubscriptionCancelScreen = connect(
	(state: AppState) => ({
		isOnboardingCompleted: state.user.user?.status === UserStatus.active
	}),
	(dispatch: AppThunkDispatch) => ({})
)(BareSubscriptionCancelScreen);