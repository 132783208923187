import axios from 'axios';
import {User} from './User';
import {ApiResponse, getHeaders} from './ApiResponse';


export interface Billing
{
	compositeName: string;
	addressOne: string;
	addressTwo: string | null;
	city: string;
	country: string;
	zipCode: string;
	companyName: string;
	crn: string;
	vat: string;
}


export interface BillingData
{
	user: User;
	billing: Billing;
}


export type BillingApiResponse = ApiResponse<BillingData>;
export type BillingCardApiResponse = ApiResponse<{}>;

export async function billingSaveRequest(accessToken: string, billing: Billing): Promise<BillingApiResponse>
{
	const response = await axios.post<BillingApiResponse>('/api/billing', {...billing}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}

export async function billingUpdateCard(accessToken: string, id: string): Promise<BillingCardApiResponse>
{
	const response = await axios.put<BillingCardApiResponse>('/api/card', {id}, {
		headers: getHeaders(accessToken)
	});

	return response.data;
}