import {EventCreateStatus, EventLoadingStatus, EventPayCompleteStatus, EventPayStatus, EventRemoveStatus} from '../eventState';
import {Event} from '../../../api/Event';


export const TYPE_LIST = 'event/LIST';
export const TYPE_DETAIL = 'event/DETAIL';
export const TYPE_CREATE = 'event/CREATE';
export const TYPE_UPDATE = 'event/UPDATE';
export const TYPE_PAUSE = 'event/PAUSE';
export const TYPE_RESUME = 'event/RESUME';
export const TYPE_REMOVE = 'event/REMOVE';
export const TYPE_PAY = 'event/PAY';
export const TYPE_PAY_EXTRA = 'event/PAY_EXTRA';
export const TYPE_PAY_COMPLETE = 'event/PAY_COMPLETE';


export interface EventListAction
{
	type: typeof TYPE_LIST;
	events?: Event[];
	loading?: EventLoadingStatus;
	creating?: EventCreateStatus;
}


export interface EventDetailAction
{
	type: typeof TYPE_DETAIL;
	event?: Event;
	detail?: EventLoadingStatus;
}


export interface EventCreateAction
{
	type: typeof TYPE_CREATE;
	creating?: EventCreateStatus;
	sessionId?: string;
}


export interface EventUpdateAction
{
	type: typeof TYPE_UPDATE;
	updating?: EventCreateStatus;
}


export interface EventPauseAction
{
	type: typeof TYPE_PAUSE;
	updating?: EventCreateStatus;
}


export interface EventResumeAction
{
	type: typeof TYPE_RESUME;
	updating?: EventCreateStatus;
}


export interface EventRemoveAction
{
	type: typeof TYPE_REMOVE;
	remove?: EventRemoveStatus;
}


export interface EventPayAction
{
	type: typeof TYPE_PAY;
	pay?: EventPayStatus;
	sessionId?: string;
}


export interface EventPayExtraAction
{
	type: typeof TYPE_PAY_EXTRA;
	payExtra?: EventPayStatus;
	sessionId?: string;
}


export interface EventPayCompleteAction
{
	type: typeof TYPE_PAY_COMPLETE;
	payComplete?: EventPayCompleteStatus;
	sessionId?: string;
}


export type EventAction =
	EventListAction
	| EventCreateAction
	| EventDetailAction
	| EventUpdateAction
	| EventPauseAction
	| EventResumeAction
	| EventRemoveAction
	| EventPayAction
	| EventPayExtraAction
	| EventPayCompleteAction;