class StandardEvent
{
	static get(eventType: string, data: {})
	{
		const customEvent = CustomEvent as any;
		const event = new customEvent(eventType, data);
		return event as CustomEvent;
	}
}


export default StandardEvent;