import React, {ChangeEvent} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Select, MenuItem, InputBase} from '@material-ui/core';
import {languageFlag} from '../../utils/Flags';
import {ArrowDropDown} from '@material-ui/icons';
import {withTranslation, WithTranslation} from 'react-i18next';


interface StateProps
{

}


interface DispatchProps
{

}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{

}


class BareLanguageSelect extends React.Component<Props, State>
{
	public state: State = {};


	public componentDidMount()
	{
		const {i18n} = this.props;
		const language = localStorage.getItem('language') || 'en-US';

		localStorage.setItem('language', language);
		i18n.changeLanguage(language, () => this.forceUpdate());
	}


	public render = () =>
	{
		const {i18n} = this.props;
		return (
			<div className='Footer__language'>
				<Select value={i18n.language}
						onChange={this.changeLanguage}
						variant={'outlined'}
						input={<InputBase/>}
						autoWidth={true}
						className='Footer__languageSelect'
						IconComponent={ArrowDropDown}
				>
					<MenuItem value={'en-US'} className={'Footer__languageSelectItem'}>
						{languageFlag('en')}
						<span className='Footer__languageSelectItemText'>English</span>
					</MenuItem>
					<MenuItem value={'cs-CZ'} className={'Footer__languageSelectItem'}>
						{languageFlag('cz')}
						<span className='Footer__languageSelectItemText'>Česky</span>
					</MenuItem>
				</Select>
			</div>
		);
	};

	public changeLanguage = async (e: ChangeEvent<{ name?: string; value: string }>) =>
	{
		const {i18n} = this.props;
		const language = e.target.value;

		localStorage.setItem('language', language);
		await i18n.changeLanguage(language);
	};
}


export const LanguageSelect = withTranslation('app')(connect(
	(state: AppState) => ({}),
	(dispatch: AppThunkDispatch) => ({})
)(BareLanguageSelect));