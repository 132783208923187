import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {AppThunkDispatch} from '../../model/Actions';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from '@material-ui/core';
import {Cancel} from '@material-ui/icons';
import {UserDevice} from '../../api/User';
import {actionDeviceList} from '../../model/device/actions/ListAction';
import {DeviceLoadingStatus} from '../../model/device/deviceState';
import moment from 'moment';
import {actionRemoveDevice} from '../../model/device/actions/RemoveAction';
import {WithTranslation, withTranslation} from 'react-i18next';


interface StateProps
{
	devices: UserDevice[];
	loading: DeviceLoadingStatus;
}


interface DispatchProps
{
	loadDevices: () => void;
	logoutDevice: (uid: string) => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{
	key?: number;
}


class BareUserDeviceList extends React.Component<Props, State>
{
	public state: State = {
		key: undefined
	};


	public componentDidMount(): void
	{
		this.props.loadDevices();
	}


	protected presentRemove = (key: number) => this.setState({key});
	protected closeRemove = () => this.setState({key: undefined});

	protected logout = () =>
	{
		const {key} = this.state;
		if (typeof key === 'undefined') {
			return;
		}

		const device = this.props.devices[key];

		this.closeRemove();
		this.props.logoutDevice(device.uid);
	};

	public render = () =>
	{
		if (this.props.loading === DeviceLoadingStatus.loading) {
			return (<div className="Loader">Loading…</div>);
		}

		return (
			<>
				<div className='UserDeviceList'>
					{this.props.devices.map((device, key) => (
						<div key={key} className={`UserDevice UserDevice--${browserKey(device)}`}>
							<div className='UserDevice__title'>{device.deviceIdentifier}</div>
							<div className='UserDevice__lastSeen'>{moment(device.lastUsedAt).fromNow()}</div>
							{device.isActive ? (
								<span className='UserDevice__flag'>{this.props.t('user.device.active')}</span>
							) : (
								<IconButton onClick={() => this.presentRemove(key)} color={'default'}><Cancel/></IconButton>
							)}
						</div>
					))}
				</div>
				{this.state.key && (
					<Dialog
						disableBackdropClick={false}
						disableEscapeKeyDown={false}
						className='Dialog'
						open={true}
						onClose={this.closeRemove}>
						<h2>{this.props.t('user.device.remove.title')}</h2>
						<DialogContent>
							<DialogContentText dangerouslySetInnerHTML={{
								__html: this.props.t('user.device.remove.message', {
									device: `<strong>${this.props.devices[this.state.key].deviceIdentifier}</strong>`,
									date: moment(this.props.devices[this.state.key].lastUsedAt).fromNow()
								})
							}}/>
							<Button
								onClick={() => this.logout()}
								variant="contained"
								color="primary"
								size="large">
								{this.props.t('user.device.remove.remove')}
							</Button>
							<Button
								className='Dialog__cancel'
								onClick={this.closeRemove}
								size="large"
								variant='outlined'
								color="primary">
								{this.props.t('user.device.remove.dismiss')}
							</Button>
						</DialogContent>
						<DialogActions/>
					</Dialog>
				)}
			</>
		);
	};
}


export const browserKey = (device: UserDevice): string | undefined =>
{
	const string = device.deviceIdentifier.toLowerCase();
	if (string.includes('chrome')) {
		return 'chrome';
	}

	if (string.includes('firefox')) {
		return 'firefox';
	}

	if (string.includes('safari')) {
		return 'safari';
	}

	if (string.includes('explorer')) {
		return 'ie';
	}

	if (string.includes('opera')) {
		return 'opera';
	}

	if (string.includes('edge')) {
		return 'edge';
	}

	return;
};

export const UserDeviceList = withTranslation('app')(connect(
	(state: AppState) => ({
		devices: state.device.devices,
		loading: state.device.loading
	}),
	(dispatch: AppThunkDispatch) => ({
		loadDevices: () => dispatch(actionDeviceList()),
		logoutDevice: (uid: string) =>
		{
			dispatch(actionRemoveDevice(uid)).then(() => dispatch(actionDeviceList()));
		}
	})
)(BareUserDeviceList));