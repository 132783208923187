import React, {ReactNode} from 'react';
import {Checkbox, FormControlLabel} from '@material-ui/core';


interface Props
{
	disabled?: boolean;
	label: string | ReactNode;
	isChecked: boolean;
	onChange: (isChecked: boolean) => void;
}


interface State
{

}


class CheckboxField extends React.Component<Props, State>
{

	public render = () => (
		<FormControlLabel
			control={
				<Checkbox
					onChange={() => this.props.onChange(!this.props.isChecked)}
					checked={this.props.isChecked}
					disabled={this.props.disabled}
					value="remember"
					color="primary"
				/>
			}
			label={this.props.label}
		/>
	);
}


export default CheckboxField;