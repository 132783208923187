import {eventPayExtraRequest, EventPaymentMethod} from '../../../api/Event';
import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {EventPayStatus, EventState} from '../eventState';
import {EventPayExtraAction, TYPE_PAY_EXTRA} from './types';
import {AlertType, sendAlert} from '../../../components/Alert';
import {ApiErrorResponse} from '../../../api/ApiResponse';


export const actionEventPayExtra = (uid: string, participants: number, modules: string[], payment: EventPaymentMethod): AppThunkAction => async (dispatch: AppThunkDispatch, getState) =>
{
	dispatch<EventPayExtraAction>({
		type: TYPE_PAY_EXTRA,
		payExtra: EventPayStatus.paying,
		sessionId: undefined
	});

	let {user: {device}} = getState();
	const accessToken: string = device?.accessToken || '';

	const error = (result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch<EventPayExtraAction>({
			type: TYPE_PAY_EXTRA,
			payExtra: EventPayStatus.prepared,
			sessionId: undefined
		});

		sendAlert(AlertType.error, message);
	};

	await eventPayExtraRequest(accessToken, uid, participants, modules, payment).then((result: any) =>
	{
		const {payload: {sessionId}, message} = result;

		dispatch<EventPayExtraAction>({
			type: TYPE_PAY_EXTRA,
			payExtra: EventPayStatus.paid,
			sessionId
		});

		sendAlert(AlertType.success, message);

	}).catch(error);
};

export const reduceEventPayExtra = (state: EventState, action: EventPayExtraAction): EventState => ({
	...state,
	...action
});