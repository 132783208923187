import React, {ReactNode} from 'react';
import {Card, Step, StepLabel, Stepper} from '@material-ui/core';
import {OnboardingConfirmation} from './OnboardingConfirmation';
import {connect} from 'react-redux';
import {AppState} from '../../model/State';
import {UserStatus} from '../../api/User';
import {AppThunkDispatch} from '../../model/Actions';
import {OnboardingBilling} from './OnboardingBilling';
import {actionSyncUser} from '../../model/user/actions/SyncAction';
import {UserSyncStatus} from '../../model/user/userState';
import {OnboardingEvent} from './OnboardingEvent';
import {OnboardingSubscription} from './OnboardingSubscription';
import {withTranslation, WithTranslation} from 'react-i18next';


interface StateProps
{
	status: UserStatus
	isSyncing: boolean
}


interface DispatchProps
{
	update: () => void;
}


type Props = StateProps & DispatchProps & WithTranslation;


interface State
{

}


class BareOnboarding extends React.Component<Props, State>
{
	public state = {};


	public componentDidMount(): void
	{
		this.props.update();
	}


	protected getCurrentStep = (): number =>
	{
		switch (this.props.status) {
			case UserStatus.unconfirmed:
				return 0;
			case UserStatus.confirmed:
				return 1;
			case UserStatus.billing:
				return 2;
			case UserStatus.subscription:
			case UserStatus.active:
				return 3;
		}
	};

	protected getComponent = (): ReactNode =>
	{
		switch (this.props.status) {
			case UserStatus.unconfirmed:
				return <OnboardingConfirmation/>;
			case UserStatus.confirmed:
				return <OnboardingBilling/>;
			case UserStatus.billing:
				return <OnboardingSubscription/>;
			case UserStatus.subscription:
			case UserStatus.active:
				return <OnboardingEvent/>;
		}
	};

	public render = () =>
	{
		if (this.props.isSyncing) {
			return <div/>;
		}

		const step = this.getCurrentStep();
		return (
			<Card className={'Onboarding'} variant="outlined">
				<Stepper className={'Onboarding__stepper'} activeStep={step}>
					<Step key={0}>
						<StepLabel className={`Onboarding__step ${step === 0 ? 'Onboarding__step--active' : ''}`}>
							{this.props.t('onboarding.steps.email')}
						</StepLabel>
					</Step>
					<Step key={1}>
						<StepLabel className={`Onboarding__step ${step === 1 ? 'Onboarding__step--active' : ''}`}>
							{this.props.t('onboarding.steps.billing')}
						</StepLabel>
					</Step>
					<Step key={2}>
						<StepLabel className={`Onboarding__step ${step === 2 ? 'Onboarding__step--active' : ''}`}>
							{this.props.t('onboarding.steps.subscription')}
						</StepLabel>
					</Step>
					<Step key={3}>
						<StepLabel className={`Onboarding__step ${step === 3 ? 'Onboarding__step--active' : ''}`}>
							{this.props.t('onboarding.steps.event')} <span
							className='Text--faded'>({this.props.t('onboarding.steps.optional')})</span>
						</StepLabel>
					</Step>
				</Stepper>
				{this.getComponent()}
			</Card>
		);
	};
}


export const Onboarding = withTranslation('app')(connect(
	(state: AppState) => ({
		status: state.user.user?.status || UserStatus.unconfirmed,
		isSyncing: state.user.sync === UserSyncStatus.syncing
	}),
	(dispatch: AppThunkDispatch) => ({
		update: () => dispatch(actionSyncUser())
	})
)(BareOnboarding));