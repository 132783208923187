import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {RegisterAction, TYPE_REGISTER} from './types';
import {userCreateRequest} from '../../../api/User';
import {RegisterStatus, UserState} from '../userState';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';


export const actionRegister = (name: string, email: string, password: string): AppThunkAction => async (dispatch: AppThunkDispatch) =>
{
	dispatch({
		type: TYPE_REGISTER,
		register: RegisterStatus.loading
	});

	await userCreateRequest(name, email, password).then((result: any) =>
	{
		const {payload} = result;
		localStorage.setItem('accessToken', payload.device.accessToken);

		dispatch({
			type: TYPE_REGISTER,
			register: RegisterStatus.success,
			...payload
		});
	}).catch((result: any) =>
	{
		const response: ApiErrorResponse = result.response.data;
		const {message} = response;

		dispatch({
			type: TYPE_REGISTER,
			register: RegisterStatus.failed
		});

		sendAlert(AlertType.error, message);
	});
};

export const reduceRegister = (state: UserState, action: RegisterAction): UserState => ({
	...state,
	...action
});
