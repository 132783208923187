import {AppThunkAction, AppThunkDispatch} from '../../Actions';
import {TYPE_VALIDATE, ValidateAction} from './types';
import {ApiErrorResponse} from '../../../api/ApiResponse';
import {AlertType, sendAlert} from '../../../components/Alert';
import {CouponState, CouponValidationStatus} from "../couponState";
import {couponValidateRequest} from "../../../api/Coupon";


export const actionValidateCoupon = (coupon: string): AppThunkAction => async (dispatch: AppThunkDispatch, getState) => {

    if (coupon.length === 0) {
        dispatch<ValidateAction>({
            type: TYPE_VALIDATE,
            validation: CouponValidationStatus.prepared,
            coupon: undefined
        });
        return;
    }

    dispatch<ValidateAction>({
        type: TYPE_VALIDATE,
        validation: CouponValidationStatus.loading,
        coupon: undefined
    });

    let {user: {device}} = getState();
    const accessToken: string = device?.accessToken || '';

    await couponValidateRequest(accessToken, coupon).then((result: any) => {
        const {payload} = result;
        dispatch<ValidateAction>({
            type: TYPE_VALIDATE,
            validation: payload.isValid ? CouponValidationStatus.valid : CouponValidationStatus.invalid,
            coupon: payload
        });

    }).catch((result: any) => {
        const response: ApiErrorResponse = result.response.data;
        const {message} = response;

        dispatch<ValidateAction>({
            type: TYPE_VALIDATE,
            validation: CouponValidationStatus.prepared
        });

        sendAlert(AlertType.error, message);
    });
};

export const reduceCouponValidate = (state: CouponState, action: ValidateAction): CouponState => ({
    ...state,
    ...action
});