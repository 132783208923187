export interface ApiSuccessResponse<PayloadType>
{
	success: true;
	payload: PayloadType;
}


export interface ApiErrorResponse
{
	success: false;
	code: number;
	message: string;
}


interface FieldErrorsIndexer
{
	[key: string]: string | FieldErrorsIndexer;
}


export interface FieldErrors
{
	[key: string]: FieldErrorsIndexer


	fields: FieldErrorsIndexer;
}


export interface ValidationApiErrorResponse extends ApiErrorResponse
{
	fieldErrors: FieldErrors
}


export type ApiResponse<PayloadType> =
	ApiSuccessResponse<PayloadType> | ApiErrorResponse;

export type PaginatedApiResponse<RecordType> =
	ApiResponse<{
		total: number,
		records: RecordType[],
	}>;

export type Loadable<T> = {
	loaded: false;
	loading: boolean;
	failed: false;
} | {
	loaded: true;
	failed: true;
} | {
	loaded: true;
	failed: false;
	data: T;
}

export const getHeaders = (accessToken?: string): object =>
{
	const browserInfo = require('browser-info');
	const browser = browserInfo();

	return {
		'x-access-token': accessToken,
		'x-device-identifier': `${browser.name} (${browser.version}) / ${browser.os}`
	};
};