import {Reducer} from "redux";
import {combineCaseReducers} from "../reducerCombiners";
import {CouponState, initialCouponState} from "./couponState";
import {CouponAction, TYPE_VALIDATE} from "./actions/types";
import {reduceCouponValidate} from "./actions/CouponValidateAction";

const caseReducers: { [k in CouponAction["type"]]: Reducer } = {
    [TYPE_VALIDATE]: reduceCouponValidate
};

export const couponReducer = combineCaseReducers<CouponState>(
    initialCouponState,
    caseReducers
);